import { GetServerTime } from './GetServerTime';
import { CheckErrorsPreviousDay } from './CheckErrorsPreviousDay';
import { EntradaIncidencia } from './EntradaIncidencia';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../firebase';
import moment from 'moment';

/*------------------- EntradaViaje (1 día) ----------------------*/

export const EntradaViaje = async (props, estado) => {
  const records = props.userRecords;
  const user = props.userSession;

  //Obtenemos la fecha y hora del servidor
  var serverTime = await GetServerTime();
  const time = serverTime.formatted;

  //Comprueba cuantos registros tenemos diarios
  var longRecord = 0;
  if (records[moment(time).format('DD-MM-YYYY')]) {
    var dailyRecords = records[moment(time).format('DD-MM-YYYY')];
    longRecord = Object.keys(dailyRecords).length;
  }

  if (longRecord === 0 && estado === 'Viaje 1 día') {
    await CheckErrorsPreviousDay(props, time);
  }

  if (longRecord === 0 || records[moment(time).format('DD-MM-YYYY')][longRecord - 1].salida) {
    //Registra una entrada sin incidencias
    var dayWeek = moment(time).format('dd');
    //No permite fichajes Sabados y domingos
    if (dayWeek !== 'Su' && dayWeek !== 'Sa') {
      //Comprueba si el registro anterior tiene una incidencia
      if (longRecord === 0 || !records[moment(time).format('DD-MM-YYYY')][longRecord - 1].tpIncidencia) {
        //Entrada normal
        // prettier-ignore
        await setDoc(doc(db, 'users', user.uid, 'registros', moment(time).format('MMMM-YYYY')),{
            [moment(time).format('DD-MM-YYYY')]: { [longRecord]: { entrada: moment(time).format('HH:mm'), tpRegistro: estado } },
          },{ merge: true });
        // cambia el estado del usuario
        // prettier-ignore
        await setDoc(doc(db, 'users', user.uid),{
            estadoSesion: 'Viaje'
          }, {merge:true})
      } else {
        //Entrada con incidencia
        //alert('Entrada con incidencia');
        await EntradaIncidencia(props, estado, longRecord - 1, time);
        // cambia el estado del usuario
        // prettier-ignore
        await setDoc(doc(db, 'users', user.uid),{
          estadoSesion: 'Viaje'
        }, {merge:true})
      }
    } else {
      alert('Estas intentando fichar fuera de la jornada laboral');
    }
  } else {
    alert('No has registrado una salida');
  }
};

import React, { useState, useContext, useRef, useEffect } from 'react';
import { userContext } from './../../context/UserContext';
import { useNavigate } from 'react-router-dom';
import { Header } from '../../components/ui/Header';
import { FetchTimeOut } from '../../functions/FetchTimeOut';
import { onSnapshot, collection, doc, deleteDoc, updateDoc, getDocs, query } from 'firebase/firestore';
import { ref, deleteObject } from 'firebase/storage';
import { db, storage } from '../../firebase';
import { months } from '../../constants/months';
import DatePicker from 'react-datepicker';
import es from 'date-fns/locale/es';
import DataTable from 'react-data-table-component';
import moment from 'moment';

import './styles/Receips.css';

export const Receips = () => {
  const { userData } = useContext(userContext);

  const [connectionStatus, setConnectionStatus] = useState(false);
  const [receips, setReceips] = useState([]);
  const [projects, setProjects] = useState([]);
  //const [selectedRows, setSelectedRows] = useState([]);
  const [activeReceip, setActiveReceip] = useState({});
  const [activeImage, setActiveImage] = useState();
  const visualizeDiv = useRef();
  const listDiv = useRef();
  const confirmButtenRef = useRef();

  const navigate = useNavigate();

  useEffect(() => {
    onSnapshot(collection(db, 'users', userData.userSession.uid, 'recibos'), (snapshot) => {
      const snapshotData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data(), date: doc.data().date.toDate() }));
      if (snapshotData.length === 0) {
        toggleImage();
      }
      setReceips(snapshotData);
    });
  }, []);

  useEffect(() => {
    (async () => {
      const res = await FetchTimeOut(`${process.env.REACT_APP_SERVER}/status/`, 5000);
      if (res.status === 200 && res.date === moment().format('YYYY-MM-DD')) {
        setConnectionStatus(true);
      }
    })();
  }, []);

  useEffect(() => {
    if (receips.length > 0) {
      setActiveImage(receips[0].image);
    }
  }, [receips]);

  const columns = [
    {
      name: '',
      selector: (row) => <img className='image-list' src={row.docType === 'image' ? row.document : 'static/media/pdfB&H.png'} alt={'Avatar'} data-tag='allowRowEvents' />,
      sortable: true,
      width: '12%',
    },
    {
      name: 'Día',
      selector: (row) => (
        <div style={{ fontWeight: 'bold' }} data-tag='allowRowEvents'>
          {row.day}
        </div>
      ),
      sortable: true,
      width: '7%',
    },
    {
      name: 'Hora',
      selector: (row) => (
        <div style={{ fontWeight: 'bold' }} data-tag='allowRowEvents'>
          {row.hour}
        </div>
      ),
      sortable: true,
      width: '10%',
    },
    {
      name: 'Proyecto',
      selector: (row) => (
        <div style={{ fontWeight: 'bold' }} data-tag='allowRowEvents'>
          {row.project}
        </div>
      ),
      sortable: true,
      width: '10%',
    },
    {
      name: 'Tipo',
      selector: (row) => (
        <div>
          <button className={`badge ${row.type.toLowerCase()}`} data-tag='allowRowEvents'>
            {row.type}
          </button>
        </div>
      ),
      sortable: true,
      width: '12%',
    },

    {
      name: 'Mes',
      selector: (row) => (
        <div style={{ fontWeight: 'bold' }} data-tag='allowRowEvents'>
          {row.month}
        </div>
      ),
      sortable: true,
      width: '12%',
    },
    {
      name: 'Descripción',
      selector: (row) => <span data-tag='allowRowEvents'>{row.description}</span>,
      sortable: true,
    },
    {
      selector: (row) => (
        <div onClick={() => showModal(row)} data-tag='allowRowEvents'>
          <i className='fa-regular fa-pen-to-square'></i>
        </div>
      ),
      width: '5%',
    },
  ];

  /*const handleChange = (state) => {
    setSelectedRows(state.selectedRows);
  };*/

  const toggleImage = () => {
    visualizeDiv.current.classList.toggle('active');
    listDiv.current.classList.toggle('active');
  };

  const showImage = (state) => {
    setActiveImage(state.docType === 'image' && state.document);
    if (window.screen.width > 768) {
      visualizeDiv.current.classList.remove('active');
      listDiv.current.classList.remove('active');
    } else {
      visualizeDiv.current.classList.add('active');
      listDiv.current.classList.add('active');
    }
  };

  const showModal = (receip) => {
    document.getElementById('receip-modal').classList.toggle('disabled');
    setActiveReceip(receip);

    if (projects.length !== 0) return;

    var arrProjects = [];
    //prettier-ignore
    getDocs(query(collection(db, 'projects')))
      .then((data) => {
        data.forEach((doc) => {
          arrProjects.push(doc.data())
        });
        arrProjects.sort((a,b) => (a.displayName > b.displayName) ? 1 : ((b.displayName > a.displayName) ? -1 : 0))
        setProjects(arrProjects);
      })
      .catch((err) => {
        console.log(err);
      })
  };

  const hideModal = () => {
    setActiveReceip({});
    document.getElementById('receip-modal').classList.toggle('disabled');
  };

  /*const removeReceip = () => {
    selectedRows.forEach((row) => {
      deleteDoc(doc(db, 'users', userData.userSession.uid, 'recibos', row.id));
    });
  };*/

  const updateReceip = async (e) => {
    e.preventDefault();
    confirmButtenRef.current.disabled = true;
    activeReceip.day = activeReceip.date.getDate();
    activeReceip.hour = activeReceip.date.getHours().toString().padStart(2, '0') + ':' + activeReceip.date.getMinutes().toString().padStart(2, '0');
    activeReceip.month = months[activeReceip.date.getMonth()].toUpperCase(); //Eliminar el toUpperCase en las proximas versiones
    activeReceip.year = activeReceip.date.getFullYear();
    try {
      await updateDoc(doc(db, 'users', userData.userSession.uid, 'recibos', activeReceip.id), activeReceip);
      hideModal();
    } catch (error) {
      console.log(error);
      alert('Ha habido un error al editar la dieta');
    }
    confirmButtenRef.current.disabled = false;
  };

  const downloadReceips = async () => {
    if (connectionStatus) {
      confirmButtenRef.current.disabled = true;
      for (let i = 0; i < receips.length; i++) {
        const receip = receips[i];

        //Eliminar el en las proximas versiones (toUpperCase*)
        receip.month = months[receip.date.getMonth()].toUpperCase();

        await fetch(`${process.env.REACT_APP_SERVER}/receips/save?apiKey=${process.env.REACT_APP_AQUA_APIKEY}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(receip),
        })
          .then((response) => response.json())
          .then(async (data) => {
            if (data.status === 200) {
              //prettier-ignore
              const docRef = ref(storage, `Receips/${receip.employee}/${receip.id}`);
              deleteObject(docRef).then(async () => {
                await deleteDoc(doc(db, 'users', userData.userSession.uid, 'recibos', receip.id));
              });
            } else {
              alert('Error al exportar los recibos');
            }
          })
          .catch((error) => {
            alert('Error al descargar los recibos');
            console.log(error);
          });
      }
    }
  };

  return (
    <div>
      <Header user={userData} />
      <div className='receips'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-7' ref={listDiv}>
              <div className='d-flex align-items-center'>
                <i className='fa-solid fa-arrow-left me-3' onClick={() => navigate('/')}></i>
                <h4>Listado de recibos</h4>
              </div>
              <hr />
              <div className='mt-4 mb-3'>
                {/*<button className='btn btn-success' disabled={selectedRows.length > 0}>
                  <i className='fas fa-plus text-white'></i>
                </button>
                <button className='btn btn-danger ms-2' disabled={selectedRows.length === 0} onClick={removeReceip}>
                  <i className='fas fa-trash-alt text-white'></i>
  </button>*/}
                <button
                  className='btn btn-primary '
                  /*disabled={selectedRows.length === 0}*/ onClick={downloadReceips}
                  disabled={!connectionStatus || receips.length < 1}
                  ref={confirmButtenRef}
                >
                  <i className='fas fa-download me-2 text-white' />
                  Descargar
                </button>
              </div>
              <div className='list'>
                <DataTable columns={columns} data={receips} onRowClicked={showImage} /*selectableRows onSelectedRowsChange={handleChange}*/ />
              </div>
            </div>
            {/* Calculas la posición del div y restar el del header para ponerlo en pantalla completa */}
            <div className='col-md-5 visualize' ref={visualizeDiv}>
              {window.screen.width > 768 ? <i className='fa-solid fa-chevron-right' onClick={toggleImage}></i> : <i className='fa-solid fa-close' onClick={toggleImage}></i>}
              {<img src={activeImage || receips[0]?.document} alt='receip' className='img-fluid' />}
            </div>
          </div>
        </div>
      </div>
      <div className='modal disabled' id='receip-modal'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <i className='fa-regular fa-floppy-disk'></i>
              <h1 className='modal-title fs-5 mt-1' id='exampleModalLabel'>
                Editar ticket
              </h1>
              <button type='button' className='btn-close' onClick={() => hideModal()}></button>
            </div>
            <div className='modal-body'>
              <form onSubmit={updateReceip}>
                <div className='mb-2'>
                  <label htmlFor='company' className='col-form-label'>
                    Empresa:
                  </label>
                  <select
                    className='form-select'
                    id='company'
                    value={activeReceip.company}
                    required
                    onChange={(e) => setActiveReceip({ ...activeReceip, company: e.target.value })}
                  >
                    <option value='CETEM'>CETEM</option>
                    <option value='AMUEBLA'>AMUEBLA</option>
                  </select>
                </div>
                <div className='mb-2'>
                  <label htmlFor='project' className='col-form-label'>
                    Proyecto:
                  </label>
                  <select
                    className='form-select'
                    id='project'
                    required
                    value={activeReceip.project}
                    onChange={(e) => setActiveReceip({ ...activeReceip, project: e.target.value })}
                  >
                    {projects.length > 0 &&
                      projects.map((project) => (
                        <option key={project.codigo} value={project.codigo}>
                          {project.codigo + '   -   ' + project.nombre}
                        </option>
                      ))}
                    <option key='ESPECIAL' value='ESPECIALES'>
                      ESPEC - ESPECIALES
                    </option>
                  </select>
                </div>
                <div className='mb-2'>
                  <label htmlFor='type' className='col-form-label'>
                    Tipo:
                  </label>
                  <select className='form-select' id='type' required value={activeReceip.type} onChange={(e) => setActiveReceip({ ...activeReceip, type: e.target.value })}>
                    <option value='Comida'>Comidas</option>
                    <option value='Transporte'>Transporte</option>
                    <option value='Alojamiento'>Alojamiento</option>
                    <option value='Otros'>Otros</option>
                  </select>
                </div>
                <div className='mb-2'>
                  <label htmlFor='employee' className='col-form-label'>
                    Empleado:
                  </label>
                  <input type='text' className='form-control' id='employee' value={userData.userSession.iniciales} disabled />
                </div>

                <div className='mb-2'>
                  <label htmlFor='month' className='col-form-label'>
                    Fecha:
                  </label>
                  <DatePicker
                    locale={es}
                    dateFormat='dd/MM/yyyy'
                    selected={activeReceip.date ? activeReceip.date : new Date()}
                    onChange={(date) => setActiveReceip({ ...activeReceip, date: date })}
                  />
                </div>
                <div className='mb-3'>
                  <label htmlFor='description' className='col-form-label'>
                    Descripción:
                  </label>
                  <textarea
                    className='form-control'
                    id='description'
                    rows='3'
                    defaultValue={activeReceip.description}
                    onChange={(e) => setActiveReceip({ ...activeReceip, description: e.target.value })}
                  ></textarea>
                </div>
                <div className='mt-4 mx-auto submit'>
                  <input type='submit' className='btn btn-success px-5 ' value='Confirmar' ref={confirmButtenRef} />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

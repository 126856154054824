import React from 'react';

import Lottie from 'react-lottie-player';

export const Loader = (props) => {
  return (
    <div className='loader-container'>
      <Lottie style={{ width: props.width }} loop={props.loop} animationData={props.type} play />
      <p style={{ float: 'right', marginRight: '1em' }}>Cargando ...</p>
    </div>
  );
};

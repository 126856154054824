import React, { useState, useEffect, useContext } from 'react';
import CreateMonthTable from '../../functions/CreateMonthTable';
import { userContext } from '../../context/UserContext';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import moment from 'moment';

export const CurrentPreviousMonthRecords = () => {
  const { userData } = useContext(userContext);
  const [records, setRecords] = useState([]);

  const user = userData.userSession;

  useEffect(() => {
    getDoc(doc(db, 'users', user.uid, 'registros', moment().subtract(1, 'months').format('MMMM-YYYY')))
      .then((doc) => setRecords(Object.entries(doc.data())))
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  var table = CreateMonthTable(records);

  return (
    <div className='card'>
      <div className='card-body'>
        <div className='row'>
          <div className='col mt-0'>
            <h5 className='card-title'>Registros mes anterior</h5>
          </div>
        </div>
        <table className='table'>
          <thead>
            <tr>
              <th scope='col'></th>
              <th scope='col'>Entrada</th>
              <th scope='col'>Salida</th>
              <th scope='col'>T.Tramo</th>
              <th scope='col'>Tot. Día</th>
            </tr>
          </thead>
          <tbody dangerouslySetInnerHTML={{ __html: table }}>
            {/*
                registros.sort().map((colum) =>
                    <tr>        
                        <th scope="row">{colum[0]}</th>
                        <td>{colum[1].entrada1}</td>
                        <td>{colum[1].ultimaSalida}</td>
                        <td>{colum[1].total}</td>
                    </tr>
                )
            */}
          </tbody>
        </table>
        <div className='clarification'>
          <p>
            {' '}
            Los fichajes con (<strong>*</strong>) son <strong>Salidas Justificadas</strong>
          </p>
        </div>
      </div>
    </div>
  );
};

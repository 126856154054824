import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDocs, query, collection } from 'firebase/firestore';
import { db } from '../../firebase';
import DatePicker from 'react-datepicker';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import './styles/TimeControlPrj.css';
import es from 'date-fns/locale/es';

export const TimeControlPrj = () => {
  const [connection, setConnection] = useState(false);
  const [projects, setProjects] = useState([]);
  const [data, setData] = useState({
    code: null,
    start: new Date(new Date().getFullYear(), 0, 1),
    end: new Date(new Date().getFullYear(), 0, 1),
  }); // [user, date, type
  const [records, setRecords] = useState([]);

  const navigate = useNavigate();
  document.querySelector('body').style.overflow = 'hidden';

  const exit = () => {
    document.querySelector('body').style.overflow = 'auto';
    navigate('/');
  };

  const columns = [
    {
      name: 'PROYECTO',
      selector: (row) => row.PROYECTO,
    },
    {
      name: 'FASE',
      selector: (row) => row.FASE,
    },
    {
      name: 'EMPLEADO',
      cell: (row) => {
        if (row.EMPLEADO === 'TOTAL' || row.EMPLEADO === 'TOTALPROYECTO') {
          return <div dangerouslySetInnerHTML={{ __html: `<strong>${row.EMPLEADO}</strong>` }}></div>;
        } else {
          return <div dangerouslySetInnerHTML={{ __html: row.EMPLEADO }}></div>;
        }
      },
    },
    {
      name: 'TIEMPO',
      cell: (row) => {
        var seconds = row.TIEMPO;

        var hours = Math.floor(seconds / 3600);
        var minutes = Math.floor((seconds - hours * 3600) / 60)
          .toString()
          .padStart(2, '0');

        if (row.EMPLEADO === 'TOTAL' || row.EMPLEADO === 'TOTALPROYECTO') {
          return <div dangerouslySetInnerHTML={{ __html: `<strong>${hours}h ${minutes}m</strong>` }}></div>;
        } else {
          return <div dangerouslySetInnerHTML={{ __html: `${hours}h ${minutes}m` }}></div>;
        }
      },
    },
  ];

  const getRecords = async (e) => {
    e.preventDefault();
    if (!data.code) {
      alert('Selecciona un proyecto');
      return;
    }

    let start = moment(data.start).format('YYYY-MM-DD');
    let end = moment(data.end).format('YYYY-MM-DD');

    let records = await fetch(`${process.env.REACT_APP_SERVER}/records/project/${data.code}?start=${start}&end=${end}&apiKey=${process.env.REACT_APP_AQUA_APIKEY}`);
    records = await records.json();
    records = records.message;

    let recordsFiltered = [];
    let totalProyecto = 0;
    for (let i = 0; i < records.length; i++) {
      recordsFiltered.push(records[i]);
      if (i === 0) {
        recordsFiltered[0].PROYECTO = data.code;
      }
      if (records[i].FASE !== records[i + 1]?.FASE) {
        recordsFiltered.push({
          PROYECTO: '',
          FASE: '',
          EMPLEADO: 'TOTAL',
          TIEMPO: records[i].TOTALTIEMPOFASE,
        });
        totalProyecto = totalProyecto + records[i].TOTALTIEMPOFASE;
      }
    }
    recordsFiltered.push({
      PROYECTO: '',
      FASE: '',
      EMPLEADO: 'TOTALPROYECTO',
      TIEMPO: totalProyecto,
    });

    setRecords(recordsFiltered);
  };

  useEffect(() => {
    (async () => {
      let res = await fetch(`${process.env.REACT_APP_SERVER}/status`);
      res = await res.json();
      if (res.status === 200) {
        setConnection(true);
      }
    })();
  }, []);

  useEffect(() => {
    if (connection === true) {
      var arrProjects = [];
      //prettier-ignore
      getDocs(query(collection(db, 'projects')))
      .then((data) => {
        data.forEach((doc) => {
          arrProjects.push(doc.data())
        });
        arrProjects.sort((a,b) => (a.displayName > b.displayName) ? 1 : ((b.displayName > a.displayName) ? -1 : 0))
        setProjects(arrProjects);
      })
      .catch((err) => {
        console.log(err);
      })
    }
  }, [connection]);

  return (
    <div className='modal-route'>
      <div className='timeControlPrj'>
        <div className='modal-header'>
          <h5 className='modal-title'>
            <i className='fa fa-user'></i>Consultar control horario por PROYECTO
          </h5>
          <button type='button' className='btn-close' onClick={() => exit()}></button>
        </div>
        <div className='modal-body'>
          {window.innerWidth < 1000 ? (
            <div className='row '>
              <img src='static/media/mobile-error.png' alt='Error de conexión' className='connection-error mt-2' />
              <p className='text-center mt-4'>No es posible consultar horas desde el movil</p>
            </div>
          ) : connection === false ? (
            <div className='row '>
              <img src='static/media/network-error.png' alt='Error de conexión' className='connection-error mt-2' />
              <p className='text-center'>No tienes conexion con el servidor</p>
            </div>
          ) : (
            <div className='row'>
              <div className='col-md-3'>
                <form onSubmit={(e) => getRecords(e)}>
                  <div className='form-group mb-3'>
                    <label>Proyecto:</label>
                    <select className='form-control' id='user' onChange={(e) => setData({ ...data, code: e.target.value })}>
                      <option value='' disabled selected>
                        {' '}
                        Seleccionar{' '}
                      </option>
                      {projects.map((project) => (
                        <option key={project.codigo} value={project.codigo}>
                          {project.codigo + '   -   ' + project.nombre}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className='form-group mb-3'>
                    <label>Fecha inicio:</label>
                    <DatePicker
                      locale={es}
                      dateFormat='dd/MM/yyyy'
                      selected={data.start}
                      onChange={(date) => setData({ ...data, start: date })}
                      selectsStart
                      startDate={data.start}
                      endDate={data.end}
                    />
                  </div>
                  <div className='form-group mb-3'>
                    <label>Fecha fin:</label>

                    <DatePicker
                      locale={es}
                      dateFormat='dd/MM/yyyy'
                      selected={data.end}
                      onChange={(date) => setData({ ...data, end: date })}
                      selectsEnd
                      startDate={data.start}
                      endDate={data.end}
                      minDate={data.start}
                    />
                  </div>
                  <div className='submit'>
                    <button type='submit' className='btn btn-success mt-2'>
                      Consultar
                    </button>
                  </div>
                </form>
              </div>
              <div className='col-md-9'>{<DataTable columns={columns} data={records} />}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

//import { getAuth } from 'firebase/auth';

export const GetServerTime = async () => {
  try {
    const response = await fetch('https://api.timezonedb.com/v2.1/get-time-zone?key=HCFRDCPYOL0I&format=json&by=zone&zone=Europe/Madrid');
    const data = await response.json();
    return data;
  } catch (error) {
    console.log('Error servidor horario 1 ', error);
    try {
      /*const auth = getAuth();
      const token = await auth.currentUser.getIdToken(true);
      console.log('token', token);*/

      const response = await fetch('https://us-central1-controlhorario-4c249.cloudfunctions.net/serverTime');
      const data = await response.json();
      return data;
    } catch (error) {
      alert('Error servidor horario ');
    }
  }
};

import { doc, setDoc } from 'firebase/firestore';
import { db } from '../firebase';
import MailError from './MailError';
import moment from 'moment';

export const CheckErrorsPreviousDay = async (props, time) => {
  const records = props.userRecords;
  const user = props.userSession;

  //Comprueba si está de vacaciones
  if (user.estadoSesion !== 'Vacaciones') {
    //Numero de registros
    var monthRecords = Object.keys(records).length;

    //Si no es el primer dia del mes
    if (monthRecords !== 0) {
      var recordsDayBefore, dayRecords, start, end, duration, total;
      //Comprueba si es lunes, en caso de ser lunes chequear los registros del viernes
      if (moment(time).format('dd') !== 'Mo') {
        recordsDayBefore = records[moment(time).subtract(1, 'days').format('DD-MM-YYYY')];
        if (recordsDayBefore) {
          dayRecords = Object.keys(recordsDayBefore).length;

          //Comprueba si en el último tramo del día anterior existe errores (Igual que el anterior)
          if (!records[moment(time).subtract(1, 'days').format('DD-MM-YYYY')][dayRecords - 1].totalAcumulado) {
            alert('Error fichaje día anterior. \nRecurda mandar una solicitud de incidencia');
            MailError(props);
          }

          /*Comprueba si el ultimo tramo del día anterior es de tipo incidencia y si es calcula el total del tramo
        Al ser de tipo incidencia, los totales están a 0 */
          if (records[moment(time).subtract(1, 'days').format('DD-MM-YYYY')][dayRecords - 1].tpIncidencia) {
            start = records[moment(time).subtract(1, 'days').format('DD-MM-YYYY')][dayRecords - 1].entrada;
            end = '13:00';

            // Convierte los datos en legibles para moment.js
            start = moment(start, 'HH:mm');
            end = moment(end, 'HH:mm');

            duration = moment.duration(end - start);
            total = moment.utc(duration._milliseconds).format('H:mm');

            //Introduce el total y total acumulado
            // prettier-ignore
            await setDoc(doc(db, 'users', user.uid, 'registros', moment(time).format('MMMM-YYYY')),{
            [moment(time).subtract(1, 'days').format('DD-MM-YYYY')] : { [dayRecords-1] : {"total" : total, "totalAcumulado":total}}
          },{ merge: true });
          }
        }
      } else {
        recordsDayBefore = records[moment(time).subtract(3, 'days').format('DD-MM-YYYY')];
        if (recordsDayBefore) {
          dayRecords = Object.keys(recordsDayBefore).length;

          //Comprueba si en el último tramo del día anterior existe errores (Igual que el anterior)
          if (!records[moment(time).subtract(3, 'days').format('DD-MM-YYYY')][dayRecords - 1].totalAcumulado) {
            alert('Error fichaje día anterior. \nRecurda mandar una solicitud de incidencia');
            MailError(props);
          }

          /*Comprueba si el ultimo tramo del día anterior es de tipo incidencia y si es calcula el total del tramo 
         Al ser de tipo incidencia, los totales están a 0 (Igual que el anterior) */
          if (records[moment(time).subtract(3, 'days').format('DD-MM-YYYY')][dayRecords - 1].tpIncidencia) {
            start = records[moment(time).subtract(3, 'days').format('DD-MM-YYYY')][dayRecords - 1].entrada;
            end = '13:00';

            // Convierte los datos en legibles para moment.js
            start = moment(start, 'HH:mm');
            end = moment(end, 'HH:mm');

            duration = moment.duration(end - start);
            total = moment.utc(duration._milliseconds).format('H:mm');

            //Introduce el total y total acumulado
            // prettier-ignore
            await setDoc(doc(db, 'users', user.uid, 'registros', moment(time).format('MMMM-YYYY')),{
            [moment(time).subtract(3, 'days').format('DD-MM-YYYY')] : { [dayRecords-1] : {"total" : total, "totalAcumulado":total}}
          },{ merge: true });
          }
        }
      }
    }
  }
};

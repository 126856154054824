import moment from 'moment';

export const CheckTrip = (props, type) => {
  const records = props.userRecords;

  //prettier-ignore
  var recordsDayBefore = records[moment().subtract(1, 'days').format("DD-MM-YYYY")];
  if (!recordsDayBefore) {
    if (moment().format('dd') === 'Mo') {
      //prettier-ignore
      recordsDayBefore = records[moment().subtract(3, 'days').format("DD-MM-YYYY")];
      if (!recordsDayBefore) {
        recordsDayBefore = [{}];
      }
    } else {
      recordsDayBefore = [{}];
    }
  }

  recordsDayBefore = Object.values(recordsDayBefore);

  //Nos devuelve true si el objeto contiene tpRegistro === Viaje
  var check = recordsDayBefore.some((item) => item.tpRegistro === 'Viaje');

  //Vuelta viaje viernes pasadas las 00:00
  if (type === 'salida' && moment().format('dd') === 'Sa' && recordsDayBefore.some((item) => item.tpRegistro === 'Vuelta viaje')) {
    check = true;
  }

  return check;
};

import { GetServerTime } from './GetServerTime';
import { CheckErrorsPreviousDay } from './CheckErrorsPreviousDay';
import { EntradaIncidencia } from './EntradaIncidencia';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../firebase';
import moment from 'moment';

export const Entrada = async (props, estado) => {
  const records = props.userRecords;
  const user = props.userSession;

  //Obtenemos la fecha y hora del servidor
  var serverTime = await GetServerTime();
  const time = serverTime.formatted;

  //Comprueba cuantos registros tenemos diarios
  var longRecord = 0;
  if (records[moment(time).format('DD-MM-YYYY')]) {
    var dailyRecords = records[moment(time).format('DD-MM-YYYY')];
    longRecord = Object.keys(dailyRecords).length;
  }

  if (longRecord === 0) {
    await CheckErrorsPreviousDay(props, time);
  }

  if (longRecord === 0 || records[moment(time).format('DD-MM-YYYY')][longRecord - 1].salida) {
    //Registra una entrada sin incidencias
    var dayWeek = moment(time).format('dd');
    //No permite fichajes Sabados y domingos
    if (dayWeek !== 'Su' && dayWeek !== 'Sa') {
      //No permite fichajes antes de las 7 am y despues de las 20
      if (!(parseInt(moment(time).format('HH')) < 7 || parseInt(moment(time).format('HH')) > 20)) {
        //Comprueba si el registro anterior tiene una incidencia
        if (longRecord === 0 || !records[moment(time).format('DD-MM-YYYY')][longRecord - 1].tpIncidencia) {
          //Entrada normal
          // prettier-ignore
          await setDoc(doc(db, 'users', user.uid, 'registros', moment(time).format('MMMM-YYYY')),{
            [moment(time).format('DD-MM-YYYY')]: { [longRecord]: { entrada: moment(time).format('HH:mm'), tpRegistro: estado } },
          },{ merge: true });
          // cambia el estado del usuario
          // prettier-ignore
          await setDoc(doc(db, 'users', user.uid),{
            estadoSesion: estado
          }, {merge:true})
        } else {
          //Entrada con incidencia
          //alert('Entrada con incidencia');
          await EntradaIncidencia(props, estado, longRecord - 1, time);
          // prettier-ignore
          await setDoc(doc(db, 'users', user.uid),{
            estadoSesion: estado
          }, {merge:true})
        }
      } else {
        // Si el fichaje es mayor de 8 muestra error
        if (!(parseInt(moment(time).format('HH')) >= 20)) {
          var resultado = window.confirm('No son todavía las 07:00 \n ¿Desea realizar el fichaje a las 07:00? ');
          if (resultado === true) {
            // prettier-ignore
            await setDoc(doc(db, 'users', user.uid, 'registros', moment(time).format('MMMM-YYYY')),{
              [moment(time).format('DD-MM-YYYY')]: { [longRecord] : { entrada : "07:00", tpRegistro : estado }}
            },{ merge: true });
            // prettier-ignore
            await setDoc(doc(db, 'users', user.uid),{
            estadoSesion: estado
            }, {merge:true})
          }
        } else {
          alert('No se puede fichar a partir de las 8 de la tarde.');
        }
      }
    } else {
      alert('Estas intentando fichar fuera de la jornada laboral');
    }
  } else {
    alert('No has registrado una salida');
  }
};

import React, { useState, useEffect, useContext } from 'react';
import { userContext } from '../../context/UserContext';
import { useNavigate } from 'react-router-dom';
import { getDoc, doc, addDoc, collection, query, onSnapshot, where } from 'firebase/firestore';
import { db } from '../../firebase';
import { DaysBetweenDates } from '../../functions/DaysBetweenDates';
import DatePicker, { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import moment from 'moment';

import './styles/Absences.css';

export const Absences = () => {
  const { userData } = useContext(userContext);

  const [year, setYear] = useState(new Date().getFullYear());
  const [absences, setAbsences] = useState({ old: [], new: [] });
  const [absenceType, setAbsenceType] = useState(null);
  //Restante (confirmado)
  const [remainingConfirmed, setRemainingConfirmed] = useState({
    tAbsences: userData.userSession.calendario[year].horas_variables || 0,
    tPersAffairs: userData.userSession.calendario[year].asuntos_propios || 0,
  });
  //Restante total (confirmado y pendiente)
  const [totalRemaining, setTotalRemaining] = useState({
    tAbsences: userData.userSession.calendario[year].horas_variables || 0,
    tPersAffairs: userData.userSession.calendario[year].asuntos_propios || 0,
  });
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const navigate = useNavigate();
  document.querySelector('body').style.overflow = 'hidden';

  useEffect(() => {
    let abs = { old: [], new: [] };

    onSnapshot(query(collection(db, 'users', userData.userSession.uid, 'ausencias'), where('year', '==', year.toString())), (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        //Si la fecha de fin es mayor a la actual, se añade a las nuevas, si no, a las antiguas
        if (moment(doc.data().endDate, 'DD/MM/YYYY').isAfter(moment())) {
          abs.new.push(doc.data());
        } else {
          abs.old.push(doc.data());
        }
      });

      //Ordena las ausencias por fecha de inicio de menor a mayor
      abs.old.sort((a, b) => (moment(a.startDate, 'DD/MM/YYYY').isAfter(moment(b.startDate, 'DD/MM/YYYY')) ? 1 : -1)).reverse();
      abs.new.sort((a, b) => (moment(a.startDate, 'DD/MM/YYYY').isAfter(moment(b.startDate, 'DD/MM/YYYY')) ? 1 : -1)).reverse();

      setAbsences(abs);
      abs = { old: [], new: [] };
    });
  }, [year]);

  useEffect(() => {
    const allAbsences = [...absences.old, ...absences.new];

    const total = {
      tAbsences: userData.userSession.calendario[year].horas_variables || 0,
      tPersAffairs: userData.userSession.calendario[year].asuntos_propios || 0,
    };

    const confirmed = {
      tAbsences: userData.userSession.calendario[year].horas_variables || 0,
      tPersAffairs: userData.userSession.calendario[year].asuntos_propios || 0,
    };

    allAbsences.forEach((absence) => {
      if (absence.type === 'Vacaciones' && absence.status !== 'Rechazado') {
        if (absence.status === 'Aceptado') {
          confirmed.tAbsences = confirmed.tAbsences - absence.total;
        }
        total.tAbsences = total.tAbsences - absence.total;
      } else if (absence.type === 'Asuntos_propios' && absence.status !== 'Rechazado') {
        if (absence.status === 'Aceptado') {
          //Obtener los días en vez del total de horas
          confirmed.tPersAffairs = confirmed.tPersAffairs - (moment(absence.endDate, 'DD/MM/YYYY').diff(moment(absence.startDate, 'DD/MM/YYYY'), 'days') + 1);
        }
        total.tPersAffairs = total.tPersAffairs - (moment(absence.endDate, 'DD/MM/YYYY').diff(moment(absence.startDate, 'DD/MM/YYYY'), 'days') + 1);
      }
    });

    setRemainingConfirmed(confirmed);
    setTotalRemaining(total);
  }, [absences]);

  const createAbsence = async (e) => {
    e.preventDefault();

    if (dateRange[0] === null || dateRange[1] === null) {
      alert('Debes de seleccionar una fecha de inicio y una fecha de fin');
      return;
    }

    const calendarYear = moment(dateRange[0]).format('YYYY');

    const workerCalendar = userData.userSession.calendario[calendarYear].id;
    //Obtiene el calendario del empleado
    const calendar = (await getDoc(doc(db, 'calendars', workerCalendar))).data().data;

    const absence = {
      notificationDate: new Date(),
      type: e.target[0].value,
      description: e.target[3].value || `Solicitud de ${e.target[0].value.replace(/_/g, ' ')}`,
      startDate: moment(dateRange[0]).format('DD/MM/YYYY'),
      endDate: moment(dateRange[1]).format('DD/MM/YYYY'),
      total: 0,
      status: 'Pendiente',
      processed: false,
      uid: userData.userSession.uid,
      email: userData.userSession.email,
      name: userData.userSession.displayName,
      avatar: userData.userSession.photoURL,
      read: false,
      year: year.toString(),
    };

    const days = DaysBetweenDates(moment(dateRange[0]), moment(dateRange[1]));
    days.forEach((day) => {
      const month = moment(day, 'DD/MM/YYYY').format('MMMM');
      const dayNumber = moment(day, 'DD/MM/YYYY').format('DD') - 1;

      const hours = calendar[month].days[dayNumber].hours;
      absence.total = absence.total + parseInt(hours.split(':')[0] * 60) + parseInt(hours.split(':')[1]);
    });

    switch (absence.type) {
      case 'Vacaciones':
        if (absence.total <= totalRemaining.tAbsences) {
          await addDoc(collection(db, 'users', userData.userSession.uid, 'ausencias'), absence).then((data) => {
            absence.nid = data.id;
          });
          await addDoc(collection(db, 'notifications'), absence);
        } else {
          alert('No tienes suficientes horas variables');
        }
        break;
      case 'Asuntos_propios':
        const days = moment(absence.endDate, 'DD/MM/YYYY').diff(moment(absence.startDate, 'DD/MM/YYYY'), 'days') + 1;
        if (days <= totalRemaining.tPersAffairs) {
          await addDoc(collection(db, 'users', userData.userSession.uid, 'ausencias'), absence).then((data) => {
            absence.nid = data.id;
          });
          await addDoc(collection(db, 'notifications'), absence);
        } else {
          alert('No tienes suficientes días de asuntos propios');
        }
        break;
      case 'Enfermedad':
        await addDoc(collection(db, 'users', userData.userSession.uid, 'ausencias'), absence).then((data) => {
          absence.nid = data.id;
        });
        await addDoc(collection(db, 'notifications'), absence);
        break;
      default:
        break;
    }

    hideModal();
  };

  const onChangeYear = (e) => {
    const min = e.target.min;
    const max = e.target.max;

    if (e.target.value < min) {
      setYear(min);
    } else if (e.target.value > max) {
      setYear(max);
    } else {
      setYear(e.target.value);
    }
  };

  const showModal = () => {
    document.getElementById('absences-modal').classList.toggle('disabled');
  };

  const hideModal = () => {
    document.getElementById('absences-modal').classList.toggle('disabled');
  };

  const exit = () => {
    document.querySelector('body').style.overflow = 'auto';
    navigate('/');
  };

  registerLocale('es', es);
  const isWeekday = (date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  return (
    <div className='modal-route'>
      <div className='absences'>
        <div className='modal-header'>
          <h5 className='modal-title '>
            <i className='fa-solid fa-umbrella-beach'></i>Ausencias / Vacaciones
          </h5>
          <button type='button' className='btn-close' onClick={() => exit()}></button>
        </div>
        <div className='modal-body'>
          <div className='row'>
            <div className='col-md-4 status'>
              <div className='card mb-4'>
                <div className='card-header'>
                  <h5 className='card-title float-start'>Horas variables</h5>
                  <span className='badge text-bg-success bg-success float-end '>Horas</span>
                </div>
                <div className='card-body'>
                  <div className='progress rounded-pill mt-2 mb-3 float-start'>
                    <div
                      className='progress-bar progress-bar-striped bg-success rounded-pill '
                      role='progressbar'
                      style={{ width: (remainingConfirmed.tAbsences / userData.userSession.calendario[year].horas_variables) * 100 + '%' }}
                    ></div>
                  </div>
                  <div className='total'>
                    <span>
                      {Math.floor(userData.userSession.calendario[year].horas_variables / 60) + 'h ' + (userData.userSession.calendario[year].horas_variables % 60) + 'min'}
                    </span>
                  </div>
                  <div className='float-start'>
                    <span className='fw-bold me-2'>Restante:</span>
                    <span>{Math.floor(remainingConfirmed.tAbsences / 60) + 'h ' + (remainingConfirmed.tAbsences % 60) + 'min'}</span>
                  </div>
                </div>
              </div>
              <div className='card'>
                <div className='card-header'>
                  <h5 className='card-title float-start'>Asuntos propios</h5>
                  <span className='badge text-bg-success bg-primary float-end '>Días</span>
                </div>
                <div className='card-body'>
                  <div className='progress rounded-pill mt-2 mb-3 float-start'>
                    <div
                      className='progress-bar progress-bar-striped rounded-pill '
                      role='progressbar'
                      style={{ width: (remainingConfirmed.tPersAffairs / userData.userSession.calendario[year].asuntos_propios) * 100 + '%' }}
                    ></div>
                  </div>
                  <div className='total'>
                    <span>{userData.userSession.calendario[year].asuntos_propios + ' días'}</span>
                  </div>
                  <div className='float-start'>
                    <span className='fw-bold me-2'>Restante:</span>
                    <span>{remainingConfirmed.tPersAffairs + ' días '}</span>
                  </div>
                </div>
              </div>
              {(userData.userSession.role === 'Responsable' || userData.userSession.privileges) && (
                <div className='row mx-auto'>
                  <div className='btn btn-default mt-4' onClick={() => navigate('/absences-report')}>
                    <i className='fa-solid fa-magnifying-glass me-3'></i>
                    Consultar ausencias departamento{' '}
                  </div>
                </div>
              )}
            </div>
            <div className='col-md-8 list'>
              <div className='new-absence mx-auto mb-4'>
                <div className='add' onClick={() => showModal()}>
                  <i className='fa-solid fa-plus'></i>
                  <span className='ml-4'>Nueva Solicitud</span>
                </div>
                <div className='year-selector'>
                  <label htmlFor='year' className='col-form-label'>
                    Horas variables del año:
                  </label>
                  <input
                    type='number'
                    className='form-control'
                    id='year'
                    value={year}
                    min={Object.keys(userData.userSession.calendario).sort((a, b) => a - b)[0]}
                    max={Object.keys(userData.userSession.calendario).sort((a, b) => a - b)[Object.keys(userData.userSession.calendario).length - 1]}
                    onChange={(e) => onChangeYear(e)}
                    onKeyDown={(e) => e.preventDefault()}
                  />
                </div>
              </div>
              <h5 className='text-center mb-4 d-none'>Solicitudes</h5>
              <div className='absence-list'>
                {absences.new.map((absence) => (
                  <div className='absence-item mb-3'>
                    <div className='card'>
                      <div className='card-body'>
                        <h5 className='card-title mb-4'>{absence.type.replace(/_/g, ' ')}</h5>
                        <div className='row mt-4'>
                          <div className='col-md-3'>
                            <div className='icon-calendar'>
                              <div id='weekday'>{moment(absence.startDate, 'DD/MM/YYYY').format('MMM')}</div>
                              <div id='day'>{absence.startDate.split('/')[0]}</div>
                            </div>
                            {absence.startDate !== absence.endDate && (
                              <>
                                <i className='fa-solid fa-arrow-right'></i>
                                <div className='icon-calendar'>
                                  <div id='weekday'>{moment(absence.endDate, 'DD/MM/YYYY').format('MMM')}</div>
                                  <div id='day'>{absence.endDate.split('/')[0]}</div>
                                </div>
                              </>
                            )}
                          </div>
                          <div className='col-md-5'>
                            <p className='card-text'>{`Del ${absence.startDate} al ${absence.endDate}`}</p>
                            <p>
                              <strong className='me-2'>Observaciones:</strong>
                              {absence.description}
                            </p>
                          </div>
                          <div className='col-md-2 mt-4 text-center'>
                            <p className='card-text '>
                              {absence.type === 'Vacaciones'
                                ? Math.floor(absence.total / 60)
                                    .toString()
                                    .padStart(2, '0') +
                                  'h ' +
                                  (absence.total % 60).toString().padStart(2, '0') +
                                  'min'
                                : moment(absence.endDate, 'DD/MM/YYYY').diff(moment(absence.startDate, 'DD/MM/YYYY'), 'days') + 1 + ' días'}
                            </p>
                          </div>
                          <div className='col-md-2 mt-4 text-center'>
                            <div className={`badge rounded-pill ${absence.status}`}>
                              <i className={`fa-solid fa-clock-rotate-left ${absence.status}`}></i>
                              <span className='text-white'>{absence.status}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

                {absences.old.map((absence, i) => (
                  <>
                    {i === 0 && <p className='text-center mt-4 fw-bold'>Vencidas</p>}
                    <div className='absence-item mb-3 grayscale'>
                      <div className='card'>
                        <div className='card-body'>
                          <h5 className='card-title mb-4'>{absence.type}</h5>
                          <div className='row mt-4'>
                            <div className='col-md-3'>
                              <div className='icon-calendar'>
                                <div id='weekday'>{moment(absence.startDate, 'DD/MM/YYYY').format('MMM')}</div>
                                <div id='day'>{absence.startDate.split('/')[0]}</div>
                              </div>
                              {absence.startDate !== absence.endDate && (
                                <>
                                  <i className='fa-solid fa-arrow-right'></i>
                                  <div className='icon-calendar'>
                                    <div id='weekday'>{moment(absence.endDate, 'DD/MM/YYYY').format('MMM')}</div>
                                    <div id='day'>{absence.endDate.split('/')[0]}</div>
                                  </div>
                                </>
                              )}
                            </div>
                            <div className='col-md-5'>
                              <p className='card-text'>{`Del ${absence.startDate} al ${absence.endDate}`}</p>
                              <p>
                                <strong className='me-2'>Observaciones:</strong>
                                {absence.description}
                              </p>
                            </div>
                            <div className='col-md-2 mt-4 text-center'>
                              <p className='card-text '>
                                {absence.type === 'Vacaciones'
                                  ? Math.floor(absence.total / 60)
                                      .toString()
                                      .padStart(2, '0') +
                                    'h ' +
                                    (absence.total % 60).toString().padStart(2, '0') +
                                    'min'
                                  : moment(absence.endDate, 'DD/MM/YYYY').diff(moment(absence.startDate, 'DD/MM/YYYY'), 'days') + 1 + ' días'}
                              </p>
                            </div>
                            <div className='col-md-2 mt-4 text-center'>
                              <div className={`badge rounded-pill ${absence.status}`}>
                                <span className='text-white'>{absence.status}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='modal disabled' id='absences-modal'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <i className='fa-regular fa-envelope-open'></i>
              <h1 className='modal-title fs-5 mt-1' id='exampleModalLabel'>
                Nueva solicitud
              </h1>
              <button type='button' className='btn-close' onClick={() => hideModal()}></button>
            </div>
            <div className='modal-body'>
              <form onSubmit={(e) => createAbsence(e)}>
                <div className='mb-3'>
                  <label htmlFor='ausence-type' className='col-form-label'>
                    Tipo de ausencia:
                  </label>
                  <select className='form-select' defaultValue='' id='ausence-type' required onChange={(e) => setAbsenceType(e.target.value)}>
                    <option value='' disabled>
                      Seleccionar
                    </option>
                    <option value='Vacaciones'>Vacaciones</option>
                    <option value='Asuntos_propios'>Asuntos propios</option>
                    <option value='Enfermedad'>Enfermedad</option>
                  </select>
                </div>
                <div className='mb-3'>
                  <label htmlFor='ausence-type' className='col-form-label'>
                    Fecha:
                  </label>
                  <DatePicker
                    className='datePicker-input'
                    selectsRange={true}
                    locale='es'
                    dateFormat='dd/MM/yyyy'
                    selected={startDate}
                    startDate={startDate}
                    endDate={endDate}
                    filterDate={isWeekday}
                    isClearable={true}
                    onChange={(update) => {
                      setDateRange(update);
                    }}
                    minDate={absenceType !== 'Enfermedad' && new Date()}
                    maxDate={userData.userSession.calendario[parseInt(year) + 1] ? new Date(parseInt(year) + 1, 0, 31) : new Date(parseInt(year), 11, 31)}
                    required
                  />
                </div>
                <div className='mb-3'>
                  <label htmlFor='description' className='col-form-label'>
                    Descripción:
                  </label>
                  <textarea className='form-control' id='description' rows='3'></textarea>
                </div>
                <div className='mb-1 mx-auto submit'>
                  <input type='submit' className='btn btn-success px-5 ' value='Enviar' />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import './styles/TimeControlDpto.css';
import es from 'date-fns/locale/es';

export const TimeControlDpto = () => {
  const [connection, setConnection] = useState(false);
  const [data, setData] = useState({
    dpto: null,
    start: new Date(new Date().getFullYear(), 0, 1),
    end: new Date(new Date().getFullYear(), 0, 1),
  }); // [user, date, type
  const [records, setRecords] = useState([]);

  const navigate = useNavigate();
  document.querySelector('body').style.overflow = 'hidden';

  const exit = () => {
    document.querySelector('body').style.overflow = 'auto';
    navigate('/');
  };

  const dptos = [
    { iniciales: 'DIR', displayName: 'Dirección' },
    { iniciales: 'ADM', displayName: 'Administración' },
    { iniciales: 'FOR', displayName: 'Formación' },
    { iniciales: 'IDE', displayName: 'Otri - Proyectos Internacionales' },
    { iniciales: 'INF', displayName: 'Otri - Vigilancia Tecnológica' },
    { iniciales: 'TEC', displayName: 'Tecnología Procesos' },
    { iniciales: 'IPR', displayName: 'Ingeniería Producto' },
    { iniciales: 'MAT', displayName: 'Materiales' },
    { iniciales: 'ELE', displayName: 'Electrónica' },
  ];

  const columns = [
    {
      name: 'PROYECTO',
      cell: (row) => {
        if (row.FASE === 'TOTALPROYECTO') {
          return;
        } else {
          return <div dangerouslySetInnerHTML={{ __html: `<strong>${row.PROYECTO}</strong>` }}></div>;
        }
      },
    },
    {
      name: 'EMPLEADO',
      selector: (row) => row.EMPLEADO,
    },
    {
      name: 'FASE',
      cell: (row) => {
        if (row.FASE === 'TOTALPROYECTO') {
          return <div dangerouslySetInnerHTML={{ __html: `<strong>${row.FASE}</strong>` }}></div>;
        } else {
          return <div dangerouslySetInnerHTML={{ __html: row.FASE }}></div>;
        }
      },
    },
    {
      name: 'TIEMPO',
      cell: (row) => {
        var seconds = row.TIEMPO;

        var hours = Math.floor(seconds / 3600);
        var minutes = Math.floor((seconds - hours * 3600) / 60)
          .toString()
          .padStart(2, '0');

        if (row.FASE === 'TOTALPROYECTO') {
          return <div dangerouslySetInnerHTML={{ __html: `<strong>${hours}h ${minutes}m</strong>` }}></div>;
        } else {
          return <div dangerouslySetInnerHTML={{ __html: `${hours}h ${minutes}m` }}></div>;
        }
      },
    },
  ];

  const getRecords = async (e) => {
    e.preventDefault();
    if (!data.dpto) {
      alert('Selecciona un departamento');
      return;
    }

    let start = moment(data.start).format('YYYY-MM-DD');
    let end = moment(data.end).format('YYYY-MM-DD');

    let records = await fetch(`${process.env.REACT_APP_SERVER}/records/department/${data.dpto}?start=${start}&end=${end}&apiKey=${process.env.REACT_APP_AQUA_APIKEY}`);
    records = await records.json();
    records = records.message;

    let recordsFiltered = [];
    let totalEmpleado = 0;
    for (let i = 0; i < records.length; i++) {
      recordsFiltered.push(records[i]);
      if (records[i].FASE) {
        if (records[i].EMPLEADO === records[i + 1]?.EMPLEADO) {
          totalEmpleado = totalEmpleado + records[i].TIEMPO;
        } else {
          totalEmpleado = totalEmpleado + records[i].TIEMPO;
          recordsFiltered.push({
            PROYECTO: '',
            EMPLEADO: '',
            FASE: 'TOTAL',
            TIEMPO: totalEmpleado,
          });

          totalEmpleado = 0;
        }
      }

      if (records[i].PROYECTO !== records[i + 1]?.PROYECTO) {
        recordsFiltered.push({
          EMPLEADO: '',
          PROYECTO: records[i].PROYECTO,
          FASE: 'TOTALPROYECTO',
          TIEMPO: records[i].TOTALTIEMPOPROYECTO,
        });
      }
    }

    setRecords(recordsFiltered);
  };

  useEffect(() => {
    (async () => {
      let res = await fetch(`${process.env.REACT_APP_SERVER}/status`);
      res = await res.json();
      if (res.status === 200) {
        setConnection(true);
      }
    })();
  }, []);

  return (
    <div className='modal-route'>
      <div className='timeControlDpto'>
        <div className='modal-header'>
          <h5 className='modal-title'>
            <i className='fa fa-user'></i>Consultar control horario por DEPARTAMENTO
          </h5>
          <button type='button' className='btn-close' onClick={() => exit()}></button>
        </div>
        <div className='modal-body'>
          {window.innerWidth < 1000 ? (
            <div className='row '>
              <img src='static/media/mobile-error.png' alt='Error de conexión' className='connection-error mt-2' />
              <p className='text-center mt-4'>No es posible consultar horas desde el movil</p>
            </div>
          ) : connection === false ? (
            <div className='row '>
              <img src='static/media/network-error.png' alt='Error de conexión' className='connection-error mt-2' />
              <p className='text-center'>No tienes conexion con el servidor</p>
            </div>
          ) : (
            <div className='row'>
              <div className='col-md-3'>
                <form onSubmit={(e) => getRecords(e)}>
                  <div className='form-group mb-3'>
                    <label>Departamento:</label>
                    <select className='form-control' id='user' onChange={(e) => setData({ ...data, dpto: e.target.value })}>
                      <option value='' disabled selected>
                        {' '}
                        Seleccionar{' '}
                      </option>
                      {dptos.map((dpto) => (
                        <option key={dpto.iniciales} value={dpto.iniciales}>
                          {dpto.displayName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className='form-group mb-3'>
                    <label>Fecha inicio:</label>
                    <DatePicker
                      locale={es}
                      dateFormat='dd/MM/yyyy'
                      selected={data.start}
                      onChange={(date) => setData({ ...data, start: date })}
                      selectsStart
                      startDate={data.start}
                      endDate={data.end}
                    />
                  </div>
                  <div className='form-group mb-3'>
                    <label>Fecha fin:</label>

                    <DatePicker
                      locale={es}
                      dateFormat='dd/MM/yyyy'
                      selected={data.end}
                      onChange={(date) => setData({ ...data, end: date })}
                      selectsEnd
                      startDate={data.start}
                      endDate={data.end}
                      minDate={data.start}
                    />
                  </div>
                  <div className='submit'>
                    <button type='submit' className='btn btn-success mt-2'>
                      Consultar
                    </button>
                  </div>
                </form>
              </div>
              <div className='col-md-9'>{<DataTable columns={columns} data={records} />}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

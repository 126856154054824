import React, { useEffect, useState } from 'react';

export const UpdatePWA = () => {
  const [newVersion, setNewVersion] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then((reg) => {
        if (reg.waiting !== null && reg.waiting.state === 'installed') {
          if (newVersion) {
            reg.waiting.postMessage({ type: 'SKIP_WAITING' });
            window.location.reload();
          } else {
            //New version detected
            setShowAlert(true);
          }
        }
      });
    }
  }, [newVersion]);

  const updatePWA = () => {
    setNewVersion(true);
  };

  if (!showAlert) {
    return <></>;
  }

  return (
    <div>
      <div className='alert-content'>
        <div className='alert alert-success' role='alert'>
          Aplicación actualizada
          <span className='float-end fw-bold' role='button' onClick={updatePWA}>
            CONFIRMAR
          </span>
        </div>
      </div>
    </div>
  );
};

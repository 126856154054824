import React, { useState, useEffect } from 'react';
import { setDoc, doc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { db } from '../../firebase';
import moment from 'moment';

export const EditCalendar = (props) => {
  const calendar = props.calendar.data;

  const [data, setData] = useState(calendar);
  const [inputValues, setInputValues] = useState([]);
  const [activeInput, setActiveInput] = useState({});

  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  const navigate = useNavigate();
  document.querySelector('body').style.overflow = 'hidden';

  useEffect(() => {
    const ArrInputValues = [];
    Object.values(calendar).map((month) => {
      month.days.map((day) => {
        if (day.hours !== '00:00' && !ArrInputValues.find((input) => input.time === day.hours)) {
          //console.log('encontrado');
          ArrInputValues.push({
            time: day.hours,
            color: day.color,
          });
        }
      });
    });
    setData({ ...calendar });
    setInputValues([...ArrInputValues]);
    setActiveInput(ArrInputValues[0]);
  }, [calendar]);

  const addTime = (e) => {
    const month = moment(e.target.id).format('MMMM');
    const day = moment(e.target.id).format('DD') - 1;

    if (moment(e.target.id).day() > 0 && moment(e.target.id).day() < 6) {
      //Resta al total si ya hay horas en el día
      if (data[month].days[day].hours !== null) {
        let total = data[month].total.split(':');
        total[0] = parseInt(total[0]) - parseInt(data[month].days[day].hours.split(':')[0]);
        total[1] = parseInt(total[1]) - parseInt(data[month].days[day].hours.split(':')[1]);
        if (total[1] < 0) {
          total[0] = parseInt(total[0]) - 1;
          total[1] = parseInt(total[1]) + 60;
        }
        data[month].total = `${total[0].toString().padStart(2, '0')}:${total[1].toString().padStart(2, '0')}`;
      }

      //Añade el registro de horas al día
      data[month].days[day].hours = activeInput.time;
      data[month].days[day].color = activeInput.color;

      //Suma al total
      let total = data[month].total.split(':');
      total[0] = parseInt(total[0]) + parseInt(activeInput.time.split(':')[0]);
      total[1] = parseInt(total[1]) + parseInt(activeInput.time.split(':')[1]);
      if (total[1] > 59) {
        total[0] = parseInt(total[0]) + 1;
        total[1] = parseInt(total[1]) - 60;
      }
      data[month].total = `${total[0].toString().padStart(2, '0')}:${total[1].toString().padStart(2, '0')}`;

      document.getElementById(e.target.id).style.backgroundColor = activeInput.color;
      document.getElementById(e.target.id).style.color = 'white';

      setData({ ...data });
    }
  };

  const onConfirm = async () => {
    let status = true;
    Object.values(data).map((month) => {
      month.days.map((day) => {
        if (day.hours === null) {
          return (status = false);
        }
      });
    });

    if (status) {
      const calendarRef = doc(db, 'calendars', props.calendar.id);
      await setDoc(calendarRef, { data: data }, { merge: true });
      document.querySelector('body').style.overflow = 'auto';
      navigate(0);
    }
  };

  const showModal = () => {
    document.getElementById('workCalendar-modal').classList.toggle('disabled');
  };

  const hideModal = () => {
    document.getElementById('workCalendar-modal').classList.toggle('disabled');
  };

  return (
    <>
      <div className='d-flex gap-2' style={{ marginLeft: '5%', marginBottom: '40px' }}>
        <button className='btn btn-light shadow-sm' onClick={() => showModal()}>
          <i className='fa-solid fa-plus '></i>
        </button>
        {inputValues.map((input) => (
          <div key={input.color}>
            <button
              className='btn btn-default'
              style={{ border: `1px solid ${input.color}`, color: `${input.color}`, backgroundColor: `${input.color + '30'}` }}
              onClick={() => setActiveInput({ time: input.time, color: input.color })}
            >
              {input.time}
            </button>
          </div>
        ))}
        <button className='btn btn-outline-warning rounded-lg' onClick={() => setActiveInput({ time: '00:00', color: '#ffc107' })}>
          Festivo
        </button>
        <button
          className='btn btn-default rounded-lg'
          style={{ border: `1px solid #ff8800`, color: `#ff8800`, backgroundColor: `#ff880030` }}
          onClick={() => setActiveInput({ time: '00:00', color: '#ff8800' })}
        >
          Vacaciones
        </button>
      </div>
      <div className='calendar'>
        {months.map((month) => (
          <div key={month}>
            <h3>{month}</h3>
            <div className='month'>
              <div className='day-name'>Lun</div>
              <div className='day-name'>Mar</div>
              <div className='day-name'>Mie</div>
              <div className='day-name'>Jue</div>
              <div className='day-name'>Vie</div>
              <div className='day-name'>Sab</div>
              <div className='day-name'>Dom</div>

              {data[month].days.map((day, i) =>
                // si es el primer day
                i === 0 ? (
                  <div
                    id={day.date}
                    key={month + i}
                    className={`day ${day.color ? 'text-white' : ''}`}
                    style={{ gridColumnStart: ` ${moment(day.date).isoWeekday()} `, backgroundColor: `${day.color}` }}
                    onClick={(e) => addTime(e)}
                  >
                    {moment(day.date).format('DD')}
                  </div>
                ) : (
                  <div id={day.date} key={month + i} className={`day ${day.color ? 'text-white' : ''}`} style={{ backgroundColor: `${day.color}` }} onClick={(e) => addTime(e)}>
                    {moment(day.date).format('DD')}
                  </div>
                )
              )}
            </div>
            <div className='total'>
              <h5>Total: {calendar[month].total}</h5>
            </div>
          </div>
        ))}
      </div>

      <div className={'mt-4'} style={{ marginLeft: '35%' }}>
        <button className='btn btn-danger w-50 ml-25' onClick={() => onConfirm()}>
          Guardar
        </button>
      </div>

      <div className='modal disabled' id='workCalendar-modal'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h1 className='modal-title fs-5' id='exampleModalLabel'>
                Crear jornada
              </h1>
              <button type='button' className='btn-close' onClick={() => hideModal()}></button>
            </div>
            <div className='modal-body'>
              <form className='mt-2'>
                <div className='row gx-2'>
                  <div className='col-md-2 mb-3'>
                    <input type='color' className='form-control' value='#ffc107' disabled />
                  </div>
                  <div className='col-md-10 mb-3'>
                    <input type='text' className='form-control' value='Festivo' disabled />
                  </div>
                </div>
                <div className='row gx-2'>
                  <div className='col-md-2 mb-2'>
                    <input type='color' className='form-control' value='#ff8000' disabled />
                  </div>
                  <div className='col-md-10 mb-2'>
                    <input type='text' className='form-control' value='Vacaciones' disabled />
                  </div>
                </div>
                {inputValues.map((input, i) => (
                  <div className='row gx-2' key={input.color + i}>
                    <div className='col-md-2 my-2 '>
                      <input
                        type='color'
                        className='form-control'
                        defaultValue={input.color}
                        onChange={(e) => {
                          setInputValues([...inputValues.slice(0, i), { time: inputValues[i].time, color: e.target.value }, ...inputValues.slice(i + 1)]);
                        }}
                      />
                    </div>
                    <div className='col-md-10 my-2'>
                      <input
                        type='time'
                        className='form-control'
                        defaultValue={input.time}
                        min='05:00'
                        max='10:00'
                        onChange={(e) => {
                          setInputValues([...inputValues.slice(0, i), { time: e.target.value, color: inputValues[i].color }, ...inputValues.slice(i + 1)]);
                        }}
                      />
                    </div>
                  </div>
                ))}
              </form>
              <div className='more'>
                <button type='button' className='btn btn-default mx-2' onClick={() => setInputValues([...inputValues, { time: '00:00', color: 'black' }])}>
                  <i className='fa-solid fa-plus'></i>
                </button>
                <button type='button' className='btn btn-default mx-2' onClick={() => setInputValues(inputValues.slice(0, inputValues.length - 1))}>
                  <i className='fa-solid fa-minus'></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

import React, { useState, useEffect, useContext } from 'react';
import { userContext } from '../../context/UserContext';
import { useNavigate } from 'react-router-dom';
import { getDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase';
import { ShowCalendar } from '../../components/ui/ShowCalendar';
import './styles/WorkerCalendar.css';

export const WorkerCalendar = () => {
  const { userData } = useContext(userContext);

  const [calendar, setCalendar] = useState(null);

  const navigate = useNavigate();
  document.querySelector('body').style.overflow = 'hidden';

  useEffect(() => {
    const workerCalendar = userData.userSession.calendario[new Date().getFullYear()].id;
    //Obtiene el calendario del empleado
    getDoc(doc(db, 'calendars', workerCalendar)).then((calendar) => {
      setCalendar(calendar.data());
    });
  }, []);

  const exit = () => {
    document.querySelector('body').style.overflow = 'auto';
    navigate('/');
  };

  return (
    <div className='modal-route'>
      <div className='workerCalendar'>
        <div className='modal-header'>
          <h5 className='modal-title'>
            <i className='fa fa-calendar'></i>Calendario Laboral
          </h5>
          <button type='button' className='btn-close' onClick={() => exit()}></button>
        </div>
        <div className='modal-body'>
          <div className='row'>
            <div className='col-md-12'>{calendar !== null && <ShowCalendar calendar={calendar} />}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

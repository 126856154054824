import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { db } from '../../firebase';
import { FixRecords } from '../../components/ui/FixRecords';
import { ShowAbsences } from '../../components/ui/ShowAbsences';

import './styles/Notifications.css';

export const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [activeNotifications, setActiveNotifications] = useState(null);
  const modal = useRef(null);

  const navigate = useNavigate();
  document.querySelector('body').style.overflow = 'hidden';

  const exit = () => {
    document.querySelector('body').style.overflow = 'auto';
    navigate('/');
  };

  useEffect(() => {
    onSnapshot(query(collection(db, 'notifications'), where('processed', '==', false)), (documents) => {
      var arrRecords = [];
      documents.forEach((document) => {
        arrRecords.push({ id: document.id, ...document.data() });
      });
      setNotifications(arrRecords);
    });
  }, []);

  const onSelected = (data) => {
    setActiveNotifications(data);
    //hacer scroll hacia arriba en el modal
    modal.current.scrollTop = 0;
  };

  return (
    <div className='modal-route' ref={modal}>
      <div className='notifications'>
        <div className='modal-header'>
          <h5 className='modal-title'>
            <i className='fa-regular fa-bell'></i>Notificaciones
          </h5>
          <button type='button' className='btn-close' onClick={() => exit()}></button>
        </div>
        <div className='modal-body'>
          <div className='row'>
            <div className='col-md-3'>
              <ul className='nav nav-pills nav-stacked'>
                {notifications.map((data) => (
                  <li className='mb-4' key={data.id}>
                    <a data-toggle='pill' onClick={() => onSelected(data)}>
                      <div className='card'>
                        <div className='card-header'>
                          <div className='row'>
                            <div className='col-xs-4 col-lg-8'>
                              <img src={data.avatar} alt='Avatar' className='avatar' />
                              <p>Solicitud: {data.name}</p>
                            </div>
                            <div className='col-xs-5 col-lg-4'>
                              <span className={`btn btn-outline float-end ${data.type}`}>{data.type}</span>
                            </div>
                          </div>
                        </div>
                        <div className='card-body'>
                          <p>{data.name} ha solicitado revisar los siguientes registros de fichaje</p>
                        </div>
                      </div>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className='col-md-9'>
              <div className='tab-content'>
                {activeNotifications ? (
                  activeNotifications.type === 'Error_fichaje' || activeNotifications.type === 'Error_app' ? (
                    <FixRecords data={activeNotifications} />
                  ) : (
                    <ShowAbsences data={activeNotifications} />
                  )
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

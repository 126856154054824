import { useState, useEffect, useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Header } from '../../components/ui/Header';
import { Banner } from '../../components/ui/Banner';
import { DayStatus } from '../../components/ui/DayStatus';
import { CurrentDay } from '../../components/ui/CurrentDay';
import { CurrentWeek } from '../../components/ui/CurrentWeek';
import { CurrentMonth } from '../../components/ui/CurrentMonth';
import { MonthlyRemaining } from '../../components/ui/MonthlyRemaining';
import { TimeControlPending } from '../../components/ui/TimeControlPending';
import { MonthlyBalance } from '../../components/ui/MonthlyBalance';
import { CurrentMonthRecords } from '../../components/ui/CurrentMonthRecords';
import { CurrentPreviousMonthRecords } from '../../components/ui/CurrentPreviousMonthRecords';
import { ToastContainer } from 'react-toastify';
import { GeneratePDF } from '../../functions/GeneratePDF';
import { UpdatePWA } from '../../components/UpdatePWA';
import { InstallPWA } from '../../components/InstallPWA';
import { FloatingButton } from '../../components/ui/FloatingButton';
import { NavbarMobile } from '../../components/ui/NavbarMobile';
import { useNavigate } from 'react-router-dom';
import { userContext } from './../../context/UserContext';

import './styles/MainPage.css';

export function MainPage() {
  const { userData } = useContext(userContext);
  const navigate = useNavigate();

  const [monthRecord, setMonthRecords] = useState(<CurrentMonthRecords user={userData} />);
  const [refresh, setRefresh] = useState(true);

  //Refresca la pagina cada vez que se abre.
  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'visible') {
      setRefresh(true);
    } else {
      setRefresh(false);
    }
  });

  useEffect(() => {
    if (userData && parseInt(userData.unjustifiedWeeks) > 1) {
      navigate('/time-control');
    } else if (userData && parseInt(userData.unjustifiedWeeks) === 1) {
      navigate('/alert');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  if (!userData) {
    return <h1 style={{ textAlign: ' Center', marginTop: '50vh' }}> Cargando ... </h1>;
  }

  return (
    <div className='mainPage'>
      <Header user={userData} />
      <Banner user={userData} />
      <div className='main'>
        <div className='row'>
          <div className='col-md-5'>
            <div className='row'>
              <div className='col-md-6 day'>
                <Link to='/worker-status'>
                  <DayStatus user={userData} />
                </Link>
              </div>
              <div className='col-md-6 day'>
                <CurrentDay user={userData} refresh={refresh} />
              </div>
              <div className='col-md-6 week'>
                <CurrentWeek user={userData} />
              </div>
              <div className='col-md-6 month'>
                <CurrentMonth user={userData} />
              </div>
              <div className='col-md-6 month'>
                <MonthlyRemaining user={userData} />
              </div>
              <div className='col-md-6 month'>
                <MonthlyBalance user={userData} />
              </div>
              <div className='col-md-6 month'>
                <Link to='/time-control'>
                  <TimeControlPending user={userData} />
                </Link>
              </div>
            </div>
          </div>
          <div className='col-md-7'>
            <div className='row'>
              <div className='col-md-12 day'>
                <div className='monthSelector'>
                  {userData.userSession.privileges && (
                    <Link to='/worker-records'>
                      {' '}
                      <i className='fa fa-search' aria-hidden='true' data-toggle='modal' data-target='#toggleWorkerRecords'></i>{' '}
                    </Link>
                  )}
                  <i onClick={() => setMonthRecords(<CurrentPreviousMonthRecords />)} className='fa fa-chevron-left'></i>
                  <i onClick={() => setMonthRecords(<CurrentMonthRecords />)} className='fa fa-chevron-right'></i>
                </div>
                {monthRecord}
                <div className='row'>
                  <div className='col-md-4'></div>
                  <div className='col-md-4'>
                    <button type='button' className='btn btn-danger' onClick={() => GeneratePDF(userData)}>
                      <i className='far fa-file-pdf'></i>Exportar Control Horario
                    </button>
                  </div>
                  <div className='col-md-4' style={{ marginBottom: '20%' }}>
                    <InstallPWA />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />
      <div className='version' style={{ textAlign: 'center' }}>
        <p>CETEM - v4.1</p>
      </div>

      <UpdatePWA />
      <Outlet />
      <FloatingButton />
      <NavbarMobile />
      <ToastContainer position='top-right' autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <ToastContainer />
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, query, getDocs, updateDoc, doc, where } from 'firebase/firestore';
import { db } from '../../firebase';
import { departments } from '../../constants/departments';
import './styles/WorkerList.css';

export const WorkerList = () => {
  const navigate = useNavigate();

  const [usuarios, setUsuarios] = useState([]);
  const [calendarios, setCalendarios] = useState([]);
  const [status, setStatus] = useState('Activo');

  useEffect(() => {
    const arrUser = [];
    getDocs(query(collection(db, 'users'))).then((data) => {
      data.forEach((doc) => {
        arrUser.push(doc.data());
      });
      arrUser.sort((a, b) => (a.displayName > b.displayName ? 1 : b.displayName > a.displayName ? -1 : 0));
      setUsuarios(arrUser);
    });
  }, []);

  useEffect(() => {
    const arrCalendars = [];
    getDocs(query(collection(db, 'calendars'), where('year', '==', new Date().getFullYear().toString()))).then((data) => {
      data.forEach((doc) => {
        const calendar = { id: doc.id, ...doc.data() };
        arrCalendars.push(calendar);
      });
      setCalendarios(arrCalendars);
    });
  }, []);

  const updateUser = async (e, user) => {
    e.preventDefault();
    const userData = {
      displayName: e.target[0].value,
      email: e.target[1].value,
      iniciales: e.target[2].value,
      departamento: e.target[3].value,
      estadoSesion: e.target[4].value,
      ['calendario.' + new Date().getFullYear() + '.id']: e.target[5].value,
      ['calendario.' + new Date().getFullYear() + '.horas_variables']: e.target[6].value.split(':')[0] * 60 + e.target[6].value.split(':')[1] * 1,
      observaciones: e.target[8].value,
    };

    await updateDoc(doc(db, 'users', user.id), userData);
  };

  document.querySelector('body').style.overflow = 'hidden';
  const exit = () => {
    document.querySelector('body').style.overflow = 'auto';
    navigate('/');
  };

  return (
    <div className='modal-route'>
      <div className='workerList'>
        <div className='modal-header'>
          <h5 className='modal-title'>
            <i className='fa fa-user'></i>Listado de trabajadores
          </h5>
          <button type='button' className='btn-close' onClick={() => exit()}></button>
        </div>
        <div className='modal-body'>
          <div className='row'>
            <div className='col-md-12'>
              <ul class='nav nav-tabs '>
                <li class='nav-item'>
                  <span class={`nav-link d-flex align-items-center gap-2 ${status === 'Activo' ? 'active' : ''}`} onClick={() => setStatus('Activo')} aria-current='page'>
                    <i class='fa-regular fa-eye'></i>
                    Activos
                  </span>
                </li>
                <li class='nav-item'>
                  <span class={`nav-link d-flex align-items-center gap-2 ${status === 'Deshabilitado' ? 'active' : ''}`} onClick={() => setStatus('Deshabilitado')}>
                    <i class='fa-regular fa-eye-slash'></i>
                    Deshabilitados
                  </span>
                </li>
              </ul>
              <div className='list'>
                <div className='row border-bottom mx-1 mb-3 pb-2'>
                  <div className='col-md-4'>
                    <span className='fw-bold'>Empleados</span>
                  </div>
                  <div className='col-md-4'>
                    <span className='fw-bold'>Correo</span>
                  </div>
                  <div className='col-md-2'>
                    <span className='fw-bold'>Departamento</span>
                  </div>
                  <div className='col-md-2'>
                    <span className='fw-bold'>Estado</span>
                  </div>
                </div>

                {usuarios
                  .filter((user) => (status === 'Deshabilitado' ? user.estadoSesion === 'Deshabilitado' : user.estadoSesion !== 'Deshabilitado'))
                  .map((user) => (
                    <div className='worker row shadow-sm'>
                      <div
                        className='col-md-12 d-flex align-items-center px-3 py-2'
                        data-bs-toggle='collapse'
                        data-bs-target={'#' + user.displayName.replace(/\s/g, '-')}
                        aria-expanded='false'
                        aria-controls='employee'
                      >
                        <div className='col-md-4 d-flex gap-4 align-items-center'>
                          <img src={user.photoURL} alt='Avatar' className='avatar' />
                          <p className='float-start fw-bold '>{user.displayName}</p>
                        </div>
                        <div className='col-md-4'>
                          <p className='float-start fw-semibold '>{user.email}</p>
                        </div>
                        <div className='col-md-2'>
                          <p className='float-start fw-semibold '>{user.departamento}</p>
                        </div>
                        <div className='col-md-2'>
                          <p className={`float-start fw-semibold ${user.estadoSesion}`}>{user.estadoSesion}</p>
                        </div>
                      </div>
                      <div className='collapse' id={user.displayName.replace(/\s/g, '-')}>
                        <hr className='my-4' />
                        <div>
                          <form onSubmit={(e) => updateUser(e, user)}>
                            <div className='row'>
                              <div className='mb-3 col-md-4'>
                                <label htmlFor='name' className='form-label'>
                                  Nombre
                                </label>
                                <input type='text' className='form-control' id='name' defaultValue={user.displayName} required />
                              </div>
                              <div className='mb-3 col-md-8'>
                                <label htmlFor='email' className='form-label'>
                                  Dirección de correo
                                </label>
                                <input type='email' className='form-control' id='email' defaultValue={user.email} required />
                              </div>
                              <div className='mb-3 col-md-4'>
                                <label htmlFor='initials' className='form-label'>
                                  Iniciales
                                </label>
                                <input type='text' className='form-control' id='initials' defaultValue={user.iniciales} required />
                              </div>
                              <div className='mb-3 col-md-4'>
                                <label htmlFor='departament' className='form-label'>
                                  Departamento
                                </label>
                                <select className='form-select' id='departament' aria-label='Floating label select example' required>
                                  {departments.map((department) => (
                                    <option key={department} value={department} selected={user.departamento === department ? true : false}>
                                      {department}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className='mb-3 col-md-4'>
                                <label htmlFor='estado' className='form-label'>
                                  Estado
                                </label>
                                <select className='form-select' id='estado' aria-label='Floating label select example 2' required>
                                  <option value='Desconectado' selected={user.estadoSesion === 'Desconectado' ? true : false}>
                                    Desconectado
                                  </option>
                                  <option value='Presencial' selected={user.estadoSesion === 'Presencial' ? true : false}>
                                    Presencial
                                  </option>
                                  <option value='Teletrabajo' selected={user.estadoSesion === 'Teletrabajo' ? true : false}>
                                    Teletrabajo
                                  </option>
                                  <option value='Viaje' selected={user.estadoSesion === 'Viaje' ? true : false}>
                                    Viaje
                                  </option>
                                  <option value='Deshabilitado' selected={user.estadoSesion === 'Deshabilitado' ? true : false}>
                                    Deshabilitado
                                  </option>
                                </select>
                              </div>
                              <div className='mb-3 col-md-4'>
                                <label htmlFor='password' className='form-label'>
                                  Calendario ( Año Actual )
                                </label>
                                <select className='form-select' id='departament' aria-label='Floating label select example' required>
                                  {calendarios.map((calendar) => (
                                    <option key={calendar.id} value={calendar.id} selected={user.calendario?.[new Date().getFullYear()]?.id === calendar.id ? true : false}>
                                      {calendar.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className='mb-3 col-md-4'>
                                <label htmlFor='Horas' className='form-label'>
                                  Horas Variables
                                </label>
                                <input
                                  type='text'
                                  className='form-control'
                                  id='Horas'
                                  defaultValue={
                                    user?.calendario?.[new Date().getFullYear()]
                                      ? `${Math.floor(user?.calendario[new Date().getFullYear()]?.horas_variables / 60)} : ${(
                                          user?.calendario[new Date().getFullYear()]?.horas_variables % 60
                                        )
                                          .toString()
                                          .padStart(2, '0')}`
                                      : 'ERROR'
                                  }
                                  disabled={user?.calendario?.[new Date().getFullYear()] ? false : true}
                                  required
                                />
                              </div>
                              <div className='mb-3 col-md-4'>
                                <label htmlFor='formFile' className='form-label'>
                                  Selecciona una imagen
                                </label>
                                <input className='form-control' type='file' id='formFile' />
                              </div>
                              <div className='mb-3 col-md-12'>
                                <label htmlFor='Observaciones' className='form-label'>
                                  Observaciones
                                </label>
                                <textarea className='form-control' id='Observaciones' rows='3'>
                                  {user.observaciones}
                                </textarea>
                              </div>
                              <div className='mb-3 col-md-12'>
                                <button type='submit' className='btn btn-default' data-bs-toggle='collapse' data-bs-target={'#' + user.displayName.replace(/\s/g, '-')}>
                                  Confirmar
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

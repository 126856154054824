import React from 'react';
import { Link } from 'react-router-dom';

export const Banner = (props) => {
  const { userSession } = props.user;
  return (
    <div className='banner'>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-5 user'>
            <h1>Bienvenido</h1>
            <h5>{userSession.displayName}</h5>
          </div>
          <div className={'col-md-7 registros'}>
            <Link to='/input'>
              <button type='button' className='btn btn-success'>
                <i className='fas fa-sign-in-alt'></i>Entrada
              </button>
            </Link>
            <Link to='/output'>
              <button type='button' className='btn btn-danger'>
                <i className='fas fa-sign-out-alt'></i>Salida
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

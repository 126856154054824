import * as XLSX from 'xlsx';
export const ExportCHXLS = async () => {
  let data = await fetch(`${process.env.REACT_APP_SERVER}/records?apiKey=${process.env.REACT_APP_AQUA_APIKEY}`);
  data = await data.json();

  data.message = data.message.map((item) => {
    delete item.ID;
    item.HORAS = new Date(0, 0, 0, item.HORAS.slice(11, 13), item.HORAS.slice(14, 16));
    return item;
  });

  data.message.sort((a, b) => {
    return a.SEMANA - b.SEMANA;
  });

  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(data.message);

  // Establecer el formato de celda para la columna de tiempo
  const colWidth = [{ wch: 20 }];
  ws['!cols'] = colWidth;

  // Establecer el formato de celda para la columna de tiempo
  const timeFormat = '[h]:mm;@';
  for (let r = 1; r <= data.message.length; r++) {
    const cell = XLSX.utils.encode_cell({ r, c: 6 });
    if (ws[cell] && ws[cell].t === 'n') {
      ws[cell].z = timeFormat;
    }
  }

  // Agregar la hoja al libro
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  // Guardar el libro como un archivo Excel
  XLSX.writeFile(wb, 'CH_Global.xlsx');
  console.log('Archivo de Excel creado exitosamente.');
};

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserState } from '../../hooks/useUserState';
import DataTable from 'react-data-table-component';
import { customStyles } from '../../constants/dataTableStyle';
import './styles/WorkerStatus.css';

const Order = (lista) => {
  lista.sort((a, b) => (a.displayName > b.displayName ? 1 : b.displayName > a.displayName ? -1 : 0));
  return lista;
};

export const WorkerStatus = () => {
  const navigate = useNavigate();
  document.querySelector('body').style.overflow = 'hidden';

  const exit = () => {
    document.querySelector('body').style.overflow = 'auto';
    navigate('/');
  };

  var presenceUsers = useUserState('Presencial');
  var teleworkingUser = useUserState('Teletrabajo');
  var disconectedUsers = useUserState('Desconectado');
  var holidaysUsers = useUserState('Vacaciones');
  var tripUsers = useUserState('Viaje');

  var activeUsers = presenceUsers.concat(teleworkingUser);
  var disconectedUsers = disconectedUsers.concat(holidaysUsers);

  activeUsers = Order(activeUsers);
  disconectedUsers = Order(disconectedUsers);
  tripUsers = Order(tripUsers);

  const modelColumns = [
    {
      name: 'photoURL',
      selector: 'photoURL',
      sortable: true,
      cell: (row) => (
        <li className={`status ${row.estadoSesion}`}>
          <img className='avatar' src={row.photoURL} alt={'Avatar'} />
        </li>
      ),
      width: '28%',
    },
    {
      name: 'iniciales',
      cell: (row) => <div style={{ fontWeight: 700 }}>{row.iniciales}</div>,
      width: '20%',
    },
    {
      name: 'displayName',
      selector: 'displayName',
      width: '42%',
    },
    {
      name: 'extensión',
      cell: (row) => <div style={{ fontWeight: 'bold' }}>{row.ext}</div>,
      width: '15%',
    },
  ];

  return (
    <div className='modal-route'>
      <div className='workerStatus'>
        <div className='modal-header'>
          <h5 className='modal-title'>
            <i className='fa fa-user'></i>Estado del personal
          </h5>
          <button type='button' className='btn-close' onClick={() => exit()}></button>
        </div>
        <div className='modal-body'>
          <div className='row'>
            <div className='col-md-4 active-user'>
              <p>Usuarios activos</p>
              <hr />
              <DataTable noHeader columns={modelColumns} data={activeUsers} customStyles={customStyles} />
              <hr />
            </div>
            <div className='col-md-4 disconnect-user'>
              <p>Usuarios desconectados</p>
              <hr />
              <DataTable noHeader columns={modelColumns} data={disconectedUsers} customStyles={customStyles} />
            </div>
            <div className='col-md-4 trip-user'>
              <p>Usuarios de viaje</p>
              <hr />
              <DataTable noHeader columns={modelColumns} data={tripUsers} customStyles={customStyles} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { userContext } from '../context/UserContext';
import { Loader } from './Loader';
import clockRegistry from './../assets/animations/clockRegistry.json';

export function RoleRoutes({ children }) {
  const { loading, userData } = useContext(userContext);

  if (loading) {
    return <Loader type={clockRegistry} loop={true} width={'250px'} />;
  }

  if (userData.userSession.role !== 'Responsable' && !userData.userSession.privileges) {
    return <Navigate to='/' />;
  }

  return children ? children : <Outlet />;
}

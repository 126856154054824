import { collection, query, getDocs, getDoc, doc, where } from 'firebase/firestore';
import { db } from './../firebase';
import moment from 'moment';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export const ExportPDF = async () => {
  const users = await getDocs(query(collection(db, 'users'), where('estadoSesion', '!=', 'Deshabilitado')));
  users.forEach((user) => {
    //prettier-ignore

    if (user.data().estadoSesion !== 'Deshabilitado') {
      getDoc(doc(db, 'users', user.id, 'registros', moment().subtract(1, 'months').format('MMMM-YYYY'))).then(async (document) => {
        let theoretical = 0;
        let absences = 0;
        let real = 0;
        let calendar = {};

        if (user.data().calendario?.[moment().subtract(1, 'months').format('YYYY')]) {
          //Obtiene el calendario
          calendar = await getDoc(doc(db, 'calendars', user.data().calendario[moment().subtract(1, 'months').format('YYYY')].id)).then((document) => {
            return document.data().data[moment().subtract(1, 'months').format('MMMM')];
          });

          //Obtiene total teorico en funcion del calendario
          theoretical = calendar.total.split(':')[0] * 60 + parseInt(calendar.total.split(':')[1]);

          //Obtiene total vacaciones si las hubiera
          absences = await getDocs(query(collection(db, 'users', user.id, 'ausencias'), where('status', '==', 'Aceptado'))).then((doc) => {
            const days = [];

            doc.forEach((document) => {
              //si hay registros de vacaciones en este mes
              //prettier-ignore
              if (moment(document.data().startDate, 'DD/MM/YYYY').format('MM/YYYY') === moment().subtract(1, 'months').format('MM/YYYY') || moment(document.data().endDate, 'DD/MM/YYYY').format('MM/YYYY') === moment().subtract(1, 'months').format('MM/YYYY')) {
                
                let start = moment(document.data().startDate, 'DD/MM/YYYY');
                let end = moment(document.data().endDate, 'DD/MM/YYYY');

                //Detectar si hay cambio de mes en el periodo de vacaciones
                if (start.format('MM') !== end.format('MM')) {
                  end = moment().subtract(1, 'months').endOf('month');
                }

                //Detectar si hay cambio de mes en el periodo de vacaciones
                if (start.format('MM') !== end.format('MM')) {
                  if (start.format('MM') === moment().subtract(1, 'months').format('MM')) {
                    end = moment(start).endOf('month');
                  } else {
                    start = moment(end).startOf('month');
                  }
                }

                if (start.format('YYYY/MM/DD') === end.format('YYYY/MM/DD')) {
                  days.push([start.format('DD-MM-YYYY'), '', '', '', calendar.days[start.format('D') - 1].hours.substr(1), document.data().type]);

                  real = real + parseInt(calendar.days[start.format('D') - 1].hours.split(':')[0]) * 60 + parseInt(calendar.days[start.format('D') - 1].hours.split(':')[1]);
                } else {
                  for (var m = start; m.isBefore(end + 1); m.add(1, 'days')) {
                    days.push([m.format('DD-MM-YYYY'), '', '', '', calendar.days[m.format('D') - 1].hours.substr(1), document.data().type]);
                    real = real + parseInt(calendar.days[m.format('D') - 1].hours.split(':')[0]) * 60 + parseInt(calendar.days[m.format('D') - 1].hours.split(':')[1]);
                  }
                }
              }
            });

            return days;
          });

          var data = [];

          //Comprueba si tiene registros
          
          if(document.data()){

            //Crea un array con los registros del mes
            var datos = Object.entries(document.data()).sort(); 
            var long1 = Object.entries(document.data()).length;

            for (var i = 0; i<long1; i++){
                
              var long2 = Object.keys(datos[i][1]).length;

              //Almacena el registro anterior para poder contabilizar el tramo anterior en caso de error
              var totalPreviousRecord ;

              for (var j = 0; j<long2; j++){
                  
                const unordered = datos[i][1][j];
                const ordered = {}

                Object.keys(unordered).sort().forEach(function(key) {
                  ordered[key] = unordered[key];
                });

                //Comprueba que exista y almacena el último registro existente 
                if (Object.values(ordered)[3]){
                  totalPreviousRecord = Object.values(ordered)[3];
                }                                         
                
                //Calcula el total de horas y minutos NO HACER SOBRE LA COLUMNA TRAMO
                if(j === long2-1){
                  if (Object.values(ordered)[3]){
                    real = real + Object.values(ordered)[3].split(":")[0] * 60 + parseInt(Object.values(ordered)[3].split(":")[1]);
                  }
                  else{
                    if(totalPreviousRecord){
                      real = real + totalPreviousRecord.split(":")[0] * 60 + parseInt(totalPreviousRecord.split(":")[1]);
                    }
                  }
                  //restablecemos el totalPreviousRecord a indefinido
                  totalPreviousRecord = undefined;
                }
                
                var arrReg = [];
                arrReg[0] = datos[i][0];
                
                if (Object.values(ordered)[0].indexOf("<i") !== -1){
                  //arrReg[2] = Object.values(ordered)[1];
                  arrReg[1] = "Viaje"
                }
                else {
                  arrReg[1] = Object.values(ordered)[0]
                }

                
                if (Object.values(ordered)[1].indexOf("<i") !== -1){
                  //arrReg[2] = Object.values(ordered)[1];
                  arrReg[2] = "Viaje"
                }
                else if ( Object.values(ordered)[1].indexOf("<strong") !== -1 ){
                  arrReg[2] = Object.values(ordered)[1].slice(8, 13) + " (*)";
                }
                else {
                  arrReg[2] = Object.values(ordered)[1]
                }
                
                //arrReg[2] = Object.values(ordered)[1];
                arrReg[3] = Object.values(ordered)[2];
                arrReg[4] = Object.values(ordered)[3];
                arrReg[5] = Object.values(ordered)[4];

                data.push(arrReg);

              } 
            } 

            //añade a data los dias de vacaciones
            data = data.concat(absences)

            //Ordena por el primer valor del array
            data.sort(function(a, b) {
              var dateA = a[0].split('-').reverse().join('');
              var dateB = b[0].split('-').reverse().join('');
              return dateA.localeCompare(dateB);
            });

            //Añade una fila de vacaciones
            data.push([" ", " ", " ", " ", "Teorico mes:", Math.floor(theoretical/60) + ":" + theoretical%60]);
            data.push([" ", " ", " ", " ", "Acumulado:", Math.floor(real/60) + ":" + real%60]);
          
          }

          //Crea el informe PDF
          var img = new Image();
          img.src = 'static/media/logo.png';

          var file = new jsPDF();

          file.setFont('Helvetica', 'normal', 'bold');
          file.text(20, 25, 'Control Horario CETEM');

          file.setFont('Helvetica', 'normal', '400');
          file.text('Usuario: ' + user.data().displayName, 20, 35);

          moment.locale('es');
          file.setFont('Helvetica', 'normal', '400');
          file.text('Mes: ' + moment().subtract(1, 'months').format('MMMM'), 20, 40);

          file.addImage(img, 'PNG', 120, 15);

          var columns = ['Fecha', 'Entrada', 'Salida', 'T. Tramo', 'Total Día', 'Observaciones'];

          file.autoTable(columns, data, {
            margin: { top: 45 },
            headStyles: {
              fillColor: [226, 149, 0],
              fontSize: 12,
            },
            columnStyles: {
              4: {
                fontStyle: 'bold',
                textColor: [0, 0, 0],
              },
            },
          });

          if (real > theoretical) {
            file.save(user.data().displayName + '.pdf');
          } else {
            file.save('Error - ' + user.data().displayName + '.pdf');
          }
        } else {
          alert(user.data().displayName + ' no tiene calendario asignado para el año ' + moment().subtract(1, 'months').format('YYYY') + ' y no se puede generar el informe.');
        }
      });
    }
  });
};

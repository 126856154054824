import React, { useState, useEffect } from 'react';
import { getDoc, doc, getDocs, collection, query, where } from 'firebase/firestore';
import { db } from '../../firebase';
import moment from 'moment';

export const CurrentMonth = (props) => {
  const records = props.user.userRecords;
  const user = props.user.userSession;

  const [currentMonth, setCurrentMonth] = useState('0h 00min');

  useEffect(() => {
    (async () => {
      const calendarId = user.calendario[new Date().getFullYear()].id;
      let calendar = await getDoc(doc(db, 'calendars', calendarId));
      calendar = calendar.data().data[moment().format('MMMM')];

      let total = 0;

      if (records) {
        var sortRecords = Object.entries(records).sort();
        var long = Object.entries(records).length;

        for (var i = 0; i < long; i++) {
          var dayRecords = sortRecords[i][1];
          var longDayRecords = Object.entries(dayRecords).length - 1;
          var dailyTotal;
          if (Object.entries(dayRecords)[longDayRecords][1].totalAcumulado !== undefined || longDayRecords === 0) {
            dailyTotal = Object.entries(dayRecords)[longDayRecords][1].totalAcumulado;
          } else {
            dailyTotal = Object.entries(dayRecords)[longDayRecords - 1][1].totalAcumulado;
          }
          if (dailyTotal) {
            var dato = dailyTotal.split(':');
            total = total + dato[0] * 60 + dato[1] * 1;
          }
        }

        //Añade al teorico las horas de vacaciones solicitadas y aprobadas
        await getDocs(query(collection(db, 'users', user.uid, 'ausencias'), where('status', '==', 'Aceptado'))).then((doc) => {
          doc.forEach((document) => {
            //si hay registros de vacaciones en este mes
            //prettier-ignore
            if (moment(document.data().startDate, 'DD/MM/YYYY').format('MM/YYYY') === moment().format('MM/YYYY') || moment(document.data().endDate, 'DD/MM/YYYY').format('MM/YYYY') === moment().format('MM/YYYY')){
              let start = moment(document.data().startDate, 'DD/MM/YYYY');
              let end = moment(document.data().endDate, 'DD/MM/YYYY');

              //Detectar si hay cambio de mes en el periodo de vacaciones
              if (start.format('MM') !== end.format('MM')) {
                if (start.format('MM') === moment().format('MM')) {
                  end = moment(start).endOf('month');
                } else {
                  start = moment(end).startOf('month');
                }
              }

              //Si todavía no ha llegado el día, no las sumes
              if (moment() >= start) {
                if (start.format('YYYY/MM/DD') === end.format('YYYY/MM/DD')) {
                  total = total + parseInt(calendar.days.find((day) => day.date === start.format('YYYY-MM-DD')).hours.split(':')[0]) * 60;
                  total = total + parseInt(calendar.days.find((day) => day.date === start.format('YYYY-MM-DD')).hours.split(':')[1]);
                } else {
                  for (var m = start; m.isBefore(end + 1); m.add(1, 'days')) {
                    total = total + parseInt(calendar.days.find((day) => day.date === m.format('YYYY-MM-DD')).hours.split(':')[0]) * 60;
                    total = total + parseInt(calendar.days.find((day) => day.date === m.format('YYYY-MM-DD')).hours.split(':')[1]);
                  }
                }
              }
            }
          });
        });
      }

      setCurrentMonth(Math.floor(total / 60) + 'h ' + (total % 60).toString().padStart(2, '0') + 'min');
    })();
  }, [records]);

  return (
    <div className='card'>
      <div className='card-body'>
        <div className='row'>
          <div className='col mt-0'>
            <h5 className='card-title'>Mes en curso</h5>
          </div>
          <div className='col-auto'>
            <div className='avatar'>
              <div className='avatar-title'>
                <i className='fas fa-calendar-alt'></i>
              </div>
            </div>
          </div>
        </div>
        <h1 className='display-5 mt-2 mb-4'>{currentMonth}</h1>
        {/*<LastMonth
          user={props.user}
      />*/}
      </div>
    </div>
  );
};

import React, { useContext } from 'react';
import CreateMonthTable from '../../functions/CreateMonthTable';
import { userContext } from '../../context/UserContext';

export const CurrentMonthRecords = () => {
  const { userData } = useContext(userContext);
  const records = Object.entries(userData.userRecords);

  // Leer registros tabla
  var table = CreateMonthTable(records);

  return (
    <div className='card'>
      <div className='card-body'>
        <div className='row'>
          <div className='col mt-0'>
            <h5 className='card-title'>Registros mes actual</h5>
          </div>
        </div>
        <table className='table'>
          <thead>
            <tr>
              <th scope='col'></th>
              <th scope='col'>Entrada</th>
              <th scope='col'>Salida</th>
              <th scope='col'>T.Tramo</th>
              <th scope='col'>Tot. Día</th>
            </tr>
          </thead>
          <tbody dangerouslySetInnerHTML={{ __html: table }}>
            {/*
                registros.sort().map((colum) =>
                    <tr>        
                        <th scope="row">{colum[0]}</th>
                        <td>{colum[1].entrada1}</td>
                        <td>{colum[1].ultimaSalida}</td>
                        <td>{colum[1].total}</td>
                    </tr>
                )
            */}
          </tbody>
        </table>
        <div className='clarification'>
          <p>
            {' '}
            Los fichajes con (<strong>*</strong>) son <strong>Justificados</strong>
          </p>
        </div>
      </div>
    </div>
  );
};

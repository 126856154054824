import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import { userContext } from '../../context/UserContext';
import { getDocs, query, collection, addDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db, storage } from '../../firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useNavigate, useLocation } from 'react-router-dom';
import Cropper from 'react-perspective-cropper';
import { ShowToast } from '../../functions/ShowToast';
import DatePicker from 'react-datepicker';
import es from 'date-fns/locale/es';
import { months } from '../../constants/months';

import './styles/ImageCropper.css';

export const ImageCropper = () => {
  const { userData } = useContext(userContext);

  const [cropState, setCropState] = useState();
  const [projects, setProjects] = useState([]);
  const [imgConfirm, setImgConfirm] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const confirmButtenRef = useRef();
  const cropperRef = useRef();
  const onDragStop = useCallback((s) => setCropState(s), []);
  const onChange = useCallback((s) => setCropState(s), []);

  const navigate = useNavigate();
  const location = useLocation();

  document.querySelector('body').style.overflow = 'hidden';

  useEffect(() => {
    var arrProjects = [];
    //prettier-ignore
    getDocs(query(collection(db, 'projects')))
      .then((data) => {
        data.forEach((doc) => {
          arrProjects.push(doc.data())
        });
        arrProjects.sort((a,b) => (a.displayName > b.displayName) ? 1 : ((b.displayName > a.displayName) ? -1 : 0))
        setProjects(arrProjects);
      })
      .catch((err) => {
        console.log(err);
      })
  }, []);

  const saveReceip = async (e) => {
    e.preventDefault();

    confirmButtenRef.current.disabled = true;

    var regex = new RegExp('^[a-zA-Z0-9 ]+$');
    if (!regex.test(e.target[5].value)) {
      alert('El campo descripción solo puede contener letras y números');
      confirmButtenRef.current.disabled = false;
      return;
    }

    const document = await imgConfirm;

    const data = {
      company: e.target[0].value,
      project: e.target[1].value,
      type: e.target[2].value,
      employee: e.target[3].value,
      date: startDate,
      day: startDate.getDate(),
      hour: startDate.getHours().toString().padStart(2, '0') + ':' + startDate.getMinutes().toString().padStart(2, '0'),
      month: months[startDate.getMonth()],
      year: startDate.getFullYear(),
      description: e.target[5].value,
      //document: await imgConfirm,
      docType: 'image',
    };

    //Guarda primero el documento en bd para obtener id
    await addDoc(collection(db, 'users', userData.userSession.uid, 'recibos'), data)
      .then(async (docRef) => {
        const id = docRef.id;
        //Guarda el documento en storage
        var storageRef = ref(storage, 'Receips/' + data.employee + '/' + id);
        uploadBytes(storageRef, document).then((snapshot) => {
          getDownloadURL(snapshot.ref)
            .then(async (url) => {
              //Save data to firestore
              await updateDoc(docRef, { document: url });
              ShowToast('Ticket guardado');
              exit();
            })
            .catch(async () => {
              alert('Error al guardar el ticket');
              await deleteDoc(docRef);
              confirmButtenRef.current.disabled = false;
            });
        });
      })
      .catch((error) => {
        alert('Error al guardar el ticket');
        confirmButtenRef.current.disabled = false;
        console.log(error);
      });
  };

  const showModal = () => {
    document.getElementById('receip-modal').classList.toggle('disabled');
  };

  const hideModal = () => {
    document.getElementById('receip-modal').classList.toggle('disabled');
  };

  const exit = () => {
    document.querySelector('body').style.overflow = 'auto';
    navigate('/');
  };

  const image = location.state?.image || null;
  if (!image) {
    navigate('/');
  }

  return (
    <div className='imageCropper'>
      <Cropper ref={cropperRef} image={image} onChange={onChange} onDragStop={onDragStop} maxWidth={window.innerWidth - 20} maxHeight={window.innerHeight - 80} />
      <div className='buttons-container'>
        <button
          className='btn btn-danger'
          onClick={() => {
            exit();
          }}
        >
          Cancelar
        </button>
        {imgConfirm ? (
          <button className='btn btn-success' onClick={showModal}>
            Guardar
          </button>
        ) : (
          <button className='btn btn-success' onClick={() => setImgConfirm(cropperRef.current.done({ preview: true }))}>
            Confirmar
          </button>
        )}
      </div>
      <div className='modal disabled' id='receip-modal'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <i className='fa-regular fa-floppy-disk'></i>
              <h1 className='modal-title fs-5 mt-1' id='exampleModalLabel'>
                Guardar ticket
              </h1>
              <button type='button' className='btn-close' onClick={() => hideModal()}></button>
            </div>
            <div className='modal-body'>
              <form onSubmit={(e) => saveReceip(e)}>
                <div className='mb-2'>
                  <label htmlFor='company' className='col-form-label'>
                    Empresa:
                  </label>
                  <select className='form-select' id='company' required>
                    <option value='CETEM' selected>
                      {' '}
                      CETEM{' '}
                    </option>
                    <option value='AMUEBLA'> AMUEBLA </option>
                  </select>
                </div>
                <div className='mb-2'>
                  <label htmlFor='project' className='col-form-label'>
                    Proyecto:
                  </label>
                  <select className='form-select' id='project' required>
                    <option value='' disabled selected>
                      {' '}
                      Seleccionar{' '}
                    </option>
                    {projects.map((project) => (
                      <option key={project.codigo} value={project.codigo}>
                        {project.codigo + '   -   ' + project.nombre}
                      </option>
                    ))}
                    <option key='ESPECIAL' value='ESPECIALES'>
                      ESPEC - ESPECIALES
                    </option>
                  </select>
                </div>
                <div className='mb-2'>
                  <label htmlFor='type' className='col-form-label'>
                    Tipo:
                  </label>
                  <select className='form-select' defaultValue='' id='type' required>
                    <option value='' disabled selected>
                      Seleccionar
                    </option>
                    <option value='Comida'>Comidas</option>
                    <option value='Transporte'>Transporte</option>
                    <option value='Alojamiento'>Alojamiento</option>
                    <option value='Otros'>Otros</option>
                  </select>
                </div>
                <div className='mb-2'>
                  <label htmlFor='employee' className='col-form-label'>
                    Empleado:
                  </label>
                  <input type='text' className='form-control' id='employee' value={userData.userSession.iniciales} disabled />
                </div>

                <div className='mb-2'>
                  <label htmlFor='month' className='col-form-label'>
                    Fecha:
                  </label>
                  <DatePicker locale={es} dateFormat='dd/MM/yyyy' selected={startDate} onChange={(date) => setStartDate(date)} />
                </div>
                <div className='mb-3'>
                  <label htmlFor='description' className='col-form-label'>
                    Descripción:
                  </label>
                  <textarea className='form-control' id='description' rows='3'></textarea>
                </div>
                <div className='mt-4 mx-auto submit'>
                  <input type='submit' className='btn btn-success px-5 ' value='Confirmar' ref={confirmButtenRef} />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import { createContext, useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, collection, onSnapshot } from 'firebase/firestore';
import { auth, db, generateUserDocument } from '../firebase';
import moment from 'moment';

export const userContext = createContext();

export function UserProvider({ children }) {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    onAuthStateChanged(auth, async (currentUser) => {
      const user = await generateUserDocument(currentUser);
      if (user) {
        //Sustituir en uid en producción por currentUser.uid
        await onSnapshot(doc(db, 'users', user.uid, 'registros', moment().format('MMMM-YYYY')), (doc) => {
          if (doc.data()) {
            setUserData({ userSession: user, userRecords: doc.data() });
          } else {
            setUserData({ userSession: user, userRecords: {} });
          }
          //No quita el loader hasta que se cargue los registros
          setLoading(false);
        });

        await onSnapshot(collection(db, 'users', user.uid, 'partes'), (documents) => {
          const partes = {};
          documents.docs.map((doc) => {
            partes[doc.id] = doc.data();
          });

          let weekCheck = 2;
          if (partes[moment().subtract(weekCheck, 'weeks').format('YYYY')][moment().subtract(weekCheck, 'weeks').isoWeek()] !== 'OK') {
            //Comprueba la última semana que está relleno
            weekCheck++;
            while (partes[moment().subtract(weekCheck, 'weeks').format('YYYY')][moment().subtract(weekCheck, 'weeks').isoWeek()] !== 'OK') {
              weekCheck++;
            }
            weekCheck--;
          } else {
            weekCheck = 1;
            if (partes[moment().subtract(weekCheck, 'weeks').format('YYYY')]) {
              //prettier-ignore
              if(partes[moment().subtract(weekCheck, 'weeks').format('YYYY')][moment().subtract(weekCheck, 'weeks').isoWeek()] === 'OK'){
              weekCheck = 0;
              }
            }
          }

          setUserData((prevState) => ({ ...prevState, unjustifiedWeeks: weekCheck }));
        });
      } else {
        setLoading(false);
      }
    });
  }, []);

  return (
    <userContext.Provider
      value={{
        userData,
        loading,
      }}
    >
      {children}
    </userContext.Provider>
  );
}

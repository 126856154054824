import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, documentId } from 'firebase/firestore';
import { db } from '../../firebase';
import { DaysBetweenDates } from '../../functions/DaysBetweenDates';
import moment from 'moment';

export const CurrentWeek = (props) => {
  const user = props.user.userSession;
  var records = props.user.userRecords;

  const [currentWeek, setCurrentWeek] = useState('0h 00min');

  const getTotal = (records, wkResults) => {
    var hwktotal = 0;
    var minwktotal = 0;
    var totalPreviousRecord;
    var record;

    for (var i = 0; i < wkResults.length; i++) {
      if (records[wkResults[i]]) {
        var recordlength = Object.keys(records[wkResults[i]]).length;
        for (var j = 0; j < recordlength; j++) {
          //Comprueba que exista y almacena el último registro existente
          if (records[wkResults[i]][j].totalAcumulado) {
            totalPreviousRecord = records[wkResults[i]][j].totalAcumulado;
          }

          if (j === recordlength - 1) {
            if (records[wkResults[i]][j].totalAcumulado) {
              record = records[wkResults[i]][j].totalAcumulado.split(':');
              hwktotal = hwktotal + parseInt(record[0]);
              minwktotal = minwktotal + parseInt(record[1]);
            } else {
              if (totalPreviousRecord) {
                record = totalPreviousRecord.split(':');
                hwktotal = hwktotal + parseInt(record[0]);
                minwktotal = minwktotal + parseInt(record[1]);
              }
            }
            //restablecemos el totalPreviousRecord a indefinido
            totalPreviousRecord = undefined;
          }
        }
      }
    }

    if (minwktotal > 60) {
      hwktotal = hwktotal + Math.trunc(minwktotal / 60);
      minwktotal = minwktotal % 60;
    }

    return hwktotal + 'h ' + minwktotal + 'min';
  };

  // prettier-ignore
  useEffect(() => {
    var wkStart = moment(moment().startOf('isoweek').format('YYYY-MM-DD'));
    var wkEnd = moment(moment().endOf('isoweek').format('YYYY-MM-DD'));
    var wkResults = DaysBetweenDates(wkStart, wkEnd);

    var monthWkStart = moment(wkResults[0], 'DD-MM-YYYY').format('MM');
    var monthWkEnd = moment(wkResults[wkResults.length - 1], 'DD-MM-YYYY').format('MM');

    if (monthWkStart !== monthWkEnd) {
      const ref = query(collection(db, 'users', user.uid, 'registros'), where(documentId(), '==', moment().subtract(1, 'months').format('MMMM-YYYY')));
      getDocs(ref)
        .then((docs) => {
          docs.forEach((doc) => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            records = { ...records, ...doc.data() };
          });
        })
        .then(() => setCurrentWeek(getTotal(records, wkResults)));
    } else {
      setCurrentWeek(getTotal(records, wkResults));
    }
 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [records]);

  return (
    <div className='card'>
      <div className='card-body'>
        <div className='row'>
          <div className='col mt-0'>
            <h5 className='card-title'>Semana en curso</h5>
          </div>

          <div className='col-auto'>
            <div className='avatar'>
              <div className='avatar-title'>
                <i className='fas fa-calendar-week'></i>
              </div>
            </div>
          </div>
        </div>
        <h1 className='display-5 mt-2 mb-4'>{currentWeek}</h1>
        {/*<LastWeek user={props.user} />*/}
      </div>
    </div>
  );
};

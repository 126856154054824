import React, { useState, useEffect } from 'react';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import moment from 'moment';

export const CurrentDay = (props) => {
  const records = props.user.userRecords;
  const user = props.user.userSession;

  const [workedHours, setWorkedHours] = useState('0h 00min');
  const [hoursleft, setHoursLeft] = useState('10:00');

  useEffect(() => {
    if (records) {
      if (records[moment().format('DD-MM-YYYY')]) {
        var long = Object.keys(records[moment().format('DD-MM-YYYY')]).length;
        if (long > 0) {
          if (!records[moment().format('DD-MM-YYYY')][long - 1].totalAcumulado) {
            //console.log("usuarioActivo");
            //Obtengo el tiempo del tramo
            var record = records[moment().format('DD-MM-YYYY')][long - 1].entrada;

            //no suma horas hasta que no sean las 7
            if (parseInt(moment().format('HH')) >= 7) {
              var start = moment(record, 'HH:mm'); //Convierte los datos en legibles por moment.js
              var end = moment();
              var total = moment.duration(end - start);
              var duration = moment.utc(total._milliseconds).format('H:mm');

              //Obtengo el tiempo acumulado
              var accumulated = '0:00';
              if (long > 1) {
                accumulated = records[moment().format('DD-MM-YYYY')][long - 2].totalAcumulado;
              }

              //Si hay un error en el registro anterior no obtiene el acumulado y genera error
              if (accumulated) {
                var splitDuracion = duration.split(':');
                var splitAccumulated = accumulated.split(':');

                var hours = parseInt(splitDuracion[0]) + parseInt(splitAccumulated[0]);
                var minutes = parseInt(splitDuracion[1]) + parseInt(splitAccumulated[1]);

                if (minutes > 60) {
                  hours = hours + Math.trunc(minutes / 60);
                  minutes = minutes % 60;
                }

                setWorkedHours(hours + 'h ' + minutes + 'min');
              } else {
                setWorkedHours('Error registro');
              }
            }
          } else {
            //console.log("usuario desconectado")
            const record = records[moment().format('DD-MM-YYYY')][long - 1].totalAcumulado;
            const total = record.split(':');
            setWorkedHours(total[0] + 'h ' + total[1] + 'min');
          }
        }
      }
    } else {
      //Crea el directorio del usuario si no tiene registros
      // prettier-ignore
      setDoc(doc(db, 'users', user.uid, 'registros', moment().format('MMMM-YYYY')),{}, { merge: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [records, props.refresh]);

  useEffect(() => {
    const totalTeorico = moment('10:00', 'HH:mm');
    const totalReal = moment(workedHours, 'HH:mm');

    var restante = moment.duration(totalTeorico - totalReal);
    restante = moment.utc(restante._milliseconds).format('HH:mm');
    setHoursLeft(restante);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workedHours]);

  return (
    <div className='card'>
      <div className='card-body'>
        <div className='row'>
          <div className='col mt-0'>
            <h5 className='card-title'>Día actual</h5>
          </div>
          <div className='col-auto'>
            <div className='avatar'>
              <div className='avatar-title'>
                <i className='fas fa-calendar-day'></i>
              </div>
            </div>
          </div>
        </div>
        <h1 className='display-5 mt-2 mb-4'>{workedHours}</h1>
        <div className='mb-0'>
          Horas restantes <span className='text-dark'> {hoursleft} </span>
        </div>
      </div>
    </div>
  );
};

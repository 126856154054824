import React from 'react';
import { useNavigate } from 'react-router-dom';
import { db, auth, storage } from '../../firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { doc, setDoc } from 'firebase/firestore';
import { departments } from '../../constants/departments';
import './styles/NewEmployee.css';

export const NewEmployee = () => {
  const navigate = useNavigate();

  document.querySelector('body').style.overflow = 'hidden';

  const exit = () => {
    document.querySelector('body').style.overflow = 'auto';
    navigate('/');
  };

  const createUser = async (e) => {
    e.preventDefault();
    var employee = {};

    //Detecta si se ha subido una imagen
    var file = document.getElementById('formFile').files[0];
    if (file) {
      var storageRef = ref(storage, 'UsersImages/' + file.name);
      uploadBytes(storageRef, file).then((snapshot) => {
        getDownloadURL(snapshot.ref).then((url) => {
          employee.photoUrl = url;
        });
      });
    } else {
      employee.photoUrl =
        'https://firebasestorage.googleapis.com/v0/b/controlhorario-4c249.appspot.com/o/UsersImages%2Favatar.png?alt=media&token=8834680f-d620-4566-a47b-83d115e9f9f6';
    }

    employee.email = document.getElementById('email').value.toLowerCase();
    employee.password = document.getElementById('password').value;

    //Create user and add data to firestore
    const newUser = await createUserWithEmailAndPassword(auth, employee.email, employee.password);
    setDoc(doc(db, 'users', newUser.user.uid), {
      id: newUser.user.uid,
      displayName: document.getElementById('name').value,
      email: document.getElementById('email').value,
      administrador: false,
      departamento: document.getElementById('departament').value,
      iniciales: document.getElementById('initials').value,
      estadoSesion: 'Desconectado',
      photoURL: employee.photoUrl,
    });
  };

  return (
    <div className='modal-route'>
      <div className='newEmployee'>
        <div className='modal-header'>
          <h5 className='modal-title'>
            <i className='fa-solid fa-list-ul m'></i>Nuevo Empleado
          </h5>
          <button type='button' className='btn-close' onClick={() => exit()}></button>
        </div>
        <div className='modal-body'>
          <form onSubmit={(e) => createUser(e)}>
            <div className='row'>
              <div className='mb-3 col-md-8'>
                <label htmlFor='name' className='form-label'>
                  Nombre
                </label>
                <input type='text' className='form-control' id='name' required />
              </div>
              <div className='mb-3 col-md-4'>
                <label htmlFor='initials' className='form-label'>
                  Iniciales
                </label>
                <input type='text' className='form-control' id='initials' required />
              </div>
              <div className='mb-3 col-md-12'>
                <label htmlFor='email' className='form-label'>
                  Dirección de correo
                </label>
                <input type='email' className='form-control' id='email' required />
              </div>
              <div className='mb-3 col-md-8'>
                <label htmlFor='password' className='form-label'>
                  Contraseña
                </label>
                <input type='password' className='form-control' id='password' required />
              </div>
              <div className='mb-3 col-md-4'>
                <label htmlFor='departament' className='form-label'>
                  Departamento
                </label>
                <select className='form-select' id='departament' aria-label='Floating label select example' required>
                  {departments.map((department) => (
                    <option key={department} value={department}>
                      {department}
                    </option>
                  ))}
                </select>
              </div>
              <div className='mb-3 col-md-12'>
                <label htmlFor='formFile' className='form-label'>
                  Selecciona una imagen
                </label>
                <input className='form-control' type='file' id='formFile' />
              </div>
              <div className='mb-3 col-md-12'>
                <button type='submit' className='btn btn-default'>
                  Confirmar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

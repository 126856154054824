import { doc, setDoc } from 'firebase/firestore';
import { db } from '../firebase';
import moment from 'moment';

export const EntradaIncidencia = async (props, estado, lastRecord, time) => {
  const records = props.userRecords;
  const user = props.userSession;

  var previousEntry = records[moment(time).format('DD-MM-YYYY')][lastRecord].entrada;

  //Entry se utiliza para calcular la duración y hEntry es el valor que se introduce en la entrada
  var entry = moment(time).format('HH:mm');
  var hEntry = moment(time).format('HH:mm');

  //El limite de una salida justificada es la 1
  if (entry.split(':')[0] >= 13) {
    entry = '13:00';
  }

  // Convierte los datos en legibles para moment.js
  var start = moment(previousEntry, 'HH:mm');
  var end = moment(entry, 'HH:mm');

  var duration = moment.duration(end - start);

  //Calcular duracion entre horas / TOTAL
  var total = moment.utc(duration._milliseconds).format('H:mm');

  //TotalAnterior milisegundos
  var totalAnterior = records[moment(time).format('DD-MM-YYYY')][lastRecord].totalAcumulado;
  totalAnterior = moment(totalAnterior, 'HH:mm');

  var totalAcumulado = moment(totalAnterior).add(duration, 'milliseconds');
  totalAcumulado = moment(totalAcumulado).format('H:mm');

  //Introduce el total y total Acumulado
  //prettier-ignore
  await setDoc(doc(db, 'users', user.uid, 'registros', moment(time).format('MMMM-YYYY')),{
    [moment(time).format('DD-MM-YYYY')]: { [lastRecord]: { total: total, totalAcumulado: totalAcumulado }, [lastRecord + 1]: { entrada: hEntry, tpRegistro: estado } },
  },{ merge: true })
};

import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { userContext } from '../../context/UserContext';
import { Salida } from '../../functions/Salida';
import { SalidaJustificada } from '../../functions/SalidaJustificada';
import { SalidaViaje } from '../../functions/SalidaViaje';
import { SalidaVacaciones } from '../../functions/SalidaVacaciones';
import { CheckTrip } from '../../functions/CheckTrip';
import { CheckTripRecord } from '../../functions/CheckTripRecord';
import { Loader } from '../../components/Loader';
import simpleClock from '../../assets/animations/simpleClock2.json';
import moment from 'moment';

import './styles/Output.css';

export const Output = () => {
  const [visible, setVisible] = useState('modal');
  const [showIncidence, setShowIncidence] = useState('d-none');
  const [buttonDisabled, setButtonDisabled] = useState('disabled');
  const [observation, setObservation] = useState(undefined);

  const { userData } = useContext(userContext);

  //Comprueba si el registro del día anterior es de tipo Viaje (programado)
  var tripChecked = CheckTrip(userData, 'salida');

  //Comprueba si el registro actual es de tipo Viaje (1 día)
  var tripRecordCheck = CheckTripRecord(userData);

  const navigate = useNavigate();

  useEffect(() => {
    var currentTime = moment().format('HH');
    if (9 <= currentTime && 13 > currentTime) {
      setShowIncidence('inline-block');
    } else {
      setButtonDisabled('');
    }
  }, []);

  const onChangeHandler = (event) => {
    const { value } = event.currentTarget;
    if (value) {
      setObservation(value);
      setButtonDisabled('');
    } else {
      setButtonDisabled('disabled');
    }
  };

  return (
    <div className='modal-route'>
      <div className='output'>
        <div className={visible}>
          <div className='modal-header'>
            <h5 className='modal-title'>¿Confirmar salida?</h5>
            <button type='button' className='btn-close' onClick={() => navigate('/')}></button>
          </div>
          <div className={'modal-body ' + showIncidence}>
            <p>
              Estás marcando una salida entre las <strong>9:00</strong> y las <strong>13:00</strong>. Para realizar una salida justificada deberá introducir una observación.
            </p>
            <p>
              <strong>Observaciones </strong>
              <strong>:</strong>
            </p>
            <textarea placeholder='Introduce el motivo de la salida' rows='4' onChange={(event) => onChangeHandler(event)}></textarea>
          </div>
          <div className='modal-footer'>
            <button
              type='button'
              className={'btn btn-outline-danger ' + showIncidence}
              onClick={async () => {
                setVisible('modal d-none');
                tripRecordCheck ? await SalidaViaje(userData) : await Salida(userData);
                navigate('/');
              }}
            >
              No Justificada
            </button>

            <button type='button' className='btn btn-secondary pull-left' onClick={() => navigate('/')}>
              Cancelar
            </button>

            <button
              type='button'
              className={'btn btn-danger ' + buttonDisabled}
              onClick={async () => {
                setVisible('modal d-none');
                tripRecordCheck
                  ? await SalidaViaje(userData)
                  : buttonDisabled === ''
                  ? observation
                    ? await SalidaJustificada(userData, observation)
                    : await Salida(userData)
                  : await Salida(userData);
                navigate('/');
              }}
            >
              Aceptar
            </button>

            {/*<button
              type='button'
              className='btn btn-outline-warning'
              onClick={async () => {
                setVisible('modal d-none');
                await SalidaVacaciones(userData);
                navigate('/');
              }}
            >
              Vacaciones
            </button>*/}

            {tripChecked && (
              <button
                type='button'
                className='btn btn-outline-warning'
                onClick={async () => {
                  setVisible('modal d-none');
                  await SalidaViaje(userData);
                  navigate('/');
                }}
              >
                Vuelta Viaje
              </button>
            )}
          </div>
        </div>
      </div>
      <Loader type={simpleClock} loop={true} width='150px' />
    </div>
  );
};

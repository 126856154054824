import React from 'react';
import ReactDatePicker from 'react-datepicker';

export const TimeControlCalendar = (props) => {
  const { start, end } = props;

  const isWeekday = (date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };
  return (
    <div className='timeControl-calendar'>
      <ReactDatePicker
        calendarStartDay={1}
        highlightDates={[new Date()]}
        startDate={new Date(start)}
        endDate={new Date(end)}
        filterDate={isWeekday}
        inline
        showWeekNumbers
        disabledKeyboardNavigation
      />
    </div>
  );
};

import { doc, setDoc } from 'firebase/firestore';
import { db } from '../firebase';
import moment from 'moment';

export const TotalViaje = async (props, time) => {
  const records = props.userRecords;
  const user = props.userSession;

  var long, start, end, duracion, hourRecord;
  if (records[moment(time).format('DD-MM-YYYY')][1]) {
    //Entra si es el segundo registro de la jornada
    long = Object.keys(records[moment(time).format('DD-MM-YYYY')]).length;

    start = records[moment(time).format('DD-MM-YYYY')][long - 1].entrada;
    end = records[moment(time).format('DD-MM-YYYY')][long - 1].salida;

    // Convierte los datos en legibles para moment.js
    start = moment(start, 'HH:mm');
    end = moment(end, 'HH:mm');

    var duracionActual = moment.duration(end - start);

    //Calcular duracion entre horas = TOTAL
    duracion = moment.utc(duracionActual._milliseconds).format('H:mm');

    //TotalAnterior milisegundos
    var totalAnterior = records[moment(time).format('DD-MM-YYYY')][long - 2].totalAcumulado;
    totalAnterior = moment(totalAnterior, 'HH:mm');

    //Si hay un error en el fichaje anterior
    if (!totalAnterior) {
      alert('Existen errores de registro');
      //MANDAR MAIL
    }

    var totalActual = moment(totalAnterior).add(duracionActual, 'milliseconds');
    totalActual = moment(totalActual).format('H:mm');

    hourRecord = totalActual.split(':');

    //Si el total acumulado supera las 10 horas, solo contabiliza 10:00
    if (parseInt(hourRecord[0]) >= 10) {
      //prettier-ignore
      await setDoc(doc(db, 'users', user.uid, 'registros', moment(time).format('MMMM-YYYY')),{
        [moment(time).format('DD-MM-YYYY')]: { [long-1]: { salida: moment(time).format('HH:mm'), total: duracion, totalAcumulado: "10:00" } },
      },{ merge: true })
    } else {
      //prettier-ignore
      await setDoc(doc(db, 'users', user.uid, 'registros', moment(time).format('MMMM-YYYY')),{
        [moment(time).format('DD-MM-YYYY')]: { [long-1]: { salida: moment(time).format('HH:mm'), total: duracion, totalAcumulado: totalActual } },
      },{ merge: true })
    }
  } else {
    //Entra si es el primer registro de la jornada
    long = Object.keys(records[moment(time).format('DD-MM-YYYY')]).length;
    long = long / 2;

    start = records[moment(time).format('DD-MM-YYYY')][0].entrada;
    end = records[moment(time).format('DD-MM-YYYY')][0].salida;

    // Convierte los datos en legibles para moment.js
    start = moment(start, 'HH:mm');
    end = moment(end, 'HH:mm');

    duracion = moment.duration(end - start);
    duracion = moment.utc(duracion._milliseconds).format('H:mm');

    hourRecord = duracion.split(':');

    //Si la duracion es mayor a 10 horas, solo contabiliza 10:00
    if (parseInt(hourRecord[0]) >= 10) {
      //prettier-ignore
      await setDoc(doc(db, 'users', user.uid, 'registros', moment(time).format('MMMM-YYYY')),{
        [moment(time).format('DD-MM-YYYY')]: { 0: { salida: moment(time).format('HH:mm'), total: duracion, totalAcumulado: "10:00" } },
      },{ merge: true });
    } else {
      //prettier-ignore
      await setDoc(doc(db, 'users', user.uid, 'registros', moment(time).format('MMMM-YYYY')),{
        [moment(time).format('DD-MM-YYYY')]: { 0: { salida: moment(time).format('HH:mm'), total: duracion, totalAcumulado: duracion } },
      },{ merge: true });
    }
  }
};

import moment from 'moment';

export const CheckTripRecord = (props) => {
  const records = props.userRecords;
  var check = false;

  if (records[moment().format('DD-MM-YYYY')]) {
    //console.log('Tiene registros');
    var dailyRecords = records[moment().format('DD-MM-YYYY')];
    var longRecord = Object.keys(dailyRecords).length;
    if (records[moment().format('DD-MM-YYYY')][longRecord - 1].tpRegistro === 'Viaje 1 día') {
      check = true;
    }
  }

  return check;
};

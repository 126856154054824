import { GetServerTime } from './GetServerTime';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../firebase';
import moment from 'moment';

export const SalidaJustificada = async (props, observation) => {
  const records = props.userRecords;
  const user = props.userSession;

  //Obtenemos la fecha y hora del servidor
  var serverTime = await GetServerTime();
  const time = serverTime.formatted;

  var longRecord = 0;
  //Comprueba cuentos registros tenemos diarios
  if (records[moment(time).format('DD-MM-YYYY')]) {
    var dailyRecords = records[moment(time).format('DD-MM-YYYY')];
    longRecord = Object.keys(dailyRecords).length;
  }

  if (longRecord !== 0) {
    if (!records[moment(time).format('DD-MM-YYYY')][longRecord - 1].salida) {
      if (longRecord === 1 || !records[moment(time).format('DD-MM-YYYY')][longRecord - 2].totalAcumulado) {
        //prettier-ignore
        await setDoc(doc(db, 'users', user.uid, 'registros', moment(time).format('MMMM-YYYY' )), {
          [moment(time).format('DD-MM-YYYY')] : { [longRecord-1] : {salida : "<strong>" + moment(time).format('HH:mm')+" (S)</strong>", tpIncidencia : observation, total: "0:00", totalAcumulado:"0:00"}}
        },{ merge: true })
        //prettier-ignore
        await setDoc(doc(db, 'users', user.uid),{
          estadoSesion: "Desconectado"
        }, {merge:true})
      } else {
        var totalAcumulado = records[moment(time).format('DD-MM-YYYY')][longRecord - 2].totalAcumulado;
        //prettier-ignore
        await setDoc(doc(db, 'users', user.uid, 'registros', moment(time).format('MMMM-YYYY' )), {
          [moment(time).format('DD-MM-YYYY')] : { [longRecord-1] : {"salida" : "<strong>" + moment(time).format('HH:mm')+" (S)</strong>", "tpIncidencia" : observation, "total": "0:00", "totalAcumulado": totalAcumulado}}
        },{ merge: true })
        //prettier-ignore
        await setDoc(doc(db, 'users', user.uid),{
          estadoSesion: "Desconectado"
        }, {merge:true})
      }
    } else {
      alert('Actualmente estas fuera de la empresa');
    }
  } else {
    alert('Actualmente estas fuera de la empresa');
  }
};

import React, { useEffect, useState } from 'react';

export const InstallPWA = () => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (!window.deferredPrompt) {
      //console.log('oops, no prompt event guardado en window');
      setShow(false);
    }
  }, [show]);

  const downloadApp = async () => {
    const promptEvent = window.deferredPrompt;
    // Show the install prompt.
    promptEvent.prompt();

    // Log the result
    //const result = await promptEvent.userChoice;
    //console.log('👍', 'userChoice', result);

    // Reset the deferred prompt variable, since
    // prompt() can only be called once.
    window.deferredPrompt = null;
    setShow(false);
  };

  if (!show) {
    return null;
  }

  return (
    <button className='btn btn-custom install link-button' id='setup_button' aria-label='Install app' title='Install app' onClick={downloadApp}>
      <i className='fas fa-mobile-alt'></i>Instalar APP
    </button>
  );
};

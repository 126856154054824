import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDocs, query, collection, where } from 'firebase/firestore';
import { db } from '../../firebase';

import './styles/HistoricalIncidence.css';

export const HistoricalIncidence = () => {
  const [usuarios, setUsuarios] = useState([]);
  const [incidences, setIncidences] = useState([]);
  const [incidence, setIncidence] = useState({});
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedType, setSelectedType] = useState(null);

  const navigate = useNavigate();
  document.querySelector('body').style.overflow = 'hidden';

  //Obtener usuarios
  useEffect(() => {
    var arrUser = [];

    //prettier-ignore
    getDocs(query(collection(db, 'users')))
      .then((data) => {
        data.forEach((doc) => {
          arrUser.push(doc.data())
        });
        arrUser.sort((a,b) => (a.displayName > b.displayName) ? 1 : ((b.displayName > a.displayName) ? -1 : 0))
        setUsuarios(arrUser);
      })
      .catch((err) => {
        console.log(err);
      })
  }, []);

  //Obtener incidencias
  useEffect(() => {
    var arrIncidences = [];
    var q;

    if (selectedUser && selectedType) {
      q = query(collection(db, 'notifications'), where('uid', '==', selectedUser), where('type', '==', selectedType));
    } else {
      if (selectedUser) {
        q = query(collection(db, 'notifications'), where('uid', '==', selectedUser));
      } else {
        if (selectedType) {
          q = query(collection(db, 'notification'), where('uid', '==', selectedType));
        }
      }
    }

    if (q) {
      getDocs(q).then((data) => {
        data.forEach((doc) => {
          arrIncidences.push(doc.data());
        });
        setIncidences(arrIncidences);
      });
    }
  }, [selectedUser, selectedType]);

  //LLama a la funcion para obtener las incidencias
  const handleInputChange = async (e) => {
    const { name, value } = e.target;

    if (name === 'user') {
      setSelectedUser(value);
    } else if (name === 'type') {
      setSelectedType(value);
    }
  };

  const getIncidence = (incidence) => {
    //Anterior selectedUser
    //Si hay incidencia y el selectedUser es diferente
    //console.log(Object.keys(incidence.recordsProposed)[0]);
    //console.log(Object.keys(records));
    console.log(incidence);
    setIncidence(incidence);
  };

  const exit = () => {
    document.querySelector('body').style.overflow = 'auto';
    navigate('/');
  };

  return (
    <div className='modal-route'>
      <div className='historicalIncidence'>
        <div className='modal-header'>
          <h5 className='modal-title'>
            <i className='fa fa-check'></i>Historico solicitudes de corrección
          </h5>
          <button type='button' className='btn-close' onClick={() => exit()}></button>
        </div>
        <div className='modal-body'>
          <div className='row'>
            <div className='col-md-3'>
              <br />
              <p>Historico de incidencias por usuario:</p>
              <select name='user' className='form-select' aria-label='Default select example' defaultValue={'default'} onChange={handleInputChange}>
                <option value='default' disabled>
                  Seleccionar
                </option>
                {usuarios.map((usuarios) => (
                  <option value={usuarios.id}>{usuarios.displayName}</option>
                ))}
              </select>
              <br />
              <p>Historico de incidencias por tipo:</p>
              <select name='type' className='form-select' defaultValue={'default'} aria-label='Default select example' onChange={handleInputChange}>
                <option value='default' disabled>
                  Seleccionar
                </option>
                <option value='Error_fichaje'>Error fichaje</option>
                <option value='Error_app'>Error APP</option>
              </select>
              <br />
            </div>
            <div className='col-md-4'>
              <p>
                Incidencias: <strong>{incidences.length}</strong>
              </p>
              <hr />
              {incidences.map((incidence) => (
                <>
                  <div className='card'>
                    <div className='card-body' onClick={() => getIncidence(incidence)}>
                      <h6 className='card-title float-start'>{'- ' + incidence.email}</h6>
                      <p className='float-end'>{Object.keys(incidence.recordsProposed)[0]}</p>
                      <br />
                      <hr />
                      <p className='card-text'>{incidence.observation.substring(0, 60) + '...'}</p>
                    </div>
                  </div>
                  <br />
                </>
              ))}
            </div>
            <div className='col-md-5'>
              <p>Registros</p>
              <hr />
              {incidence.id === 'p6yrLpdPrbAvQElH1qve' && (
                <>
                  <div className='row'>
                    <div className='col-md-3'>
                      <div className='form-group'>
                        <label htmlFor='name'>Entrada</label>
                        <input type='time' value='08:05' className='form-control' />
                      </div>
                    </div>
                    <div className='col-md-3'>
                      <div className='form-group'>
                        <label htmlFor='name'>Salida</label>
                        <input type='time' value='14:15' className='form-control' />
                      </div>
                    </div>
                    <div className='col-md-3'>
                      <div className='form-group'>
                        <label htmlFor='name'>T.Tramo</label>
                        <input type='time' value='06:10' className='form-control' />
                      </div>
                    </div>
                    <div className='col-md-3'>
                      <div className='form-group'>
                        <label htmlFor='name'>Tot. Día</label>
                        <input type='time' value='06:10' className='form-control' />
                      </div>
                    </div>
                  </div>
                  <div className='row mt-4 mb-4'>
                    <div className='col-md-3'>
                      <div className='form-group'>
                        <label htmlFor='name'>Entrada</label>
                        <input type='time' value='15:10' className='form-control' />
                      </div>
                    </div>
                    <div className='col-md-3'>
                      <div className='form-group'>
                        <label htmlFor='name'>Salida</label>
                        <input type='time' className='form-control' />
                      </div>
                    </div>
                    <div className='col-md-3'>
                      <div className='form-group'>
                        <label htmlFor='name'>T.Tramo</label>
                        <input type='time' className='form-control' />
                      </div>
                    </div>
                    <div className='col-md-3'>
                      <div className='form-group'>
                        <label htmlFor='name'>Tot. Día</label>
                        <input type='time' className='form-control' />
                      </div>
                    </div>
                  </div>
                </>
              )}
              <br />
              <p>Registros Propuestos</p>
              <hr />
              {incidence.id === 'p6yrLpdPrbAvQElH1qve' && (
                <>
                  <div className='row'>
                    <div className='col-md-3'>
                      <div className='form-group'>
                        <label htmlFor='name'>Entrada</label>
                        <input type='time' value='08:05' className='form-control' />
                      </div>
                    </div>
                    <div className='col-md-3'>
                      <div className='form-group'>
                        <label htmlFor='name'>Salida</label>
                        <input type='time' value='14:15' className='form-control' />
                      </div>
                    </div>
                    <div className='col-md-3'>
                      <div className='form-group'>
                        <label htmlFor='name'>T.Tramo</label>
                        <input type='time' value='06:10' className='form-control' />
                      </div>
                    </div>
                    <div className='col-md-3'>
                      <div className='form-group'>
                        <label htmlFor='name'>Tot. Día</label>
                        <input type='time' value='06:10' className='form-control' />
                      </div>
                    </div>
                  </div>
                  <div className='row mt-4 mb-4'>
                    <div className='col-md-3'>
                      <div className='form-group'>
                        <label htmlFor='name'>Entrada</label>
                        <input type='time' value='15:10' className='form-control' />
                      </div>
                    </div>
                    <div className='col-md-3'>
                      <div className='form-group'>
                        <label htmlFor='name'>Salida</label>
                        <input type='time' value='16:10' className='form-control' />
                      </div>
                    </div>
                    <div className='col-md-3'>
                      <div className='form-group'>
                        <label htmlFor='name'>T.Tramo</label>
                        <input type='time' value='07:10' className='form-control' />
                      </div>
                    </div>
                    <div className='col-md-3'>
                      <div className='form-group'>
                        <label htmlFor='name'>Tot. Día</label>
                        <input type='time' value='07:10' className='form-control' />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CreateMonthTable = (records, showType) => {
  var table = ' ';

  if (records) {
    records.sort().map((colum) => {
      var long = Object.keys(colum[1]).length;
      if (long > 1) {
        for (var i = 0; i < long; i++) {
          table = table + `<tr>`;
          if (i === 0) {
            table = table + `<th scope="row">${colum[0]}</th>`;
          } else {
            table = table + `<th scope="row"></th>`;
          }
          table = table + `<td>${colum[1][i].entrada}</td>`;

          if (colum[1][i].salida !== undefined) {
            if (colum[1][i].tpIncidencia !== undefined)
              table =
                table +
                `
                  <td>
                    <div className="tooltip">${colum[1][i].salida}
                      <span className="tooltiptext"><strong>Incidencia: <hr></strong> ${colum[1][i].tpIncidencia}</span>
                    </div>
                  </td>`;
            else {
              table = table + `<td>${colum[1][i].salida}</td>`;
            }
          }
          if (colum[1][i].total !== undefined) {
            table = table + `<td>${colum[1][i].total}</td>`;
          }
          if (colum[1][i].totalAcumulado !== undefined) {
            if (i === long - 1) {
              table = table + `<td><strong>${colum[1][i].totalAcumulado}</strong></td>`;
            } else {
              table = table + `<td>${colum[1][i].totalAcumulado}</td>`;
            }
          }
          if (colum[1][i].tpRegistro && showType) {
            table = table + `<td class="${colum[1][i].tpRegistro}">${colum[1][i].tpRegistro}</td>`;
          }

          table = table + `<tr>`;
        }
      } else {
        table = table + `<tr>`;
        table = table + `<th scope="row">${colum[0]}</th>`;
        table = table + `<td>${colum[1][0].entrada}</td>`;
        if (colum[1][0].salida) {
          if (colum[1][0].tpIncidencia !== undefined) {
            table =
              table +
              `
                <td>
                  <div className="tooltip">${colum[1][0].salida}
                  <span className="tooltiptext"><strong>Incidencia: <hr></strong> ${colum[1][0].tpIncidencia}</span>
                  </div>
                </td>`;
          } else {
            table = table + `<td>${colum[1][0].salida}</td>`;
          }
        }
        if (colum[1][0].total) {
          table = table + `<td>${colum[1][0].total}</td>`;
        }
        if (colum[1][0].totalAcumulado) {
          table = table + `<td><strong>${colum[1][0].totalAcumulado}</strong></td>`;
        }
        if (colum[1][0].tpRegistro && showType) {
          table = table + `<td class="${colum[1][0].tpRegistro}">${colum[1][0].tpRegistro}</td>`;
        }
        table = table + `<tr>`;
      }
    });
  }

  return table;
};

export default CreateMonthTable;

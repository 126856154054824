import { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { userContext } from '../context/UserContext';
import { Loader } from './Loader';
import clockRegistry from './../assets/animations/clockRegistry.json';

export function ProtectedRoute({ children }) {
  const { loading } = useContext(userContext);

  const auth = getAuth();

  if (loading) {
    return <Loader type={clockRegistry} loop={true} width={'250px'} />;
  }

  if (!auth.currentUser) {
    return <Navigate to='/login' />;
  }

  return children ? children : <Outlet />;
}

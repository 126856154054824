import { GetServerTime } from './GetServerTime';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../firebase';
import moment from 'moment';

export const EntradaJustificada = async (props, observation, estado) => {
  const records = props.userRecords;
  const user = props.userSession;

  //Obtenemos la fecha y hora del servidor
  var serverTime = await GetServerTime();
  const time = serverTime.formatted;

  //Comprueba cuantos registros tenemos diarios
  var longRecord = 0;
  if (records[moment(time).format('DD-MM-YYYY')]) {
    var dailyRecords = records[moment(time).format('DD-MM-YYYY')];
    longRecord = Object.keys(dailyRecords).length;
  }

  if (longRecord === 0 || records[moment(time).format('DD-MM-YYYY')][longRecord - 1].salida) {
    var totalAcumulado = '0:00';

    var entry = moment(time).format('HH:mm');

    var start = moment('09:00', 'HH:mm');
    var end = moment(entry, 'HH:mm');

    var duracion = moment.duration(end - start);
    var totalActual = moment.utc(duracion._milliseconds).format('H:mm');

    var total;
    //Detecta si hay registros anteriores y obtiene el totalAcumulado
    if (records[moment(time).format('DD-MM-YYYY')]) {
      //console.log('Ya hay registros');
      totalAcumulado = records[moment(time).format('DD-MM-YYYY')][longRecord - 1].totalAcumulado;
      totalAcumulado = moment(totalAcumulado, 'H:mm');

      total = moment(totalAcumulado).add(duracion, 'milliseconds');
      total = moment(total).format('H:mm');

      // prettier-ignore
      await setDoc(doc(db, 'users', user.uid, 'registros', moment(time).format('MMMM-YYYY')),{
      [moment(time).format('DD-MM-YYYY')]: { [longRecord]: { 'entrada': '09:00', 'tpRegistro': estado, 'tpIncidencia': observation, 'salida': "<strong>" + entry + " (E)</strong>", 'total': totalActual, 'totalAcumulado':total }, [longRecord+1]: {'entrada': entry, 'tpRegistro':estado} },
      },{ merge: true });
    } else {
      //console.log('No hay registros');
      totalAcumulado = moment(totalAcumulado, 'H:mm');

      total = moment(totalAcumulado).add(duracion, 'milliseconds');
      total = moment(total).format('H:mm');

      // prettier-ignore
      await setDoc(doc(db, 'users', user.uid, 'registros', moment(time).format('MMMM-YYYY')),{
      [moment(time).format('DD-MM-YYYY')]: { [longRecord]: { 'entrada': '09:00', 'tpRegistro': estado, 'tpIncidencia': observation, 'salida': "<strong>" + entry + " (E)</strong>", 'total': total, 'totalAcumulado':total }, [longRecord+1]: {'entrada': entry, 'tpRegistro':estado} },
      },{ merge: true });
    }

    // cambia el estado del usuario
    // prettier-ignore
    await setDoc(doc(db, 'users', user.uid),{
      estadoSesion: estado
    }, {merge:true})
  } else {
    alert('No has registrado una salida');
  }
};

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

export const NavbarMobile = () => {
  const [navbarStyle, setNavbarStyle] = useState('android');

  useEffect(() => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      if (window.matchMedia('(display-mode: standalone)').matches) {
        setNavbarStyle('ios');
      }
    }
  }, []);

  return (
    <div className='navbarmobile'>
      <div className={navbarStyle}>
        <Link to='/input'>
          <button type='button' className='btn btn-success'>
            <i className='fas fa-sign-in-alt'></i>Entrada
          </button>
        </Link>
        <Link to='/output'>
          <button type='button' className='btn btn-danger'>
            <i className='fas fa-sign-out-alt'></i>Salida
          </button>
        </Link>
      </div>
    </div>
  );
};

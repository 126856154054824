import emailjs from 'emailjs-com';

export const MailConfirmNotification = (record, observation) => {
  const data = {
    type: 'ACEPTADA',
    date: record.date,
    email: record.email,
    content: record.content || 'Se ha aceptado la solicitud de modificación para el día: ' + record.date,
    observation: observation,
  };

  emailjs.send('service_e7f41hj', 'template_mfcu21i', data, 'user_6u2cffy79MwdDbyMQzgkI');
};

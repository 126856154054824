// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { doc, getDoc, getFirestore, setDoc } from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyCwYCJs6YUQ9lfUwp7pnoneRUVNddTn__I',
  authDomain: 'controlhorario-4c249.firebaseapp.com',
  databaseURL: 'https://controlhorario-4c249.firebaseio.com',
  projectId: 'controlhorario-4c249',
  storageBucket: 'controlhorario-4c249.appspot.com',
  messagingSenderId: '1080646631077',
  appId: '1:1080646631077:web:8f2d84398e36e48fa1d74c',
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore();
export const storage = getStorage(app);

const getUserDocument = async (uid) => {
  if (!uid) return null;
  try {
    const docRef = doc(db, 'users', uid);
    const userDocument = await getDoc(docRef);
    return {
      uid,
      ...userDocument.data(),
    };
  } catch (error) {
    console.error('Error fetching user', error);
  }
};

export const generateUserDocument = async (user, additionalData) => {
  if (!user) return null;
  const userRef = doc(db, 'users', user.uid);
  const userDocument = await getDoc(userRef);
  if (!userDocument.exists()) {
    const { email, displayName, photoURL } = user;
    try {
      await setDoc(userRef, {
        displayName,
        email,
        photoURL,
        ...additionalData,
      });
    } catch (error) {
      console.error('Error creating user document', error);
    }
  }
  return getUserDocument(user.uid);
};

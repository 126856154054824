import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

export const GeneratePDF = (props) => {
  const user = props.userSession;
  const records = props.userRecords;

  var img = new Image();
  img.src = 'static/media/logo.png';

  var doc = new jsPDF();

  doc.setFont('Helvetica', 'normal', 'bold');
  doc.text(20, 25, 'Control Horario CETEM');

  doc.setFont('Helvetica', 'normal', '400');
  doc.text('Usuario: ' + user.displayName, 20, 35);

  doc.addImage(img, 'PNG', 120, 15);

  var columns = ['Fecha', 'Entrada', 'Salida', 'Total', 'TotalAcumulado', 'Observaciones'];

  var data = [];

  //Comprueba si tiene registros
  if (records !== undefined) {
    var datos = Object.entries(records).sort();
    //console.log(datos);
    var long1 = Object.entries(records).length;
    for (var i = 0; i < long1; i++) {
      //console.log(datos[i])
      //console.log(datos[i][0])
      //console.log(datos[i][1])
      var long2 = Object.keys(datos[i][1]).length;
      //console.log(Object.keys(datos[i][1]).length)
      for (var j = 0; j < long2; j++) {
        //console.log(datos[i][1][j])
        const unordered = datos[i][1][j];
        const ordered = {};
        Object.keys(unordered)
          .sort()
          .forEach(function (key) {
            ordered[key] = unordered[key];
          });
        //console.log(ordered)
        //console.log(Object.values(ordered))

        var arrReg = [];
        arrReg[0] = datos[i][0];

        if (Object.values(ordered)[0].indexOf('<i') !== -1) {
          //arrReg[2] = Object.values(ordered)[1];
          arrReg[1] = 'Viaje';
        } else {
          arrReg[1] = Object.values(ordered)[0];
        }

        if (Object.values(ordered)[1].indexOf('<i') !== -1) {
          //arrReg[2] = Object.values(ordered)[1];
          arrReg[2] = 'Viaje';
        } else if (Object.values(ordered)[1].indexOf('<strong') !== -1) {
          arrReg[2] = Object.values(ordered)[1].slice(8, 13) + ' (*)';
        } else {
          arrReg[2] = Object.values(ordered)[1];
        }

        //arrReg[2] = Object.values(ordered)[1];
        arrReg[3] = Object.values(ordered)[2];
        arrReg[4] = Object.values(ordered)[3];
        arrReg[5] = Object.values(ordered)[4];

        data.push(arrReg);
      }
    }

    //console.log(data)

    /*
    var dataPrueba = [
        ["01-06-2020", "08:00", "2:30", "6h 30min", "6h 30min" ],
        ["02-06-2020", "08:11", "2:55", "6h 44min", "6h 30min"],
        ["03-06-2020", "08:04", "2:40", "6h 66min", "6h 30min"],
        ["04-06-2020", "08:00", "2:30", "6h 30min", "6h 30min" ],
        ["05-06-2020", "08:11", "2:55", "6h 44min", "6h 30min"],
        ["08-06-2020", "08:04", "2:40", "6h 66min", "6h 30min"],
        ["09-06-2020", "08:00", "2:30", "6h 30min", "6h 30min" ],
        ["10-06-2020", "08:11", "2:55", "6h 44min", "6h 30min"],
        ["11-06-2020", "08:04", "2:40", "6h 66min", "6h 30min"],
        ["12-06-2020", "08:00", "2:30", "6h 30min", "6h 30min" ],
        ["15-06-2020", "08:11", "2:55", "6h 44min", "6h 30min"],
        ["16-06-2020", "08:04", "2:40", "6h 66min", "6h 30min"],
        ["17-06-2020", "08:00", "2:30", "6h 30min", "6h 30min" ],
        ["18-06-2020", "08:11", "2:55", "6h 44min", "6h 30min"],
        ["19-06-2020", "08:04", "2:40", "6h 66min", "6h 30min"],
        ["22-06-2020", "08:00", "2:30", "6h 30min", "6h 30min" ],
        ["23-06-2020", "08:11", "2:55", "6h 44min", "6h 30min"],
        ["24-06-2020", "08:04", "2:40", "6h 66min", "6h 30min"],
        ["25-06-2020", "08:00", "2:30", "6h 30min", "6h 30min" ],
        ["26-06-2020", "08:11", "2:55", "6h 44min", "6h 30min"],
        ["29-06-2020", "08:04", "2:40", "6h 66min", "6h 30min"],
        ["30-06-2020", "08:04", "2:40", "6h 66min", "6h 30min"]
    ];
    */

    doc.autoTable(columns, data, {
      margin: { top: 50 },
      headStyles: {
        fillColor: [226, 149, 0],
        fontSize: 15,
      },
    });

    doc.save(user.iniciales + '.pdf');
  } else {
    doc.autoTable(columns, data, {
      margin: { top: 50 },
      headStyles: {
        fillColor: [226, 149, 0],
        fontSize: 15,
      },
    });

    doc.save(user.iniciales + '.pdf');
  }
};

export default GeneratePDF;

import { useState, useEffect } from 'react';
import { doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { MailConfirmNotification } from '../../functions/MailConfirmNotification';
import { MailDeleteNotification } from '../../functions/MailDeleteNotification';
import { ShowToast } from '../../functions/ShowToast';
import moment from 'moment';

export const ShowAbsences = (props) => {
  const [data, setData] = useState(null);
  const [observations, setObservations] = useState('');

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  const confirmNotification = async (data) => {
    await updateDoc(doc(db, 'users', data.uid, 'ausencias', data.nid), { status: 'Aceptado' });

    await deleteDoc(doc(db, 'notifications', data.id));
    //await updateDoc(doc(db, 'notifications', record.id), { processed: true });

    setData(null);
    setObservations('');
    ShowToast('Notificación enviada');
    MailConfirmNotification(
      {
        date: data.startDate,
        email: data.email,
        content: observations || 'Se ha aceptado tu solicitud de ' + data.type.replace(/_/g, ' ') + ' para el día ' + data.startDate + '.',
      },
      'Solicitud aceptada'
    );
  };

  const deleteNotication = async (data) => {
    await updateDoc(doc(db, 'users', data.uid, 'ausencias', data.nid), { status: 'Rechazado' });

    await deleteDoc(doc(db, 'notifications', data.id));
    //await updateDoc(doc(db, 'notifications', record.id), { processed: true });

    setData(null);
    setObservations('');
    ShowToast('Notificación enviada');
    MailDeleteNotification(
      {
        date: data.startDate,
        email: data.email,
        content: observations || 'Se ha rechazado tu solicitud de ' + data.type.replace(/_/g, ' ') + ' para el día ' + data.startDate + '.',
      },
      'Solicitud rechazada'
    );
  };

  if (!data) return null;

  return (
    <div className='content'>
      <h6>
        Solicitud de {data.type.replace(/_/g, ' ')}: <strong>{data.name}</strong>
      </h6>
      <hr />
      <div className='absence-item mt-4 mb-3'>
        <div className='card'>
          <div className='card-header d-flex align-items-center justify-content-between pe-5'>
            <h5 className='card-title mt-1'>{data.type.replace(/_/g, ' ')}</h5>
            <span>
              Año: <strong className='ms-2'>{data.year}</strong>
            </span>
          </div>
          <div className='card-body'>
            <div className='row mt-4'>
              <div className='col-md-3'>
                <div className='icon-calendar'>
                  <div id='weekday'>{moment(data.startDate, 'DD/MM/YYYY').format('MMM')}</div>
                  <div id='day'>{data.startDate.split('/')[0]}</div>
                </div>
                {data.startDate !== data.endDate && (
                  <>
                    <i className='fa-solid fa-arrow-right'></i>
                    <div className='icon-calendar'>
                      <div id='weekday'>{moment(data.endDate, 'DD/MM/YYYY').format('MMM')}</div>
                      <div id='day'>{data.endDate.split('/')[0]}</div>
                    </div>
                  </>
                )}
              </div>
              <div className='col-md-5'>
                <p className='card-text'>{`Del ${data.startDate} al ${data.endDate}`}</p>
                <p>
                  <strong className='me-2'>Observaciones:</strong>
                  {data.description}
                </p>
              </div>
              <div className='col-md-2 mt-4 text-center'>
                <p className='card-text '>
                  {data.type === 'Vacaciones'
                    ? Math.floor(data.total / 60)
                        .toString()
                        .padStart(2, '0') +
                      'h ' +
                      (data.total % 60).toString().padStart(2, '0') +
                      'min'
                    : moment(data.endDate, 'DD/MM/YYYY').diff(moment(data.startDate, 'DD/MM/YYYY'), 'days') + 1 + ' días'}
                </p>
              </div>
              <div className='col-md-2 mt-4'>
                <div className={`badge rounded-pill ${data.status}`}>
                  <i className={`fa-solid fa-clock-rotate-left ${data.status}`}></i>
                  <span className='text-white'>{data.status}</span>
                </div>
              </div>
            </div>
          </div>
          <div className='card-footer mt-2'>
            <div className='mb-3'>
              <label for='observations' className='form-label'>
                Observaciones
              </label>
              <textarea className='form-control' id='observations' rows='3' onChange={(e) => setObservations(e.target.value)}></textarea>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-footer pe-0'>
        <p>¿Desea aceptar la solicitud?</p>
        <button className='btn btn-success' onClick={() => confirmNotification(data)}>
          {' '}
          Confirmar
        </button>
        <button className='btn btn-danger' onClick={() => deleteNotication(data)}>
          {' '}
          Rechazar
        </button>
      </div>
    </div>
  );
};

import emailjs from 'emailjs-com';

function MailError(props) {
  const data = {
    name: props.userSession.displayName,
    initials: props.userSession.iniciales,
    email: props.userSession.email,
  };

  emailjs.send('service_e7f41hj', 'template_1lx4ueq', data, 'user_6u2cffy79MwdDbyMQzgkI');
}

export default MailError;

import { doc, collection, query, getDoc, getDocs, where } from 'firebase/firestore';
import { db } from './../firebase';
import moment from 'moment';
import * as XLSX from 'xlsx';

export const ExportMonthXLS = async () => {
  let data = [];
  const users = await getDocs(query(collection(db, 'users'), where('estadoSesion', '!=', 'Deshabilitado')));
  for (const user of users.docs) {
    if (user.data().calendario?.[moment().subtract(1, 'months').format('YYYY')]) {
      //Obtengo el calendario del usuario

      const calendario = await getDoc(doc(db, 'calendars', user.data().calendario[moment().subtract(1, 'months').format('YYYY')].id));

      //Obtengo el total de horas teóricas del mes anterior
      const teorico = calendario.data().data[moment().subtract(1, 'month').format('MMMM')].total || 0;

      //Obtengo el total de horas reales del mes anterior
      let real = await getDoc(doc(db, 'users', user.id, 'registros', moment().subtract(1, 'months').format('MMMM-YYYY'))).then(async (document) => {
        let total = 0;
        if (!document.exists()) {
          return 0;
        }

        Object.values(document.data()).forEach((item) => {
          var long = Object.keys(item).length;
          let totalDay = 0;
          while (totalDay <= 0 && long > 0) {
            if (item[long - 1].totalAcumulado) {
              totalDay = item[long - 1].totalAcumulado.split(':')[0] * 60 + parseInt(item[long - 1].totalAcumulado.split(':')[1]);
            }
            long--;
          }
          total = total + totalDay;
        });
        return total;
      });

      const real_horas = Math.floor(real / 60) + ':' + (real % 60).toString().padStart(2, '0');

      //Obtengo las ausencias del mes anterior
      const absences = await getDocs(query(collection(db, 'users', user.id, 'ausencias'), where('status', '==', 'Aceptado'))).then((doc) => {
        let absences = {};
        doc.forEach((document) => {
          //si hay registros de vacaciones en este mes
          //prettier-ignore
          if (moment(document.data().startDate, 'DD/MM/YYYY').format('MM/YYYY') === moment().subtract(1, 'months').format('MM/YYYY') || moment(document.data().endDate, 'DD/MM/YYYY').format('MM/YYYY') === moment().subtract(1, 'months').format('MM/YYYY')) {
            //SE PUEDE SACAR A UNA FUNCIÓN(MONTHLYBALANCE, MONTHLYREMAINING ..)
            let start = moment(document.data().startDate, 'DD/MM/YYYY');
            let end = moment(document.data().endDate, 'DD/MM/YYYY');

            //Detectar si hay cambio de mes en el periodo de vacaciones
            if (start.format('MM') !== end.format('MM')) {
              if (start.format('MM') === moment().subtract(1, 'months').format('MM')) {
                end = moment().subtract(1, 'months').endOf('month');
              } else {
                start = moment().subtract(1, 'months').startOf('month');
              }
            }

            for (var m = start; m.isBefore(end + 1); m.add(1, 'days')) {
              let horas = calendario.data().data[moment().subtract(1, 'month').format('MMMM')].days.find((day) => day.date === m.format('YYYY-MM-DD')).hours;
              horas = horas.split(':')[0] * 60 + parseInt(horas.split(':')[1]);
              absences[document.data().type] = absences[document.data().type] ? absences[document.data().type] + horas : horas;
              //Al real le sumo las ausencias
              real = real + horas;
            }

            //absences[document.data().type] = absences[document.data().type] ? absences[document.data().type] + document.data().total : document.data().total;
          }
        });
        return absences;
      });

      data.push([
        user.data().displayName,
        user.data().departamento,
        new Date(0, 0, 0, teorico.split(':')[0], teorico.split(':')[1]),
        new Date(0, 0, 0, real_horas.split(':')[0], real_horas.split(':')[1]),
        absences['Vacaciones'] ? new Date(0, 0, 0, Math.floor(absences['Vacaciones'] / 60), absences['Vacaciones'] % 60) : '0',
        absences['Asuntos_propios'] ? new Date(0, 0, 0, Math.floor(absences['Asuntos_propios'] / 60), absences['Asuntos_propios'] % 60) : '0',
        absences['Enfermedad'] ? new Date(0, 0, 0, Math.floor(absences['Enfermedad'] / 60), absences['Enfermedad'] % 60) : '0',
        new Date(0, 0, 0, Math.floor(real / 60), real % 60),
      ]);
    } else {
      alert(
        'El usuario ' +
          user.data().displayName +
          ' no tiene calendario asignado para el año ' +
          moment().subtract(1, 'months').format('YYYY') +
          ' y no se ha tenido en cuenta en la generación del informe.'
      );
    }
  }

  //Ordenar por departamento
  data.sort((a, b) => {
    if (a[1] < b[1]) return -1;
    if (a[1] > b[1]) return 1;
  });

  // Crear un nuevo libro de trabajo
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet([
    ['Empleado', 'Departamento', 'Horas Teóricas', 'Horas Trabajadas', 'Vacaciones', 'Asuntos Propios', 'Enfermedad', 'Total'],
    ...data.map((item) => [item[0], item[1], item[2], item[3], item[4], item[5], item[6], item[7]]),
  ]);

  // Establecer el formato de celda para la columna de tiempo
  const colWidth = [{ wch: 20 }];
  ws['!cols'] = colWidth;

  // Establecer el formato de celda para la columna de tiempo
  const timeFormat = '[h]:mm;@';

  // Establecer el formato de celda para las columnas 2 a 6
  for (let r = 1; r <= data.length; r++) {
    for (let c = 2; c <= 7; c++) {
      const cell = XLSX.utils.encode_cell({ r, c });
      if (ws[cell] && ws[cell].t === 'n') {
        ws[cell].z = timeFormat;
      }
    }
  }

  // Agregar la hoja al libro
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  // Guardar el libro como un archivo Excel
  XLSX.writeFile(wb, 'Mensual.xlsx');
  console.log('Archivo de Excel creado exitosamente.');
};

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

export const ShowToast = (message, type) => {
  toast(
    <>
      <i className='far fa-check-circle'></i>
      <span>{message}</span>
    </>,
    {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    }
  );
};

import React from 'react';
import { useNavigate } from 'react-router-dom';

import './styles/Alert.css';

export const Alert = () => {
  const navigate = useNavigate();
  document.querySelector('body').style.overflow = 'hidden';

  const exit = () => {
    document.querySelector('body').style.overflow = 'auto';
    navigate('/');
  };

  return (
    <div className='modal-route'>
      <div className='Alert'>
        <div className='modal-body'>
          <img src='static/media/warning.png' alt='alert' />
          <div className='row'>
            <p>Recuerda que tienes pendiente rellenar tu control horario de la semana pasada</p>
          </div>
          <button type='button' className='btn btn-success mt-2 mx-2' onClick={() => navigate('/time-control')}>
            Rellenar CH
          </button>
          <button type='button' className='btn btn-danger mt-2 mx-2' onClick={() => exit()}>
            Más tarde
          </button>
        </div>
      </div>
    </div>
  );
};

import { collection, doc, getDocs, deleteDoc, setDoc, query } from 'firebase/firestore';
import { db } from './../firebase';
import { ShowToast } from './ShowToast';

export const UpdateProjects = async () => {
  try {
    const responst = await fetch(`${process.env.REACT_APP_SERVER}/projects?apiKey=${process.env.REACT_APP_AQUA_APIKEY}`);
    const data = await responst.json();

    const documents = await getDocs(query(collection(db, 'projects')));

    //Elimina los documentos de la colección
    if (documents.docs.length > 0) {
      documents.forEach((document) => {
        deleteDoc(document.ref);
      });
    }

    //Inserta los documentos de la colección
    data.forEach((project) => {
      setDoc(doc(db, 'projects', project.codigo), project);
    });

    ShowToast('Proyectos actualizados');
  } catch (error) {
    console.log(error);
  }
};

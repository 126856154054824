import React, { useState, useContext } from 'react';
import { userContext } from '../../context/UserContext';
import { useNavigate } from 'react-router-dom';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { DaysBetweenDates } from '../../functions/DaysBetweenDates';
import moment from 'moment';
import DatePicker, { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import 'react-datepicker/dist/react-datepicker.css';
import './styles/Travel.css';

export const Travel = () => {
  const { userData } = useContext(userContext);

  const startDate = new Date();
  const [endDate, setEndDate] = useState(null);
  const [textDays, setTextDays] = useState('');
  const [arrDays, setArrDays] = useState([]);

  var user = userData.userSession;

  const navigate = useNavigate();
  document.querySelector('body').style.overflow = 'hidden';

  const exit = () => {
    document.querySelector('body').style.overflow = 'auto';
    navigate('/');
  };

  registerLocale('es', es);
  const isWeekday = (date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  const onChangeData = (date) => {
    setEndDate(date);
    var text = ' ';

    const start = moment(startDate);
    const end = moment(date);
    var daysBetween = DaysBetweenDates(start, end);

    daysBetween.push(moment(date).format('DD-MM-YYYY'));
    setArrDays(daysBetween);

    daysBetween.forEach((day, key, arrDays) => {
      day = moment(moment(day, 'DD-MM-YYYY')).format('ddd DD MMMM');
      if (day.slice(0, 3) === 'Sat' || day.slice(0, 3) === 'Sun') {
        text = text + `<p> ${day} </p> <p> <strong>No laborable</strong></p>`;
      } else if (Object.is(arrDays.length - 1, key)) {
        text = text + `<p> ${day} </p> <p> <strong style="color:red" >Realizar fichaje manual</strong></p>`;
      } else {
        text = text + `<p> ${day} </p> <p> <strong>10h</strong></p>`;
      }
    });

    setTextDays(text);
  };

  const onConfirm = () => {
    var option = window.confirm('Este paso es irreversible \n¿Está segudo que desea seleccionar ese rango de días?');
    if (option === true) {
      arrDays.forEach(async (day, key, arr) => {
        var nomDay = moment(day, 'DD-MM-YYYY').format('ddd');
        if (nomDay !== 'Sat' && nomDay !== 'Sun' && key !== arr.length - 1) {
          //Introduce un registro de viaje
          // prettier-ignore
          await setDoc(doc(db, 'users', user.uid, 'registros', moment(day, 'DD-MM-YYYY').format('MMMM-YYYY')), {
            [day]: {
              0: {
                entrada: `<i className="fa-solid fa-plane-departure"></i>`,
                salida: `<i className="fa-solid fa-plane-departure"></i>`,
                total: '10:00',
                totalAcumulado: '10:00',
                tpRegistro: 'Viaje',
              },
            },
          },{ merge: true });
          //prettier-ignore
          await setDoc(doc(db, 'users', user.uid),{
            estadoSesion: "Viaje"
          }, {merge:true})
          document.querySelector('body').style.overflow = 'auto';
          navigate('/');
        }
      });
    }
  };

  return (
    <div className='modal-route'>
      <div className='travel'>
        <div className='modal-header'>
          <h5 className='modal-title'>
            <i className='fa fa-user'></i>Programar viaje
          </h5>
          <button type='button' className='btn-close' onClick={() => exit()}></button>
        </div>
        <div className='modal-body'>
          <div className='row'>
            <div className='col-md-5'>
              <p> Seleccionar rango de días: </p>
              <div className='input-group mb-3'>
                <div className='input-group-prepend'>
                  <span className='input-group-text' id='basic-addon1'>
                    Salida
                  </span>
                </div>
                <DatePicker
                  className='datePicker-input disabled'
                  locale='es'
                  dateFormat='dd/MM/yyyy'
                  selected={startDate}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  disabled
                />
              </div>
              <div className='input-group mb-3'>
                <div className='input-group-prepend'>
                  <span className='input-group-text' id='basic-addon1'>
                    Llegada
                  </span>
                </div>
                <DatePicker
                  className='datePicker-input'
                  dateFormat='dd/MM/yyyy'
                  locale='es'
                  selected={endDate}
                  onChange={(date) => onChangeData(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  filterDate={isWeekday}
                  placeholderText='_ _ / _ _ / _ _ _ _'
                />
              </div>
            </div>
            <div className='col-md-7'>
              <p>
                <strong>Días Seleccionados :</strong>
              </p>
              <div className='days-selected' dangerouslySetInnerHTML={{ __html: textDays }}></div>
            </div>
            <div className='col-md-12'>
              <div className='modal-footer'>
                <div>
                  <p>¿Desea introducir los campos seleccionados? &nbsp;&nbsp;&nbsp;&nbsp;</p>
                </div>
                <button type='button' className='btn btn-danger' data-dismiss='modal' onClick={onConfirm}>
                  Aceptar
                </button>
                <button type='button' className='btn btn-secondary pull-left' data-dismiss='modal' onClick={() => exit()}>
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import { useState } from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import './styles/Login.css';

export function Login() {
  //Declaro los estados
  const [user, setUser] = useState({ email: '', password: '' });
  const [show, setShow] = useState(false);
  const [error, setError] = useState({ modal: 'modal' });

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    //Registro de usuario
    const auth = getAuth();
    await signInWithEmailAndPassword(auth, user.email, user.password)
      .then((userCredential) => {
        navigate('/');
      })
      .catch((error) => {
        console.log(error.code);
        //Habilita el modal de bootstrap avisando de credenciales incorrectas
        setError({
          modal: 'modal-backdrop show',
        });
      });
  };

  const handleChange = ({ target: { value, name } }) => {
    setUser({ ...user, [name]: value });
  };

  return (
    <div id='login'>
      <div className='container'>
        <div className='row'>
          <div className='col-xl-12'>
            <div className='form-wrap'>
              <h1>Control Presencia</h1>
              <hr />
              <form onSubmit={handleSubmit}>
                <div className='col mb-4'>
                  <input type='email' name='email' id='email' placeholder='Email...' className='form-control' onChange={handleChange} />
                </div>
                <div className='col mb-4'>
                  <input type={show ? 'text' : 'password'} name='password' id='password' placeholder='Password...' className='form-control' onChange={handleChange} />
                </div>
                <div className='checkbox'>
                  <input type='checkbox' className='character-checkbox' onClick={() => setShow(!show)}></input>
                  <span className='label'>Show password</span>
                </div>
                <div className='col mb-4'>
                  <button type='submit' className='btn btn-custom'>
                    Sign in
                  </button>
                </div>
              </form>
            </div>
          </div>

          <div className='logo'>
            <img src='/static/media/logo_White.png' width='250px' alt='Logotipo Cetem' />
          </div>
          <div className='develop'>Developed by CETEM</div>

          <div className={error.modal} tabIndex='-1' role='dialog'>
            <div className='modal-dialog' role='document'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h5 className='modal-title'>Credenciales Incorrectas</h5>
                  <button
                    type='button'
                    className='close'
                    data-dismiss='modal'
                    aria-label='Close'
                    onClick={() => {
                      setError({ modal: 'modal' });
                    }}
                  >
                    <span aria-hidden='true'>&times;</span>
                  </button>
                </div>
                <div className='modal-body'>
                  <p>Se ha producido un error</p>
                </div>
                <div className='modal-footer'>
                  <button
                    type='button'
                    className='btn btn-secondary'
                    data-dismiss='modal'
                    onClick={() => {
                      setError({ modal: 'modal' });
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

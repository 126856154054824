import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../firebase';
import { ExportCHXLS } from '../../functions/ExportCHXLS';
import { ExportMonthXLS } from '../../functions/ExportMonthXLS';
import { ExportAbsencesXLS } from '../../functions/ExportAbsencesXLS';
import { UpdateProjects } from '../../functions/UpdateProjects';
import { ExportPDF } from '../../functions/ExportPDF';

export const Header = (props) => {
  const user = props.user.userSession;

  const [notificationsCount, setNotificationsCount] = useState(0);

  const auth = getAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    await signOut(auth);
    navigate('/login');
  };

  useEffect(() => {
    if (user.privileges) {
      getDocs(query(collection(db, 'notifications'), where('processed', '==', false))).then((documents) => {
        var cont = 0;
        documents.forEach((document) => {
          cont++;
        });
        setNotificationsCount(cont);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <nav className='mb-1 navbar navbar-dark secondary-color lighten-1'>
      <div className='navbar-brand' href='/#'>
        <i className='fa-solid fa-bars menu float-start' data-bs-toggle='offcanvas' data-bs-target='#offcanvasNavbar' aria-controls='offcanvasNavbar'></i>
        <p className='title'>Control Presencia</p>
      </div>
      <div className='offcanvas offcanvas-start animated fadeInLeft' tabIndex='-1' id='offcanvasNavbar' aria-labelledby='offcanvasNavbarLabel'>
        <button type='button' className='btn-close' data-bs-dismiss='offcanvas' aria-label='Close'></button>

        <div className='offcanvas-body'>
          <ul className='navbar-nav justify-content-end flex-grow-1 pe-3'>
            <li className='nav-item' data-bs-dismiss='offcanvas' aria-label='Close'>
              <Link to='/time-control'>
                <span className='nav-link'>
                  <img src='static/media/calendario.png' alt='Control horario' className='icon' />
                  Rellenar Control Horario
                </span>
              </Link>
            </li>
            <li className='nav-item dropdown'>
              <span className='nav-link dropdown-toggle' href='#' role='button' data-bs-toggle='dropdown' aria-expanded='false'>
                <img src='static/media/searchCH.png' alt='Consultar control horario' className='icon' />
                Consultar Control Horario
              </span>
              <ul className='dropdown-menu'>
                <li>
                  <span className='dropdown-item' data-bs-dismiss='offcanvas' aria-label='Close'>
                    <Link to='/time-control-employee'>
                      <img src='static/media/ch_empleado.png' alt='Control horario por empleados' />
                      CH por empleados
                    </Link>
                  </span>
                </li>
                <li>
                  <span className='dropdown-item' data-bs-dismiss='offcanvas' aria-label='Close'>
                    <Link to='/time-control-dpto'>
                      <img src='static/media/ch_departamento.png' alt='Control horario por departamento' />
                      CH por departamento
                    </Link>
                  </span>
                </li>
                <li>
                  <span className='dropdown-item' data-bs-dismiss='offcanvas' aria-label='Close'>
                    <Link to='/time-control-prj'>
                      <img src='static/media/ch_proyecto.png' alt='Control horario por proyectos' />
                      CH por proyectos
                    </Link>
                  </span>
                </li>
                {user.privileges && (
                  <li onClick={() => ExportCHXLS()}>
                    <span className='dropdown-item' data-bs-dismiss='offcanvas' aria-label='Close'>
                      <img src='static/media/XLSList.png' alt='Control horario general xls' />
                      CH general XLS
                    </span>
                  </li>
                )}
              </ul>
            </li>
            <li className='nav-item dropdown'>
              <span className='nav-link dropdown-toggle' href='#' role='button' data-bs-toggle='dropdown' aria-expanded='false'>
                <img src='static/media/correccion.png' alt='Solicitudes' className='icon' />
                Solicitudes
              </span>
              <ul className='dropdown-menu'>
                <li>
                  <span className='dropdown-item' data-bs-dismiss='offcanvas' aria-label='Close'>
                    <Link to='/incidence'>
                      <img src='static/media/Sol_correccion.png' alt='Solicitud Corrección' />
                      Solicitud Corrección
                    </Link>
                  </span>
                </li>
                <li>
                  <span className='dropdown-item' data-bs-dismiss='offcanvas' aria-label='Close'>
                    <Link to='/absences'>
                      <img src='static/media/Vacaciones.png' alt='Ausencias y vacaciones' />
                      Ausencias / Vacaciones
                    </Link>
                  </span>
                </li>
              </ul>
            </li>
            <li className='nav-item' data-bs-dismiss='offcanvas' aria-label='Close'>
              <Link to='/receips'>
                <span className='nav-link'>
                  <img src='static/media/dietas.png' alt='Estado personal' className='icon' />
                  Visualizar Dietas
                </span>
              </Link>
            </li>
            <li className='nav-item' data-bs-dismiss='offcanvas' aria-label='Close'>
              <Link to='/worker-status'>
                <span className='nav-link'>
                  <img src='static/media/estado-trabajador.png' alt='Estado personal' className='icon' />
                  Estado del personal
                </span>
              </Link>
            </li>

            {!user.privileges && (
              <li className='nav-item' data-bs-dismiss='offcanvas' aria-label='Close'>
                <Link to='/worker-calendar'>
                  <span className='nav-link'>
                    <img src='static/media/calendario_usuario.png' alt='Calendario laboral' className='icon' />
                    Calendario Laboral
                  </span>
                </Link>
              </li>
            )}

            {user.privileges && (
              <>
                <li className='nav-item dropdown'>
                  <span className='nav-link dropdown-toggle' href='#' role='button' data-bs-toggle='dropdown' aria-expanded='false'>
                    <img src='static/media/empleados.png' alt='Empleados' className='icon' />
                    Empleados
                  </span>
                  <ul className='dropdown-menu'>
                    <li>
                      <span className='dropdown-item' data-bs-dismiss='offcanvas' aria-label='Close'>
                        <Link to='/worker-list'>
                          <img src='static/media/listado-empleados.png' alt='Listado empleados' />
                          Listado empleados
                        </Link>
                      </span>
                    </li>
                    <li>
                      <span className='dropdown-item' data-bs-dismiss='offcanvas' aria-label='Close'>
                        <Link to='/new-employee'>
                          <img src='static/media/crear-empleado.png' alt='Crear empleados' />
                          Crear empleado
                        </Link>
                      </span>
                    </li>
                    <li data-bs-dismiss='offcanvas' aria-label='Close'>
                      <Link to='/worker-records'>
                        <span className='dropdown-item'>
                          <img src='static/media/registros.png' alt='Registros empleados' />
                          Registros empleados
                        </span>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className='nav-item dropdown'>
                  <span className='nav-link dropdown-toggle' href='#' role='button' data-bs-toggle='dropdown' aria-expanded='false'>
                    <img src='static/media/calendario_anual.png' alt='Calendario Laboral' className='icon' />
                    Calendario Laboral
                  </span>
                  <ul className='dropdown-menu'>
                    <li data-bs-dismiss='offcanvas' aria-label='Close'>
                      <Link to='/worker-calendar'>
                        <span className='dropdown-item' href='/worker-calendar'>
                          <img src='static/media/calendario_usuario.png' alt='Visualizar calendario' />
                          Calendario Personal
                        </span>
                      </Link>
                    </li>
                    <li data-bs-dismiss='offcanvas' aria-label='Close'>
                      <Link to='/work-calendar'>
                        <span className='dropdown-item' href='#'>
                          <img src='static/media/crear_calendario.png' alt='Administar calendarios' />
                          Administrar Calendarios
                        </span>
                      </Link>
                    </li>
                  </ul>
                </li>

                <li className='nav-item dropdown'>
                  <span className='nav-link dropdown-toggle' href='#' role='button' data-bs-toggle='dropdown' aria-expanded='false'>
                    <img src='static/media/entregable.png' alt='Entregable' className='icon' />
                    Proyectos
                  </span>
                  <ul className='dropdown-menu'>
                    <li onClick={() => UpdateProjects()} data-bs-dismiss='offcanvas' aria-label='Close'>
                      <span className='dropdown-item' href='#'>
                        <img src='static/media/actualizar-proyectos.png' alt='Actualizar proyectos' />
                        Actualizar Proyectos
                      </span>
                    </li>
                    <li data-bs-dismiss='offcanvas' aria-label='Close'>
                      <Link to='/project-list'>
                        <span className='dropdown-item' href='#'>
                          <img src='static/media/listado-proyectos.png' alt='Listado proyectos' />
                          Listado de proyectos
                        </span>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className='nav-item dropdown'>
                  <span className='nav-link dropdown-toggle' href='#' role='button' data-bs-toggle='dropdown' aria-expanded='false'>
                    <img src='static/media/exportar.png' alt='Exportar Registros' className='icon' />
                    Export. Registros
                  </span>
                  <ul className='dropdown-menu'>
                    <li>
                      <span className='dropdown-item' onClick={() => ExportPDF()}>
                        <img src='static/media/pdf.png' alt='Exportar PDF' />
                        Exportar PDF
                      </span>
                    </li>
                    <li>
                      <span className='dropdown-item' onClick={() => ExportMonthXLS()}>
                        <img src='static/media/xls.png' alt='Exportar XLS' />
                        Exportar XLS
                      </span>
                    </li>
                  </ul>
                </li>
                <li className='nav-item dropdown'>
                  <span className='nav-link dropdown-toggle' href='#' role='button' data-bs-toggle='dropdown' aria-expanded='false'>
                    <img src='static/media/searchCH.png' alt='Consultar control horario' className='icon' />
                    Consulta
                  </span>
                  <ul className='dropdown-menu'>
                    <li>
                      <Link to='/justify-records'>
                        <span className='dropdown-item' data-bs-dismiss='offcanvas' aria-label='Close'>
                          <img src='static/media/registros_justificados.png' alt='Registros empleados' />
                          Registros justificados
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to='/historical-incidence'>
                        <span className='dropdown-item' data-bs-dismiss='offcanvas' aria-label='Close'>
                          <img src='static/media/auditoria.png' alt='Histórico incidencias' className='icon' />
                          Solicitudes Corrección
                        </span>
                      </Link>
                    </li>
                    <li>
                      <span className='dropdown-item' data-bs-dismiss='offcanvas' aria-label='Close'>
                        <Link to='/worker-reports'>
                          <img src='static/media/ch_proyecto.png' alt='Control horario por proyectos' />
                          Informe
                        </Link>
                      </span>
                    </li>
                    <li>
                      <span className='dropdown-item' onClick={() => ExportAbsencesXLS()}>
                        <img src='static/media/ch_proyecto.png' alt='Control horario por proyectos' />
                        Informe Ausencias / Vacaciones
                      </span>
                    </li>
                  </ul>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
      <div className='navbar-collapse animated fadeInLeft faster' id='navbarSupportedContent-555'>
        <ul className='navbar-nav ml-auto nav-flex-icons'>
          {user.privileges && (
            <Link to='/notifications'>
              <li className='notification'>
                <i className='fa-solid fa-envelope-open-text' data-toggle='modal' data-target='#toggleCheckIncidence'></i>
                <span className='badge'>{notificationsCount}</span>
              </li>
            </Link>
          )}
          <li className='nav-item avatar dropdown'>
            <a className='nav-link dropdown-toggle' id='navbarDropdownMenuLink-55' data-bs-toggle='dropdown' aria-expanded='false' href='/#'>
              <img src={user.photoURL} className='rounded-circle z-depth-0' alt='avatar' />
            </a>
            <div className='dropdown-menu dropdown-menu-lg-right dropdown-secondary' aria-labelledby='navbarDropdownMenuLink-55'>
              <a id='logout' className='dropdown-item' href='/#' onClick={handleLogout}>
                Cerrar Sesión<i className='fas fa-door-open'></i>
              </a>
            </div>
          </li>
        </ul>
      </div>
      <hr />
    </nav>
  );
};

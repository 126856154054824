import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDocs, collection, where, query } from 'firebase/firestore';
import { db } from '../../firebase';
import Select from 'react-select';
import { NewCalendar } from '../../components/ui/NewCalendar';
import { ShowCalendar } from '../../components/ui/ShowCalendar';
import { EditCalendar } from '../../components/ui/EditCalendar';
import './styles/WorkCalendar.css';

export const WorkCalendar = () => {
  const [users, setUsers] = useState([]);
  const [activeUsers, setActiveUsers] = useState(null);
  const [calendars, setCalendars] = useState([]);
  const [years, setYears] = useState([new Date().getFullYear().toString()]);
  const [activeCalendar, setActiveCalendar] = useState(null);
  const [activeYear, setActiveYear] = useState(new Date().getFullYear().toString());
  const [action, setAction] = useState(null);

  const navigate = useNavigate();
  document.querySelector('body').style.overflow = 'hidden';

  useEffect(() => {
    let arrCalendars = [];
    const arrYears = [];
    //Obtiene el calendario general
    getDocs(collection(db, 'calendars')).then((doc) => {
      doc.forEach((doc) => {
        arrYears.indexOf(doc.data().year) === -1 && arrYears.push(doc.data().year);
        arrCalendars = [...arrCalendars, { ...doc.data(), id: doc.id }];
      });
      setYears([...arrYears]);
      setCalendars([...arrCalendars]);
    });
  }, [action]);

  useEffect(() => {
    const arrUser = [];
    getDocs(query(collection(db, 'users'), where('estadoSesion', '!=', 'Deshabilitado')))
      .then((data) => {
        data.forEach((doc) => {
          arrUser.push({
            value: doc.data().id,
            name: doc.data().displayName,
            label: (
              <div className='d-flex align-items-center'>
                <img src={doc.data().photoURL} className='rounded-circle object-fit-cover' width='35' height='35' style={{ marginRight: '15px' }} />
                {doc.data().displayName}
              </div>
            ),
          });
        });
        arrUser.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
        setUsers(arrUser);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [calendars]);

  const createCalendar = (e) => {
    e.preventDefault();

    const name = e.target[0].value;
    const variableTime = e.target[3].value;
    const year = e.target[4].value;

    const regex = /^([0-9]|[01][0-9]|2[0-3]|[0-9][0-9]):[0-5][0-9]$/;

    if (!regex.test(variableTime)) {
      e.target[3].value = 'Formato de hora incorrecto (HH:MM)';
      e.target[3].style.color = 'red';
      return;
    } else {
      e.target[3].style.color = 'black';
    }

    setActiveCalendar({
      name: name,
      users: activeUsers,
      variableTime: variableTime.split(':')[0] * 60 + variableTime.split(':')[1] * 1,
      year: year,
    });
    setAction('new');
  };

  const onChangeCalendar = (calendar, action) => {
    setActiveCalendar(calendar);
    setAction(action);
  };

  const onChangeYear = (year) => {
    setActiveCalendar(null);
    setActiveYear(year);
  };

  const exit = () => {
    document.querySelector('body').style.overflow = 'auto';
    navigate('/');
  };

  return (
    <div className='modal-route'>
      <div className='workCalendar'>
        <div className='modal-header'>
          <h5 className='modal-title'>
            <i className='fa fa-calendar'></i>Administrar Calendarios
          </h5>
          <button type='button' className='btn-close' onClick={() => exit()}></button>
        </div>
        <div className='modal-body'>
          <div className='row'>
            <div className='col-md-3'>
              <form
                onSubmit={(e) => {
                  createCalendar(e);
                }}
              >
                <h5 className='mb-3 text-center'>Crear Calendario</h5>
                <div className='row mb-3 '>
                  <div className='col-md-10'>
                    <input type='text' className='form-control ' placeholder='Nombre' disabled={activeCalendar === null ? false : true} required />
                  </div>
                  <div className='col-md-2'>
                    <button type='submit' className='btn btn-success mx-0'>
                      <i className='fa-solid fa-plus text-white'></i>
                    </button>
                  </div>
                </div>
                <div className='row mb-3'>
                  <Select
                    options={users}
                    isMulti
                    isSearchable={true}
                    isDisabled={activeCalendar === null ? false : true}
                    closeMenuOnSelect={false}
                    placeholder={<div>Selecciona los empleados</div>}
                    onChange={(e) => setActiveUsers(e)}
                    required
                  />
                </div>
                <div className='row '>
                  <div className='col-md-6'>
                    <input
                      type='text'
                      id='customTimeInput'
                      className='form-control'
                      placeholder='Horas variables (HH:MM)'
                      disabled={activeCalendar === null ? false : true}
                      required
                    />
                  </div>
                  <div className='col-md-6'>
                    <input
                      type='number'
                      className='form-control'
                      min='1900'
                      max='2099'
                      step='1'
                      defaultValue={new Date().getFullYear()}
                      disabled={activeCalendar === null ? false : true}
                      required
                    />
                  </div>
                </div>
              </form>

              <hr style={{ margin: '40px 0' }} />
              <div className='saved-calendars'>
                <h5 className='my-4 text-center'>Calendarios Guardados</h5>

                <select className='form-select mb-3' onChange={(e) => onChangeYear(e.target.value)}>
                  {years.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>

                {calendars
                  .filter((calendar) => calendar.year === activeYear)
                  .map((calendar) => (
                    <div key={calendar.name} className='mb-3'>
                      <p>{calendar.name}</p>
                      <div className='d-flex float-end gap-2'>
                        <button className='btn btn-success ' onClick={() => onChangeCalendar(calendar, 'show')}>
                          Mostrar
                        </button>
                        <button className='btn btn-warning' onClick={() => onChangeCalendar(calendar, 'edit')}>
                          <i className='fa fa-pencil text-gray '></i>
                        </button>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div className='col-md-9'>
              {activeCalendar === null ? (
                <p className='text-center'>Selecciona un calendario</p>
              ) : action === 'show' ? (
                <ShowCalendar calendar={activeCalendar} />
              ) : action === 'edit' ? (
                <EditCalendar calendar={activeCalendar} />
              ) : (
                action === 'new' && <NewCalendar calendar={activeCalendar} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import React, { useState, useEffect, useContext } from 'react';
import { userContext } from '../../context/UserContext';
import { useNavigate } from 'react-router-dom';
import { getDocs, collection, query, where } from 'firebase/firestore';
import { db } from '../../firebase';
import moment from 'moment';

import './styles/AbsencesReport.css';

export const AbsencesReport = () => {
  const { userData } = useContext(userContext);

  const [users, setUsers] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear());

  useEffect(() => {
    (async () => {
      var userQuery = null;

      if (userData.userSession.privileges) {
        userQuery = query(collection(db, 'users'), where('estadoSesion', '!=', 'Deshabilitado'));
      } else if (userData.userSession.role === 'Responsable') {
        userQuery = query(collection(db, 'users'), where('estadoSesion', '!=', 'Deshabilitado'), where('departamento', '==', userData.userSession.departamento));
      }

      if (query) {
        const users = await getDocs(userQuery);

        var arrUsers = [];
        for (const user of users.docs) {
          let absences = user.data()?.calendario?.[year]?.horas_variables || 0;
          let persAffairs = user.data()?.calendario?.[year]?.asuntos_propios || 0;

          await getDocs(query(collection(db, 'users', user.id, 'ausencias'), where('status', '==', 'Aceptado'), where('year', '==', year.toString()))).then((documents) => {
            documents.forEach((doc) => {
              if (doc.data().type === 'Vacaciones') {
                absences = absences - doc.data().total;
              } else if (doc.data().type === 'Asuntos_propios') {
                persAffairs = persAffairs - (moment(doc.data().endDate, 'DD/MM/YYYY').diff(moment(doc.data().startDate, 'DD/MM/YYYY'), 'days') + 1);
              }
            });
          });

          arrUsers.push({
            uid: user.id,
            displayName: user.data().displayName,
            photoURL: user.data().photoURL,
            departament: user.data().departamento,
            tAbsences: user.data()?.calendario?.[year]?.horas_variables,
            absences: absences,
            tPersAffairs: user.data()?.calendario?.[year]?.asuntos_propios,
            persAffairs: persAffairs,
          });
        }

        arrUsers.sort((a, b) => (a.displayName > b.displayName ? 1 : b.displayName > a.displayName ? -1 : 0));
        setUsers(arrUsers);
      }
    })();
  }, [year]);

  const onChangeYear = (e) => {
    const min = e.target.min;

    setUsers([]);

    if (e.target.value < min) {
      setYear(min);
    } else {
      setYear(e.target.value);
    }
  };

  const navigate = useNavigate();
  document.querySelector('body').style.overflow = 'hidden';

  const exit = () => {
    document.querySelector('body').style.overflow = 'auto';
    navigate('/');
  };

  return (
    <div className='modal-route'>
      <div className='absencesReport'>
        <div className='modal-header'>
          <h5 className='modal-title '>
            <i className='fa-solid fa-umbrella-beach'></i>Control de ausencias y vacaciones
          </h5>
          <button type='button' className='btn-close' onClick={() => exit()}></button>
        </div>
        <div className='modal-body'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='mb-3'>
                <label htmlFor='year' className='form-label'>
                  Seleccionar año:
                </label>
                <input type='number' className='form-control' id='year' value={year} min={'2023'} onChange={(e) => onChangeYear(e)} onKeyDown={(e) => e.preventDefault()} />
              </div>
            </div>
          </div>
          {users.length === 0 ? (
            <p className='text-center'>Cargando ...</p>
          ) : (
            <div className='row align-items-stretch '>
              <div className='header row mb-2 text-center'>
                <div className='col-sm-12 col-md-4'>
                  <h6>Nombre</h6>
                </div>
                <div className='col-sm-12 col-md-4'>
                  <h6>Vacaciones</h6>
                </div>
                <div className='col-sm-12 col-md-4'>
                  <h6>Asuntos Propios</h6>
                </div>
              </div>
              <hr></hr>
              {users.map((user) => (
                <div className='row' key={user.uid}>
                  <div className='userData col-xs-12 col-md-4'>
                    <img src={user.photoURL} alt='...' className='avatar ' />
                    <strong className='ms-4'>{user.displayName}</strong> - [{user.departament}]
                  </div>
                  <div className='col-xs-12 col-md-4 text-center'>
                    <div className='progress rounded-pill float-start'>
                      <div
                        className='progress-bar progress-bar-striped bg-success rounded-pill '
                        role='progressbar'
                        style={{ width: (user.absences / user.tAbsences) * 100 + '%' }}
                      ></div>
                    </div>
                    <p>
                      {' '}
                      {Math.floor(user.absences / 60) + 'h ' + (user.absences % 60) + 'min'}{' '}
                      <strong>{'/ ' + Math.floor(user.tAbsences / 60) + 'h ' + (user.tAbsences % 60) + 'min'}</strong>
                    </p>
                  </div>
                  <div className='col-xs-12 col-md-4 text-center'>
                    <div className='progress rounded-pill float-start'>
                      <div
                        className='progress-bar progress-bar-striped rounded-pill '
                        role='progressbar'
                        style={{ width: (user.persAffairs / user.tPersAffairs) * 100 + '%' }}
                      ></div>
                    </div>
                    <p>
                      {' '}
                      {user.persAffairs} <strong>{'/ ' + user.tPersAffairs + ' días'}</strong>
                    </p>
                  </div>
                  <hr />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

import React, { useEffect, useState } from 'react';
import { getDocs, query, collection, where, documentId } from 'firebase/firestore';
import { db } from '../../firebase';
import moment from 'moment';

export const TimeControlPending = (props) => {
  let { userSession, userRecords, unjustifiedWeeks } = props.user;
  const [remaining, setRemaining] = useState('0h 00m');

  useEffect(() => {
    //prettier-ignore
    (async () => {

      if(parseInt(unjustifiedWeeks) >= 1 ){
        
        const start = moment().subtract(unjustifiedWeeks, 'week').isoWeekday(1).format('YYYY-MM-DD');
        const end = moment().subtract(unjustifiedWeeks, 'week').isoWeekday(6).format('YYYY-MM-DD');

        if (moment(start).format('MM') !== moment(end).format('MM')) {
          const docs = await getDocs(query(collection(db, 'users', userSession.uid, 'registros'), where(documentId(), '==', moment(start).format('MMMM-YYYY'))));
          const data = docs.docs.map((doc) => doc.data())[0];
          // eslint-disable-next-line
          userRecords = { ...userRecords, ...data };
        }

        let h = 0;
        let min = 0;

        for (var m = moment(start); m.isBefore(end); m.add(1, 'days')) {
          if (userRecords[m.format('DD-MM-YYYY')]) {
            var recordsPerDay = Object.keys(userRecords[m.format('DD-MM-YYYY')]).length;
            var totalAcumulado = userRecords[m.format('DD-MM-YYYY')][recordsPerDay - 1].totalAcumulado;
            h += parseInt(totalAcumulado.split(':')[0]);
            min += parseInt(totalAcumulado.split(':')[1]);
          }
        }

        if (min > 60) {
          h = h + Math.trunc(min / 60);
          min = min % 60;
        }

        let total = h.toString().padStart(2, '0') + 'h ' + min.toString().padStart(2, '0') + 'min';
        
        setRemaining(total);
      }else {
        setRemaining('0h 00m');
      }
    })();
  }, [unjustifiedWeeks]);

  return (
    <div className='card'>
      <div className='card-body'>
        <div className='row'>
          <div className='col mt-0'>
            <h5 className='card-title'>CH Pendiente</h5>
          </div>

          <div className='col-auto'>
            <div className='avatar'>
              <div className='avatar-title'>
                <i className='fas fa-solid fa-pen-to-square'></i>
              </div>
            </div>
          </div>
        </div>
        <h1 className={'display-5 mt-2 mb-4 '}>{remaining}</h1>
        <div className='mb-0'>
          Semanas pendientes <span className='text-dark'> {unjustifiedWeeks}</span>
        </div>
      </div>
    </div>
  );
};

import { GetServerTime } from './GetServerTime';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { TotalViaje } from './TotalViaje';
import { TotalViajeIncidence } from './TotalViajeIncidence';
import moment from 'moment';

export const SalidaViaje = async (props) => {
  const records = props.userRecords;
  const user = props.userSession;

  //Obtenemos la fecha y hora del servidor
  var serverTime = await GetServerTime();
  const time = serverTime.formatted;

  //Comprueba cuantos registros tenemos diarios
  var longRecord = 0;
  if (records[moment(time).format('DD-MM-YYYY')]) {
    var dailyRecords = records[moment(time).format('DD-MM-YYYY')];
    longRecord = Object.keys(dailyRecords).length;
  }

  if (longRecord !== 0) {
    if (!records[moment(time).format('DD-MM-YYYY')][longRecord - 1].salida) {
      //Introducimos la salida - Upgrade v1
      records[moment(time).format('DD-MM-YYYY')][longRecord - 1].salida = moment(time).format('HH:mm');

      await TotalViaje(props, time);
      //prettier-ignore
      await setDoc(doc(db, 'users', user.uid),{
          estadoSesion: "Desconectado"
        }, {merge:true})
    } else {
      alert('Actualmente estas fuera de la empresa');
    }
  } else {
    var dayWeek = moment(time).format('dd');
    if (dayWeek === 'Sa') {
      //prettier-ignore
      longRecord = Object.keys(records[moment(time).subtract(1, 'days').format("DD-MM-YYYY")]).length;
      //prettier-ignore
      if(records[moment(time).subtract(1, 'days').format("DD-MM-YYYY")][longRecord - 1].tpRegistro === "Vuelta viaje" && !records[moment(time).subtract(1, 'days').format("DD-MM-YYYY")][longRecord - 1].salida ){
          //Intoduce la salida
          //prettier-ignore
          records[moment(time).subtract(1, 'days').format('DD-MM-YYYY')][longRecord - 1].salida = "23:59";

          //Introduce el total
          //Hasta que esta funcion no termina el reloj sigue apareciendo en pantalla
          await TotalViajeIncidence(props, time);

          //Modificar estado
          //prettier-ignore
          await setDoc(doc(db, 'users', user.uid),{
            estadoSesion: "Desconectado"
          }, {merge:true})
        }
        else{
          alert("Actualmente estas fuera de la empresa")
        }
    } else {
      alert('Actualmente estas fuera de la empresa');
    }
  }
};

import React, { useState, useEffect } from 'react';
import moment from 'moment';

export const ShowCalendar = (props) => {
  const calendar = props.calendar.data;

  let [inputValue, setInputValue] = useState([]);

  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  useEffect(() => {
    inputValue = [];
    Object.values(calendar).map((month) => {
      month.days.map((day) => {
        if (day.hours !== '00:00' && !inputValue.find((input) => input.time === day.hours)) {
          //console.log('encontrado');
          inputValue.push({
            time: day.hours,
            color: day.color,
          });
        }
      });
    });
    setInputValue([...inputValue]);
  }, [calendar]);

  return (
    <>
      <div className='inputs'>
        <div className='d-flex gap-2'>
          {inputValue.map((input) => (
            <div key={input.color}>
              <button className='btn btn-default' style={{ border: `1px solid ${input.color}`, color: `${input.color}`, backgroundColor: `${input.color + '30'}` }}>
                {input.time}
              </button>
            </div>
          ))}
          <button className='btn btn-outline-warning'>Festivo</button>
          <button className='btn btn-default' style={{ border: `1px solid #ff8800`, color: `#ff8800`, backgroundColor: `#ff880030` }}>
            Vacaciones
          </button>
        </div>
      </div>
      <div className='calendar'>
        {months.map((month) => (
          <div key={month}>
            <h3>{month}</h3>
            <div className='month'>
              <div className='day-name'>Lun</div>
              <div className='day-name'>Mar</div>
              <div className='day-name'>Mie</div>
              <div className='day-name'>Jue</div>
              <div className='day-name'>Vie</div>
              <div className='day-name'>Sab</div>
              <div className='day-name'>Dom</div>

              {calendar[month].days.map((day, i) =>
                // si es el primer day
                i === 0 ? (
                  <div
                    id={day.date}
                    key={month + i}
                    className={`day ${day.color ? 'text-white' : ''}`}
                    style={{ gridColumnStart: ` ${moment(day.date).isoWeekday()} `, backgroundColor: `${day.color}` }}
                  >
                    {moment(day.date).format('DD')}
                  </div>
                ) : (
                  <div id={day.date} key={month + i} className={`day ${day.color ? 'text-white' : ''}`} style={{ backgroundColor: `${day.color}` }}>
                    {moment(day.date).format('DD')}
                  </div>
                )
              )}
            </div>
            <div className='total'>
              <h5>Total: {calendar[month].total}</h5>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

import React, { useState, useEffect } from 'react';
import { doc, getDoc, getDocs, collection, query, where } from 'firebase/firestore';
import { db } from '../../firebase';
import moment from 'moment';

export const MonthlyRemaining = (props) => {
  const records = props.user.userRecords;
  const user = props.user.userSession;

  const [monthlyRemaining, setMonthlyRemaining] = useState('0h 00min');
  const [monthlyTotal, setMonthlyTotal] = useState('0h 00min');

  useEffect(() => {
    (async () => {
      try {
        const calendarId = user.calendario[new Date().getFullYear()].id;
        let calendar = await getDoc(doc(db, 'calendars', calendarId));

        if (!calendar.exists()) return;

        calendar = calendar.data().data[moment().format('MMMM')];

        let theoretical = calendar.total;
        theoretical = theoretical.split(':')[0] * 60 + theoretical.split(':')[1] * 1;

        let real = 0;

        if (records) {
          for (var m = moment().startOf('month'); m.isBefore(moment()); m.add(1, 'days')) {
            //si el dia es diferente de sabado o domingo
            const dayWeek = moment(m, 'YYYY-MM-DD').format('d');
            if (dayWeek !== '0' && dayWeek !== '6') {
              //Calcula el total de horas reales
              if (records[m.format('DD-MM-YYYY')]) {
                const longDayRecords = Object.entries(records[m.format('DD-MM-YYYY')]).length - 1;
                let dailyTotal;

                if (Object.entries(records[m.format('DD-MM-YYYY')])[longDayRecords][1].totalAcumulado || longDayRecords === 0) {
                  dailyTotal = Object.entries(records[m.format('DD-MM-YYYY')])[longDayRecords][1].totalAcumulado;
                } else {
                  dailyTotal = Object.entries(records[m.format('DD-MM-YYYY')])[longDayRecords - 1][1].totalAcumulado;
                }
                if (dailyTotal) {
                  const dato = dailyTotal.split(':');
                  real = real + parseInt(dato[0]) * 60;
                  real = real + parseInt(dato[1]) * 1;
                }
              }
            }
          }

          //Añade al teorico las horas de vacaciones solicitadas y aprobadas
          await getDocs(query(collection(db, 'users', user.uid, 'ausencias'), where('status', '==', 'Aceptado'))).then((doc) => {
            doc.forEach((document) => {
              //si hay registros de vacaciones en este mes
              //prettier-ignore
              if (moment(document.data().startDate, 'DD/MM/YYYY').format('MM/YYYY') === moment().format('MM/YYYY') || moment(document.data().endDate, 'DD/MM/YYYY').format('MM/YYYY') === moment().format('MM/YYYY')){
                let start = moment(document.data().startDate, 'DD/MM/YYYY');
                let end = moment(document.data().endDate, 'DD/MM/YYYY');

                //Detectar si hay cambio de mes en el periodo de vacaciones
                if (start.format('MM') !== end.format('MM')) {
                  if (start.format('MM') === moment().format('MM')) {
                    end = moment(start).endOf('month');
                  } else {
                    start = moment(end).startOf('month');
                  }
                }

                //Si todavía no ha llegado el día, no las sumes
                if (moment() >= start) {
                  if (start.format('YYYY/MM/DD') === end.format('YYYY/MM/DD')) {
                    real = real + parseInt(calendar.days.find((day) => day.date === start.format('YYYY-MM-DD')).hours.split(':')[0]) * 60;
                    real = real + parseInt(calendar.days.find((day) => day.date === start.format('YYYY-MM-DD')).hours.split(':')[1]);
                  } else {
                    for (var m = start; m.isBefore(end + 1); m.add(1, 'days')) {
                      real = real + parseInt(calendar.days.find((day) => day.date === m.format('YYYY-MM-DD')).hours.split(':')[0]) * 60;
                      real = real + parseInt(calendar.days.find((day) => day.date === m.format('YYYY-MM-DD')).hours.split(':')[1]);
                    }
                  }
                }
              }
            });
          });

          if (real < theoretical) {
            const remaining = theoretical - real;
            const hours = Math.floor(remaining / 60);
            const minutes = remaining - hours * 60;
            setMonthlyRemaining(`${hours}h ${minutes.toString().padStart(2, '0')}min`);
          }

          setMonthlyTotal(`${calendar.total}`);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [records]);

  return (
    <div className='card'>
      <div className='card-body'>
        <div className='row'>
          <div className='col mt-0'>
            <h5 className='card-title'>Restante Mensual</h5>
          </div>
          <div className='col-auto'>
            <div className='avatar'>
              <div className='avatar-title'>
                <i className='fa-solid fa-hourglass-half'></i>
              </div>
            </div>
          </div>
        </div>
        <h1 className='display-5 mt-2 mb-4'>{monthlyRemaining}</h1>
        <div className='mb-0'>
          T. Teórico mes <span className='text-dark'> {monthlyTotal} </span>
        </div>
      </div>
    </div>
  );
};

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, query, collection, deleteDoc, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase';
import { customStyles } from '../../constants/dataTableStyle';
import DataTable from 'react-data-table-component';
import './styles/ProjectList.css';

export const ProjectList = () => {
  const [projects, setProjects] = useState([]);

  const navigate = useNavigate();
  document.querySelector('body').style.overflow = 'hidden';

  const exit = () => {
    document.querySelector('body').style.overflow = 'auto';
    navigate('/');
  };

  const removeProject = async (id) => {
    await deleteDoc(doc(db, 'projects', id));
  };

  const modelColumns = [
    {
      name: 'Codigo',
      cell: (row) => <div>{row.codigo}</div>,
      width: '6%',
    },
    {
      name: 'Nombre',
      cell: (row) => <div style={{ fontWeight: 700 }}>{row.nombre}</div>,
      width: '25%',
    },
    {
      name: 'Tipo',
      cell: (row) => <div style={{ textAlign: 'center' }}>{row.tipo}</div>,
      width: '5%',
    },
    {
      name: 'Responsable',
      cell: (row) => <div style={{ textAlign: 'center' }}>{row.responsable}</div>,
      width: '8%',
    },
    {
      name: 'Comentarios',
      selector: 'comentarios',
      wrap: true,
      width: '20%',
      format: (row) => `${row.comentarios.slice(0, 200)}...`,
    },
    {
      name: 'Objetivos',
      selector: 'objetivos',
      wrap: true,
      width: '28%',
      format: (row) => `${row.objetivos.slice(0, 200)}...`,
    },
    {
      name: '',
      cell: (row) => <i className='fa-regular fa-trash-can' onClick={() => removeProject(row.codigo)}></i>,
      width: '5%',
    },
  ];

  useEffect(() => {
    onSnapshot(query(collection(db, 'projects')), (querySnapshot) => {
      const arrProjects = [];
      querySnapshot.forEach((doc) => {
        arrProjects.push(doc.data());
      });
      setProjects(arrProjects);
    });
  }, []);

  return (
    <div className='modal-route'>
      <div className='projectList'>
        <div className='modal-header'>
          <h5 className='modal-title'>
            <i className='fa-solid fa-list-ul m'></i>Listado de proyectos
          </h5>
          <button type='button' className='btn-close' onClick={() => exit()}></button>
        </div>
        <div className='modal-body'>
          <div className='row'>
            <div className='col-md-12'>
              <DataTable noHeader columns={modelColumns} data={projects} customStyles={customStyles} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

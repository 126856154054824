import React, { useState, useEffect } from 'react';
import { getDoc, updateDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase';
import { MailConfirmNotification } from '../../functions/MailConfirmNotification';
import { MailDeleteNotification } from '../../functions/MailDeleteNotification';
import { ShowToast } from '../../functions/ShowToast';
import moment from 'moment';

export const FixRecords = (props) => {
  const data = props.data;

  const [record, setRecord] = useState(null);
  const [recordProposed, setRecordProposed] = useState({});
  const [oldRecords, setOldRecords] = useState([]);
  const [observation, setObservation] = useState(' ');

  useEffect(() => {
    if (!data) return;

    //Obtiene los registros anteriores
    getDoc(doc(db, 'users', data.uid, 'registros', moment(Object.keys(data.recordsProposed)[0], 'DD-MM-YYYY').format('MMMM-YYYY'))).then((document) => {
      var bdDays = Object.keys(document.data());
      var daySelected = Object.keys(data.recordsProposed)[0];
      if (bdDays.includes(daySelected)) {
        setOldRecords(Object.entries(document.data()[Object.keys(data.recordsProposed)[0]]));
      } else {
        setOldRecords([]);
      }
    });

    setRecordProposed(Object.values(data.recordsProposed)[0]); //Almacena el registro propuesto para introducirlo al confirmar

    setRecord({
      id: data.id,
      uid: data.uid,
      name: data.name,
      email: data.email,
      date: Object.keys(data.recordsProposed)[0],
      recordsProposed: Object.entries(data.recordsProposed[Object.keys(data.recordsProposed)[0]]),
      observationEmployee: data.observation,
    });
  }, [data]);

  const onModify = (record) => {
    var numRecord = record.id.toString().slice(-1);
    var typeRecord = record.id.toString().slice(0, -1);
    var newRecord = record.value;
    recordProposed[numRecord][typeRecord] = newRecord;
  };

  const confirmNotification = async () => {
    var records = { [record.date]: recordProposed };

    //await deleteDoc(doc(db, 'notifications', record.id));
    await updateDoc(doc(db, 'notifications', record.id), { processed: true });

    await updateDoc(doc(db, 'users', record.uid, 'registros', moment(record.date, 'DD-MM-YYYY').format('MMMM-YYYY')), records);

    ShowToast('Notificación enviada');
    MailConfirmNotification(record, observation);
    setRecord(null);
    setObservation(' ');
  };

  const deleteNotication = async () => {
    //await deleteDoc(doc(db, 'notifications', record.id));
    await updateDoc(doc(db, 'notifications', record.id), { processed: true });
    ShowToast('Notificación enviada');
    MailDeleteNotification(record, observation);
    setRecord(null);
    setObservation(' ');
  };

  return (
    <>
      {record && (
        <>
          <div id={record.id} className='content'>
            <h6>
              Solicitud de corrección:<strong> {record.name}</strong>
            </h6>
            <hr />
            <p>
              Fecha: <strong>{record.date}</strong>
            </p>
            <hr />
            <p>
              {' '}
              <strong>- Registros actuales: </strong>
            </p>
            {oldRecords.map((data) => (
              <div className='stretchs'>
                <p>
                  <strong>{'Tramo ' + (parseInt(data[0]) + 1)}</strong>
                </p>
                <div className='row'>
                  <div className='col-md-3'>
                    <div className='form-group'>
                      <label htmlFor='name'>Entrada:{}</label>
                      <input type='text' className='form-control' placeholder={data[1].entrada} disabled />
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='form-group'>
                      <label htmlFor='name'>Salida:</label>
                      <input type='text' className='form-control' placeholder={data[1].salida} disabled />
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='form-group'>
                      <label htmlFor='name'>Total:</label>
                      <input type='text' className='form-control' placeholder={data[1].total} disabled />
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='form-group'>
                      <label htmlFor='name'>TotalAcumulado:</label>
                      <input type='text' className='form-control' placeholder={data[1].totalAcumulado} disabled />
                    </div>
                  </div>
                </div>
              </div>
            ))}

            <div className='col-md-12 stretchs'>
              <div className='form-group'>
                <label htmlFor='name'>
                  <strong>Observación del empleado:</strong>
                </label>
                <textarea className='form-control' rows='3' placeholder={record.observationEmployee} disabled />
              </div>
            </div>

            <hr />
            <p>
              {' '}
              <strong>- Registros propuestos: </strong>
            </p>
            {record.recordsProposed.map((data) => (
              <div className='stretchs'>
                <p>
                  <strong>{'Tramo ' + (parseInt(data[0]) + 1)}</strong>
                </p>
                <div className='row'>
                  <div className='col-md-3'>
                    <div className='form-group'>
                      <label htmlFor='name'>Entrada:{}</label>
                      <input
                        type='time'
                        className='form-control'
                        id={'entrada' + data[0]}
                        key={data[1].entrada}
                        defaultValue={data[1].entrada}
                        onChange={(event) => onModify(event.target)}
                      />
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='form-group'>
                      <label htmlFor='name'>Salida:</label>
                      {data[1].salida && data[1].salida.includes('strong') ? (
                        <input
                          type='text'
                          className='form-control'
                          id={'salida' + data[0]}
                          key={data[1].salida}
                          placeholder={data[1].salida}
                          onChange={(event) => onModify(event.target)}
                        />
                      ) : (
                        <input
                          type='time'
                          className='form-control'
                          id={'salida' + data[0]}
                          key={data[1].salida}
                          defaultValue={data[1].salida}
                          onChange={(event) => onModify(event.target)}
                        />
                      )}
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='form-group'>
                      <label htmlFor='name'>Total:</label>
                      <input
                        type='time'
                        className='form-control'
                        id={'total' + data[0]}
                        key={data[1].total}
                        defaultValue={moment(data[1].total, 'H:mm').format('HH:mm')}
                        onChange={(event) => onModify(event.target)}
                      />
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='form-group'>
                      <label htmlFor='name'>TotalAcumulado:</label>
                      <input
                        type='time'
                        className='form-control'
                        id={'totalAcumulado' + data[0]}
                        key={data[1].totalAcumulado}
                        defaultValue={moment(data[1].totalAcumulado, 'H:mm').format('HH:mm')}
                        onChange={(event) => onModify(event.target)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}

            <div className='col-md-12 stretchs'>
              <div className='form-group'>
                <label htmlFor='name'>
                  <strong>Observación:</strong>
                </label>
                <textarea className='form-control' rows='3' onChange={(event) => setObservation(event.target.value)} />
              </div>
            </div>
          </div>

          <div className='modal-footer pe-0 pt-4'>
            <p>¿Desea aceptar los cambios propuestos?</p>
            <button className='btn btn-success' onClick={() => confirmNotification()}>
              {' '}
              Confirmar
            </button>
            <button className='btn btn-danger' onClick={() => deleteNotication()}>
              {' '}
              Rechazar
            </button>
          </div>
        </>
      )}
    </>
  );
};

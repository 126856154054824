import React from 'react';
import { Routes, Route } from 'react-router-dom';
import {
  Login,
  MainPage,
  Input,
  Output,
  WorkerRecords,
  WorkerStatus,
  Incidence,
  HistoricalIncidence,
  Notifications,
  Travel,
  ProjectList,
  TimeControl,
  TimeControlEmployee,
  TimeControlDpto,
  TimeControlPrj,
  NewEmployee,
  WorkerList,
  JustifyRecords,
  WorkerReports,
  WorkerCalendar,
  WorkCalendar,
  Absences,
  AbsencesReport,
  ImageCropper,
  PdfViewer,
  Alert,
  Receips,
} from './pages';
import { ProtectedRoute } from './components/ProtectedRoute';
import { RoleRoutes } from './components/RoleRoutes';
import { AdminRoutes } from './components/AdminRoutes';
import { UserProvider } from './context/UserContext';
import './styles/App.css';

function App() {
  // Nos permite instalar la PWA
  window.addEventListener('beforeinstallprompt', (event) => {
    event.preventDefault();
    window.deferredPrompt = event;
  });

  return (
    <UserProvider>
      <Routes>
        <Route element={<ProtectedRoute />}>
          <Route path='/' element={<MainPage />}>
            <Route path='input' element={<Input />} />
            <Route path='output' element={<Output />} />
            <Route path='worker-status' element={<WorkerStatus />} />
            <Route path='incidence' element={<Incidence />} />
            <Route path='travel' element={<Travel />} />
            <Route path='time-control' element={<TimeControl />} />
            <Route path='time-control-employee' element={<TimeControlEmployee />} />
            <Route path='time-control-dpto' element={<TimeControlDpto />} />
            <Route path='time-control-prj' element={<TimeControlPrj />} />
            <Route path='worker-calendar' element={<WorkerCalendar />} />
            <Route path='absences' element={<Absences />} />
            <Route path='image-cropper' element={<ImageCropper />} />
            <Route path='pdf-viewer' element={<PdfViewer />} />
            <Route path='alert' element={<Alert />} />
            <Route element={<RoleRoutes />}>
              <Route path='absences-report' element={<AbsencesReport />} />
            </Route>
            <Route element={<AdminRoutes />}>
              <Route path='worker-records' element={<WorkerRecords />} />
              <Route path='notifications' element={<Notifications />} />
              <Route path='project-list' element={<ProjectList />} />
              <Route path='justify-records' element={<JustifyRecords />} />
              <Route path='historical-incidence' element={<HistoricalIncidence />} />
              <Route path='new-employee' element={<NewEmployee />} />
              <Route path='worker-list' element={<WorkerList />} />
              <Route path='worker-reports' element={<WorkerReports />} />
              <Route path='work-calendar' element={<WorkCalendar />} />
            </Route>
          </Route>
          <Route path='receips' element={<Receips />} />
        </Route>
        <Route path='login' element={<Login />} />
        <Route path='404' element={<h1>404</h1>} />
        <Route path='*' element={<h1>404</h1>} />
      </Routes>
    </UserProvider>
  );
}

export default App;

export const FetchTimeOut = async (url, timeout) => {
  try {
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
    const res = await fetch(url, { signal: controller.signal });
    const status = await res.json();
    clearTimeout(id);
    return status;
  } catch (error) {
    const status = { status: 'error', message: 'Tiempo de espera agotado' };
    console.log(status);
    return status;
  }
};

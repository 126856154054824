import { collection, query, getDocs, where } from 'firebase/firestore';
import { db } from './../firebase';
import * as XLSX from 'xlsx';

export const ExportAbsencesXLS = async () => {
  let data = [];
  const users = await getDocs(query(collection(db, 'users'), where('estadoSesion', '!=', 'Deshabilitado')));
  for (const user of users.docs) {
    const absences = await getDocs(query(collection(db, 'users', user.id, 'ausencias')));
    for (let absence of absences.docs) {
      let absenceData = absence.data();

      //tengo que establecer el día como hoy y la hora como la hora de inicio de la ausencia
      absenceData.total = new Date(0, 0, 0, Math.floor(absenceData.total / 60), absenceData.total % 60);

      data.push(absenceData);
    }
  }

  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet([
    ['AñoSolicitud', 'Usuario', 'FechaInicio', 'FechaFin', 'Tipo', 'Descripción', 'Estado', 'Tiempo'],
    ...data.map((item) => [item.year, item.name, item.startDate, item.endDate, item.type, item.description, item.status, item.total]),
  ]);

  // Establecer el formato de celda para la columna de tiempo
  const colWidth = [{ wch: 10 }];
  ws['!cols'] = colWidth;

  // Establecer el formato de celda para la columna de tiempo
  const timeFormat = '[h]:mm;@';
  for (let r = 1; r <= data.length; r++) {
    const cell = XLSX.utils.encode_cell({ r, c: 7 });
    if (ws[cell] && ws[cell].t === 'n') {
      ws[cell].z = timeFormat;
    }
  }

  // Agregar la hoja al libro
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  // Guardar el libro como un archivo Excel
  XLSX.writeFile(wb, 'Ausencias.xlsx');
  console.log('Archivo de Excel creado exitosamente.');
};

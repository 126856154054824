import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { userContext } from '../../context/UserContext';
import { Entrada } from '../../functions/Entrada';
import { EntradaViaje } from '../../functions/EntradaViaje';
import { EntradaJustificada } from '../../functions/EntradaJustificada';
import { CheckTrip } from '../../functions/CheckTrip';
import { Loader } from '../../components/Loader';
import simpleClock from '../../assets/animations/simpleClock2.json';
import moment from 'moment';

import './styles/Input.css';

export const Input = () => {
  const { userData } = useContext(userContext);

  const [defaultWidth, setDefaultWidth] = useState('700px');
  const [visible, setVisible] = useState('modal');
  const [incidence, setIncidence] = useState(false);
  const [buttonIncidence, setButtonIncidence] = useState('disabled');
  const [observation, setObservation] = useState(undefined);
  const [btnVisible, setBtnVisible] = useState('d-none');

  var records = userData.userRecords;

  var tripChecked = CheckTrip(userData, 'entrada');

  // checkea si muestra incidencia o no.
  useEffect(() => {
    var currentTime = moment().format('HH');
    if (9 <= currentTime && 13 > currentTime) {
      if (records[moment().format('DD-MM-YYYY')]) {
        var long = Object.keys(records[moment().format('DD-MM-YYYY')]).length;
        if (!records[moment().format('DD-MM-YYYY')][long - 1].tpIncidencia) {
          setIncidence(true);
          if (records[moment().format('DD-MM-YYYY')][long - 1].salida) {
            if (records[moment().format('DD-MM-YYYY')][long - 1].salida.split(':')[0] >= 9) {
              setIncidence(false);
            }
          }
        }
      } else {
        setIncidence(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeHandler = (event) => {
    const { value } = event.currentTarget;
    if (value) {
      setObservation(value);
      setButtonIncidence('');
    } else {
      setButtonIncidence('disabled');
    }
  };

  const toggle = () => {
    if (btnVisible === 'd-none') {
      setBtnVisible('');
      setDefaultWidth('820px');
    } else {
      setBtnVisible('d-none');
      setDefaultWidth('700px');
    }
  };

  const navigate = useNavigate();

  if (incidence) {
    return (
      <div className='modal-route'>
        <div className='input'>
          <div className={visible}>
            <div className='modal-header'>
              <h5 className='modal-title'>¿Confirmar entrada?</h5>
              <button type='button' className='btn-close' onClick={() => navigate('/')}></button>
            </div>
            <div className='modal-body'>
              <div className='observation'>
                <p>
                  Es posible justificar esta entrada entre las <strong>9:00</strong> y las <strong>13:00</strong>. Introduce el motivo.
                </p>
                <p>
                  <strong>Observaciones:</strong>
                </p>
                <textarea placeholder='Introduce el motivo' rows='4' onChange={(event) => onChangeHandler(event)}></textarea>
              </div>
              <div className='float-start'>
                <button type='button' className='btn btn-danger' style={{ marginLeft: 0 }} onClick={() => setIncidence(false)}>
                  No Justificada
                </button>
              </div>
              <div className='float-end'>
                <button
                  type='button'
                  className={'btn btn-success outer ' + buttonIncidence}
                  onClick={async () => {
                    setVisible('modal d-none');
                    await EntradaJustificada(userData, observation, 'Presencial');
                    navigate('/');
                  }}
                >
                  Presencial
                </button>
                <button
                  type='button'
                  className={'btn btn-info ' + buttonIncidence}
                  onClick={async () => {
                    setVisible('modal d-none');
                    await EntradaJustificada(userData, observation, 'Teletrabajo');
                    navigate('/');
                  }}
                >
                  Teletrabajo
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='modal-route'>
      <div className='input' style={{ maxWidth: defaultWidth }}>
        <div className={visible}>
          <div className='modal-header'>
            <h5 className='modal-title'>¿Confirmar entrada?</h5>
            <button type='button' className='btn-close' onClick={() => navigate('/')}></button>
          </div>
          <div className='modal-body'>
            <div className='float-start'>
              <button
                type='button'
                className='btn btn-success outer'
                onClick={async () => {
                  setVisible('modal d-none');
                  await Entrada(userData, 'Presencial');
                  navigate('/');
                }}
              >
                Presencial
              </button>
              <button
                type='button'
                className='btn btn-info'
                onClick={async () => {
                  setVisible('modal d-none');
                  await Entrada(userData, 'Teletrabajo');
                  navigate('/');
                }}
              >
                Teletrabajo
              </button>
            </div>
            {tripChecked && (
              <button
                type='button'
                className='btn btn-outline-warning'
                onClick={async () => {
                  setVisible('modal d-none');
                  await EntradaViaje(userData, 'Vuelta viaje');
                  navigate('/');
                }}
              >
                Vuelta Viaje
              </button>
            )}
            <div className='button-group float-start' onClick={() => toggle()}>
              <button type='button' className='btn btn-warning'>
                Viaje&nbsp;&nbsp;<i className='fa-solid fa-chevron-down'></i>
              </button>
            </div>
            <div className={'hidden-button ' + btnVisible}>
              <button
                type='button'
                className='btn btn-outline-warning'
                onClick={async () => {
                  setVisible('modal d-none');
                  await EntradaViaje(userData, 'Viaje 1 día');
                  navigate('/');
                }}
              >
                Viaje 1 día
              </button>
            </div>
            <div className={'hidden-button ' + btnVisible}>
              <button
                type='button'
                className='btn btn-outline-warning'
                onClick={() => {
                  navigate('/travel');
                }}
              >
                Programar viaje
              </button>
            </div>

            <button type='button' className='btn btn-secondary' onClick={() => navigate('/')}>
              Cancelar
            </button>
          </div>
        </div>
      </div>
      <Loader type={simpleClock} loop={true} width='150px' />
    </div>
  );
};

import React, { useState, useEffect } from 'react';
import { getDoc, getDocs, doc, query, collection, where } from 'firebase/firestore';
import { db } from '../../firebase';
import CreateMonthTable from '../../functions/CreateMonthTable';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import './styles/WorkerRecords.css';

export const WorkerRecords = () => {
  const [usuarios, setUsuarios] = useState([]);
  const [userRecords, setUserRecords] = useState([]);
  const [date, setDate] = useState(moment().format('YYYY-MM'));

  const navigate = useNavigate();
  document.querySelector('body').style.overflow = 'hidden';

  useEffect(() => {
    var arrUser = [];
    //prettier-ignore
    getDocs(query(collection(db, 'users')))
      .then((data) => {
        data.forEach((doc) => {
          arrUser.push(doc.data())
        });
        arrUser.sort((a,b) => (a.displayName > b.displayName) ? 1 : ((b.displayName > a.displayName) ? -1 : 0))
        setUsuarios(arrUser);
      })
      .catch((err) => {
        console.log(err);
      })
  }, []);

  const handleInputChange = async (event) => {
    setUserRecords([]);
    var selectedUser = null;

    await getDocs(query(collection(db, 'users'), where('email', '==', event.target.value))).then((data) => {
      data.forEach((doc) => {
        selectedUser = doc.id;
      });
    });

    if (selectedUser) {
      const docSnap = await getDoc(doc(db, 'users', selectedUser, 'registros', moment(date).format('MMMM-YYYY')));
      if (docSnap) {
        setUserRecords(Object.entries(docSnap.data()));
      }
    }
  };

  const exit = () => {
    document.querySelector('body').style.overflow = 'auto';
    navigate('/');
  };

  var table = CreateMonthTable(userRecords, 'showType');

  return (
    <div className='modal-route'>
      <div className='workerRecords'>
        <div className='modal-header'>
          <h5 className='modal-title'>
            <i className='fa fa-user'></i>Registros del personal
          </h5>
          <button type='button' className='btn-close' onClick={() => exit()}></button>
        </div>
        <div className='modal-body'>
          <div className='row'>
            <div className='col-md-4'>
              {
                <form className='row'>
                  <input type='month' className='form-control ' value={date} onChange={(e) => setDate(e.target.value)} />
                  <select className='form-select mt-4' aria-label='Default select example' onChange={handleInputChange}>
                    {usuarios.map((usuarios) => (
                      <option value={usuarios.email}>{usuarios.displayName}</option>
                    ))}
                  </select>
                </form>
              }
            </div>
            <div className='col-md-8'>
              <table className='table'>
                <thead>
                  <tr>
                    <th scope='col'></th>
                    <th scope='col'>Entrada</th>
                    <th scope='col'>Salida</th>
                    <th scope='col'>T.Tramo</th>
                    <th scope='col'>Tot. Día</th>
                    <th scope='col'>Tipo </th>
                  </tr>
                </thead>
                {<tbody dangerouslySetInnerHTML={{ __html: table }}></tbody>}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

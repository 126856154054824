import React, { useState, useContext, useEffect } from 'react';
import { userContext } from '../../context/UserContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { doc, collection, getDoc, addDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { DaysBetweenDates } from '../../functions/DaysBetweenDates';
import { ShowToast } from '../../functions/ShowToast';
import moment from 'moment';

import './styles/Incidence.css';

export const Incidence = () => {
  const { userData } = useContext(userContext);

  const [daySelected, setDaySelected] = useState(null);
  const [typeIncidence, setTypeIncidence] = useState(null);
  const [recordsDay, setRecordsDay] = useState(null);
  const [arrRecordsDay, setArrRecordsDay] = useState([]);
  const [observation, setObservation] = useState(null);

  var records = userData.userRecords;
  var user = userData.userSession;

  const navigate = useNavigate();
  document.querySelector('body').style.overflow = 'hidden';

  const exit = () => {
    document.querySelector('body').style.overflow = 'auto';
    navigate('/');
  };

  const pageStatus = useLocation().state;

  let start = moment(moment().subtract(1, 'week').isoWeekday(1).format('YYYY-MM-DD'));

  if (pageStatus) {
    start = moment(moment().subtract(2, 'week').isoWeekday(1).format('YYYY-MM-DD'));
  }

  if (userData.unjustifiedWeeks === '0') {
    start = moment(moment().isoWeekday(1).format('YYYY-MM-DD'));
  }
  const end = moment(moment().format('YYYY-MM-DD'));
  let days = DaysBetweenDates(start, end);

  //order days
  days = days.sort((a, b) => moment(b, 'DD-MM-YYYY').format('YYYYMMDD') - moment(a, 'DD-MM-YYYY').format('YYYYMMDD')).slice(1);

  const listItems = days.map((day) => <option key={day.slice(0, 2)}>{day}</option>);

  const getRecords = async (daySelected) => {
    if (records[daySelected]) {
      daySelected = records[daySelected];
      setRecordsDay(daySelected);

      var arrDayRecords = Object.entries(daySelected);
      setArrRecordsDay(arrDayRecords);
    } else {
      if (moment().format('MM') > moment(daySelected, 'DD-MM-YYYY').format('MM') || moment().format('YYYY') > moment(daySelected, 'DD-MM-YYYY').format('YYYY')) {
        //prettier-ignore
        const document = await getDoc(doc(db, 'users', user.uid, 'registros', moment(daySelected, 'DD-MM-YYYY').format('MMMM-YYYY')));
        records = document.data();
        if (records[daySelected]) {
          daySelected = records[daySelected];
          setRecordsDay(daySelected);

          arrDayRecords = Object.entries(daySelected);
          setArrRecordsDay(arrDayRecords);
        } //Si no existen registros en el mes anterior
        else {
          daySelected = {
            0: {
              entrada: '',
              salida: '',
              total: '',
              totalAcumulado: '',
              tpRegistro: 'Incidencia',
            },
          };
          setRecordsDay(daySelected);

          arrDayRecords = [
            [
              '0',
              {
                entrada: '',
                salida: '',
                total: '',
                totalAcumulado: '',
                tpRegistro: 'Incidencia',
              },
            ],
          ];
          setArrRecordsDay(arrDayRecords);
        }
        //Si no existe registros mes actual
      } else {
        daySelected = {
          0: {
            entrada: '',
            salida: '',
            total: '',
            totalAcumulado: '',
            tpRegistro: 'Incidencia',
          },
        };
        setRecordsDay(daySelected);

        arrDayRecords = [
          [
            '0',
            {
              entrada: '',
              salida: '',
              total: '',
              totalAcumulado: '',
              tpRegistro: 'Incidencia',
            },
          ],
        ];
        setArrRecordsDay(arrDayRecords);
      }
    }
  };

  const addRecord = (event) => {
    event.preventDefault();
    var long = arrRecordsDay.length;
    setArrRecordsDay([...arrRecordsDay, [long.toString(), { entrada: null, salida: null }]]);
    setRecordsDay({ ...recordsDay, [long.toString()]: {} });
  };

  const delNewRecord = (event) => {
    event.preventDefault();
    var long = arrRecordsDay.length;
    setArrRecordsDay(arrRecordsDay.slice(0, -1));
    delete recordsDay[long - 1];
  };

  const onModify = (event) => {
    event.preventDefault();
    var record = event.target;
    var numRecord = record.id.toString().slice(-1);
    var typeRecord = record.id.toString().slice(0, -1);
    var newRecord = record.value;
    var newRecords = recordsDay;
    newRecords[numRecord][typeRecord] = newRecord;
    setRecordsDay(newRecords);
  };

  const onConfirmChanges = async (event, type) => {
    event.preventDefault();

    var arrDayRecords = Object.entries(recordsDay);
    var long = arrDayRecords.length;
    for (var i = 0; i < long; i++) {
      const entrada = recordsDay[i].entrada;
      const salida = recordsDay[i].salida;

      // Convierte los datos en legibles para moment.js
      var start = moment(entrada, 'HH:mm');
      var end = moment(salida, 'HH:mm');

      var duracion = moment.duration(end - start);
      //Calcular duracion entre horas / TOTAL
      var total = moment.utc(duracion._milliseconds).format('H:mm');

      var newRecords = recordsDay;

      if (i < 1) {
        if (total.split(':')[0] >= 8) {
          newRecords[i].total = total;
          newRecords[i].totalAcumulado = '08:00';
        } else {
          newRecords[i].total = total;
          newRecords[i].totalAcumulado = total;
        }
      } else {
        var totalAnterior = newRecords[i - 1].totalAcumulado;

        var totalAcumulado = moment(totalAnterior, 'HH:mm').add(total, 'milliseconds');
        totalAcumulado = moment(totalAcumulado).format('H:mm');

        if (totalAcumulado.split(':')[0] >= 10) {
          newRecords[i].total = total;
          newRecords[i].totalAcumulado = '10:00';
        } else {
          newRecords[i].total = total;
          newRecords[i].totalAcumulado = totalAcumulado;
        }
      }

      //Modifica en tiempo real los registros por pantalla
      arrDayRecords = Object.entries(newRecords);
      setArrRecordsDay(arrDayRecords);

      if (i >= long - 1) {
        if (type !== 'refresh' && observation) {
          //Prettier-ignore
          await addDoc(collection(db, 'notifications'), {
            notificationDate: new Date(),
            uid: user.uid,
            name: user.displayName,
            email: user.email,
            avatar: user.photoURL,
            recordsProposed: { [daySelected]: newRecords },
            read: false,
            type: typeIncidence,
            processed: false,
            observation: observation,
          });

          ShowToast('Notificación enviada');
          setRecordsDay(null);
          setArrRecordsDay([]);
          document.querySelector('body').style.overflow = 'auto';
          navigate('/');
        } else {
          if (type !== 'refresh') {
            alert('Es obligatorio meter una observación');
          }
        }
      }
    }
  };

  useEffect(() => {
    //Comprobar si en el form obliga a rellenar el tipo de incidencia
    if (typeIncidence && daySelected) {
      getRecords(daySelected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [daySelected, typeIncidence]);

  return (
    <div className='modal-route'>
      <div className='Incidence'>
        <div className='modal-header'>
          <h5 className='modal-title'>
            <i className='fa fa-check'></i>Solicitud de Incidencia
          </h5>
          {pageStatus && <button type='button' className='btn-close' onClick={() => exit()}></button>}
        </div>
        <div className='modal-body'>
          <div className='row'>
            <div className='col-md-3'>
              <p>Selecciona el día:</p>
              <select className='form-control' defaultValue={'default'} onChange={(e) => setDaySelected(e.target.value)}>
                <option value='default' disabled style={{ display: 'none' }}>
                  Seleccionar
                </option>
                {listItems}
              </select>
              <br />
              <p> Selecciona el tipo de incidencia:</p>
              <select className='form-control' defaultValue={'default'} onChange={(e) => setTypeIncidence(e.target.value)}>
                <option value='default' disabled style={{ display: 'none' }}>
                  Seleccionar
                </option>
                <option value='Error_fichaje'>Error fichaje</option>
                <option value='Error_app'>Error APP</option>
              </select>
            </div>
            <div className='col-md-9'>
              <p>Modificar datos</p>
              <hr />
              <form>
                {arrRecordsDay.map((record) => (
                  <div className='stretchs' key={record[0]}>
                    <p>
                      <strong>Tramo {parseInt(record[0]) + 1}</strong>
                    </p>
                    <div className='row'>
                      <div className='col-md-3'>
                        <div className='form-group'>
                          <label htmlFor='name'>Entrada</label>
                          {record[1].entrada && record[1].entrada.includes('fa-plane-departure') ? (
                            <input type='text' className='form-control' id={'entrada' + record[0]} placeholder='Viaje' disabled />
                          ) : (
                            <input
                              type='time'
                              className='form-control'
                              id={'entrada' + record[0]}
                              key={record[1].entrada}
                              defaultValue={record[1].entrada}
                              onChange={(event) => onModify(event)}
                              required
                            />
                          )}
                        </div>
                      </div>
                      <div className='col-md-3'>
                        <div className='form-group'>
                          <label htmlFor='name'>Salida</label>
                          {record[1].salida && record[1].salida.includes('fa-plane-departure') ? (
                            <input type='text' className='form-control' id={'salida' + record[0]} placeholder='Viaje' onChange={(event) => onModify(event)} disabled />
                          ) : record[1].salida && record[1].salida.includes('strong') ? (
                            <input
                              type='text'
                              className='form-control'
                              id={'entrada' + record[0]}
                              placeholder={record[1].salida.substring(8, record[1].salida.length - 9)}
                              disabled
                            />
                          ) : (
                            <input
                              type='time'
                              className='form-control'
                              id={'salida' + record[0]}
                              key={record[1].salida}
                              defaultValue={record[1].salida}
                              onChange={(event) => onModify(event)}
                              required
                            />
                          )}
                        </div>
                      </div>
                      <div className='col-md-3'>
                        <div className='form-group'>
                          <label htmlFor='name'>Total</label>
                          <input type='text' className='form-control' id={'total' + record[0]} placeholder={record[1].total} value={record[1].total} disabled />
                        </div>
                      </div>
                      <div className='col-md-3'>
                        <div className='form-group'>
                          <label htmlFor='name'>Total Acumulado</label>
                          <input
                            type='text'
                            className='form-control'
                            id={'totalAcumulado' + record[0]}
                            placeholder={record[1].totalAcumulado}
                            value={record[1].totalAcumulado}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

                {recordsDay && (
                  <div className='row'>
                    <div className='col-md-12 add-more'>
                      <button className='btn btn-default' onClick={(event) => addRecord(event)}>
                        <i className='fas fa-plus'></i> Añadir tramo
                      </button>
                      <button className='btn btn-default' onClick={(event) => delNewRecord(event)}>
                        <i className='fas fa-minus'></i> Eliminar tramo
                      </button>
                    </div>
                    <div className='col-md-12 stretchs'>
                      <p>
                        <strong>Observaciones:</strong>*
                      </p>
                      <textarea className='form-control' id='observation' onChange={(event) => setObservation(event.target.value)}></textarea>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
        {recordsDay && (
          <div className='modal-footer'>
            <button
              className='btn btn-custom'
              onClick={(event) => {
                onConfirmChanges(event, 'refresh');
              }}
            >
              <i className='fas fa-sync'></i> Actualizar datos
            </button>
            <button
              type='submit'
              id='submit'
              className='btn btn-danger'
              onClick={(event) => {
                onConfirmChanges(event, 'confirm');
              }}
            >
              {' '}
              <i className='fa-solid fa-check' style={{ color: 'white' }}></i>Confirmar
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { getDoc, getDocs, doc, query, collection, where } from 'firebase/firestore';
import { db } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import './styles/WorkerReports.css';
import es from 'date-fns/locale/es';

export const WorkerReports = () => {
  const [usuarios, setUsuarios] = useState([]);
  const [date, setDate] = useState({
    start: new Date(new Date().getFullYear(), 0, 1),
    end: new Date(new Date().getFullYear(), 0, 1),
  });
  const [data, setData] = useState([]);

  const navigate = useNavigate();
  document.querySelector('body').style.overflow = 'hidden';

  useEffect(() => {
    (async () => {
      var arrUser = [];
      //prettier-ignore
      await getDocs(query(collection(db, 'users')))
        .then((data) => {
          data.forEach((doc) => {
            arrUser.push(doc.data())
          });
          arrUser.sort((a,b) => (a.displayName > b.displayName) ? 1 : ((b.displayName > a.displayName) ? -1 : 0))
          setUsuarios(arrUser);
        })
        .catch((err) => {
          console.log(err);
        })
    })();
  }, []);

  const onSubmit = async () => {
    let doc = [];

    usuarios.forEach(async (user) => {
      let incidents = 0;
      let corrections = 0;

      if (user.estadoSesion !== 'Deshabilitado') {
        //Obtienen los registros con incidencias
        try {
          const docSnap = await getDocs(collection(db, 'users', user.id, 'registros'));
          docSnap.forEach((doc) => {
            if (moment(doc.id, 'MMMM-YYYY').isBetween(moment(date.start, 'DD-MM-YYYY'), moment(date.end, 'DD-MM-YYYY'), null, '[]')) {
              Object.entries(doc.data()).forEach((records) => {
                //contiene tpRegistros
                Object.entries(records[1]).forEach((record) => {
                  if (record[1].tpIncidencia) {
                    incidents++;
                  }
                });
              });
            }
          });
        } catch (err) {
          console.log(err);
        }

        //Obtiene las correcciones
        try {
          const docSnap = await getDocs(query(collection(db, 'notifications'), where('uid', '==', user.id)));
          docSnap.forEach((doc) => {
            if (moment(Object.keys(doc.data().recordsProposed)[0], 'DD-MM-YYYY').isBetween(moment(date.start, 'DD-MM-YYYY'), moment(date.end, 'DD-MM-YYYY'), null, '[]')) {
              corrections++;
            }
          });
        } catch (err) {
          console.log(err);
        }

        doc.push({
          id: user.id,
          name: user.displayName,
          incidents: incidents,
          corrections: corrections,
        });
      }
    });

    //Espera 5 segundos para que se carguen los datos
    await new Promise((resolve) => setTimeout(resolve, 5000));

    setData(doc);

    //Consulta por empleado todos los registros
    // try {
  };

  const exit = () => {
    document.querySelector('body').style.overflow = 'auto';
    navigate('/');
  };

  return (
    <div className='modal-route'>
      <div className='workerReports'>
        <div className='modal-header'>
          <h5 className='modal-title'>
            <i className='fa fa-user'></i>Registros del personal
          </h5>
          <button type='button' className='btn-close' onClick={() => exit()}></button>
        </div>
        <div className='modal-body'>
          <div className='row'>
            <div className='col-md-4'>
              {
                <form className='row'>
                  <div className='form-group mb-3'>
                    <label>Fecha inicio:</label>
                    <DatePicker
                      locale={es}
                      dateFormat='dd/MM/yyyy'
                      selected={date.start}
                      onChange={(value) => setDate({ ...date, start: value })}
                      selectsStart
                      startDate={date.start}
                      endDate={date.end}
                    />
                  </div>
                  <div className='form-group mb-3'>
                    <label>Fecha fin:</label>
                    <DatePicker
                      locale={es}
                      dateFormat='dd/MM/yyyy'
                      selected={date.end}
                      onChange={(value) => setDate({ ...date, end: value })}
                      selectsEnd
                      startDate={date.start}
                      endDate={date.end}
                      minDate={date.start}
                    />
                  </div>
                  <div className='form-group mb-3'>
                    <button type='button' className='btn btn-success' onClick={() => onSubmit()}>
                      Confirmar
                    </button>
                  </div>
                </form>
              }
            </div>
            <div className='col-md-6 mx-auto'>
              <table className='table'>
                <thead>
                  <tr>
                    <th scope='col'>Empleado</th>
                    <th scope='col'>Incidencias</th>
                    <th scope='col'>Solicitudas corrección</th>
                  </tr>
                </thead>
                <tbody
                  dangerouslySetInnerHTML={{
                    __html: data
                      .map((user) => {
                        return `<tr>
                        <td className="text-start">${user.name}</td>
                        <td>${user.incidents}</td>
                        <td>${user.corrections}</td>
                      </tr>`;
                      })
                      .join(''),
                  }}
                ></tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

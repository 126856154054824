import { useEffect, useState } from 'react';
import { query, collection, onSnapshot, where } from 'firebase/firestore';
import { db } from './../firebase';

export const useUserState = (state) => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    onSnapshot(query(collection(db, 'users'), where('estadoSesion', '==', state)), (snapshot) => {
      var activeUser = [];
      snapshot.forEach((doc) => {
        activeUser.push(doc.data());
      });
      setUsers(activeUser);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return users;
};

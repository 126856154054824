import React, { useState, useEffect } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase';
import moment from 'moment';

export const DayStatus = ({ user }) => {
  const userData = user.userSession;

  const [estado, setEstado] = useState('');
  const [hour, setHour] = useState(moment().format('HH:mm'));

  useEffect(() => {
    var interval = setInterval(() => {
      setHour(moment().format('HH:mm'));
    }, 20000);

    return () => clearInterval(interval);
  }, [hour]);

  // Ver estado actual
  onSnapshot(doc(db, 'users', userData.uid), (doc) => {
    setEstado(doc.data().estadoSesion);
  });

  return (
    <div className='card'>
      <div className='card-body'>
        <div className='row'>
          <div className='col mt-0'>
            <h5 className='card-title'>Estado</h5>
          </div>

          <div className='col-auto'>
            <div className='avatar'>
              <div className='avatar-title'>
                <i className='far fa-check-circle'></i>
              </div>
            </div>
          </div>
        </div>
        <h1 className={'display-5 mt-2 mb-4 ' + estado}>{estado}</h1>
        <div className='mb-0'>
          Hora Actual <span className='text-dark'> {hour} </span>
        </div>
      </div>
    </div>
  );
};

import emailjs from 'emailjs-com';

export const MailDeleteNotification = (record, observation) => {
  const data = {
    type: 'DENEGADA',
    date: record.date,
    email: record.email,
    content: record.content || 'Se ha denegado la solicitud de modificación para el día: ' + record.date,
    observation: observation,
  };

  emailjs.send('service_e7f41hj', 'template_mfcu21i', data, 'user_6u2cffy79MwdDbyMQzgkI');
};
